import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const BoxContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 2px',
});
const BoxCircle = styled(Box)({
    width: '12px',
    height: '12px',
    borderRadius: '50%',
});

const BoxVerticalLine = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '2px',
    height: '150px',
});

export default function StepsIconBottom({ number, title, image, color}) {
    return (
        <BoxContainer>
            <Typography
                sx={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    width: '110px',
                    textAlign: 'center',
                    padding: '8px 0px 12px 0px',
                    color: {color}
                }}
            >
                {title}
            </Typography>
            <BoxCircle sx={{ background: color }} />
            <BoxVerticalLine sx={{ background: color }} />
            <Typography
                sx={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    padding: '8px 0px 12px 0px',
                    color: { color },
                }}
            >
                Passo {number}
            </Typography>
            <Box sx={{margin: '0px 0px 80px 0px'}}>
                <img
                    style={{ width: '50px', height: 'auto' }}
                    src={image}
                    alt='Passo'
                />
            </Box>
        </BoxContainer>
    );
}
