import { Chip } from '@mui/material';

export default function chipStatus(status) {
    switch (status) {
        case 'Disponível':
            return (
                <Chip
                    label={status}
                    sx={{
                        backgroundColor: '#96E29E',
                    }}
                />
            );
        case 'Aguardando assinatura':
        case 'PENDING':
            return (
                <Chip
                    label='Aguardando assinatura'
                    sx={{
                        backgroundColor: '#ADC8F0',
                    }}
                />
            );
        case 'Assinado':
        case 'ACCEPTED':
            return (
                <Chip
                    label='Assinado'
                    sx={{
                        backgroundColor: '#CD9DF3',
                    }}
                />
            );
        case 'Recusado':
        case 'REFUSED':
            return (
                <Chip
                    label='Recusado'
                    sx={{
                        backgroundColor: '#FE9F9F',
                    }}
                />
            );
        case 'Vendido':
            return (
                <Chip
                    label={status}
                    sx={{
                        backgroundColor: '#DBDBDB',
                    }}
                />
            );
        case 'Comprado':
            return <Chip label={status} sx={{ backgroundColor: '#82C5C1' }} />;
        case 'Em processamento':
            return <Chip label={status} sx={{ backgroundColor: '#CD9DF3' }} />;
        case 'Aguardando upload':
            return <Chip label={status} sx={{ backgroundColor: '#FAC17F' }} />;
        case 'Arquivado':
            return <Chip label={status} sx={{ backgroundColor: '#DBDBDB' }} />;
        case 'Interessado':
            return <Chip label={status} sx={{ backgroundColor: '#96E29E' }} />;
        default:
            return null;
    }
}
