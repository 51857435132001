/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { RowsContextProvider } from 'context/RowsContext';
import { UserContextProvider } from 'context/UserContext';
import { ErrorBoundary } from 'react-error-boundary';
// import api from 'services/api';
import NotFound from 'views/NotFound';
import RoutesView from './routes';

// const requestApiError = async (message, code) => {
//     try {
//         //
//         await api.get('/api/logs', {
//             message,
//             code,
//         });
//     } catch (error) {
//         // console.log('error', error);
//     }
// };

export default function App() {
    return (
        <UserContextProvider>
            <RowsContextProvider>
                <ErrorBoundary
                    FallbackComponent={() => NotFound({ error: 'error' })}
                    // onError={(message, code) => requestApiError(message, code)}
                >
                    <Box>
                        <Header />
                        <RoutesView />
                        <Footer />
                    </Box>
                </ErrorBoundary>
            </RowsContextProvider>
        </UserContextProvider>
    );
}
