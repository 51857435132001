/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */

import L from 'leaflet';
import 'leaflet-boundary-canvas';
import 'leaflet/dist/leaflet.css';
import geoJSON from 'mocks/location.json';
import { useEffect, useState } from 'react';
import { CircleMarker, MapContainer, Popup } from 'react-leaflet';
import { useLocation } from 'react-router-dom';

export default function Map({ data }) {
    const [map, setMap] = useState(null);

    const location = useLocation();
    const centerMap = [-14.23, -51.92];
    const valueRadius = []
    const fillBlueOptions = { fillColor: 'blue' };
    const newInfo = [];

    const infoCircle = [
        { AC: [-8.77, -70.55] },
        { AM: [-3.47, -65.1] },
        { RO: [-10.83, -63.34] },
        { RR: [1.99, -61.33] },
        { PA: [-3.79, -52.48] },
        { AP: [1.41, -51.77] },
        { TO: [-9.46, -48.26] },
        { MA: [-5.42, -45.44] },
        { PI: [-6.6, -42.28] },
        { CE: [-5.2, -39.53] },
        { RN: [-5.81, -36.59] },
        { PB: [-7.28, -36.72] },
        { PE: [-8.38, -37.86] },
        { AL: [-9.62, -36.82] },
        { SE: [-10.57, -37.45] },
        { BA: [-13.29, -41.71] },
        { MG: [-18.1, -44.38] },
        { ES: [-19.19, -40.34] },
        { RJ: [-22.25, -42.66] },
        { SP: [-22.19, -48.79] },
        { PR: [-24.89, -51.55] },
        { SC: [-27.45, -50.95] },
        { RS: [-30.17, -53.5] },
        { MS: [-20.51, -54.54] },
        { MT: [-12.64, -55.42] },
        { GO: [-15.98, -49.86] },
        { DF: [-15.83, -47.86] },
    ];

    const calculateRadius = () => {
        infoCircle?.forEach((item) => {
            if (data[Object?.keys(item)[0]]) {
                newInfo.push(item);
                switch (true) {
                    case data[Object?.keys(item)[0]] >= 1 &&
                        data[Object?.keys(item)[0]] <= 100:
                        valueRadius.push(2);
                        break;
                    case data[Object?.keys(item)[0]] >= 100 &&
                        data[Object?.keys(item)[0]] <= 1000:
                        valueRadius.push(4);
                        break;
                    case data[Object?.keys(item)[0]] >= 1000 &&
                        data[Object?.keys(item)[0]] <= 10000:
                        valueRadius.push(6);
                        break;
                    case data[Object?.keys(item)[0]] >= 10000 &&
                        data[Object?.keys(item)[0]] <= 100000:
                        valueRadius.push(8);
                        break;
                    case data[Object?.keys(item)[0]] >= 100000 &&
                        data[Object?.keys(item)[0]] <= 1000000:
                        valueRadius.push(12);
                        break;
                    case data[Object?.keys(item)[0]] >= 1000000 &&
                        data[Object?.keys(item)[0]] <= 10000000:
                        valueRadius.push(16);
                        break;
                    case data[Object?.keys(item)[0]] >= 10000000 &&
                        data[Object?.keys(item)[0]] <= 100000000:
                        valueRadius.push(20);
                        break;
                    default:
                        valueRadius.push(2);
                }
            }
        });
    }

    calculateRadius()

    useEffect(() => {
        if (!map) return;

        const osm = L.TileLayer.boundaryCanvas(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {
                boundary: geoJSON,
            }
        );
        map.addLayer(osm);

        const ukLayer = L.geoJSON(geoJSON);

        map.fitBounds(ukLayer.getBounds());
        map.setZoom(4.4);
    }, [map]);

    return (
        <MapContainer
            center={centerMap}
            scrollWheelZoom={false}
            zoomControl={false}
            autoPanOnFocus={false}
            style={{
                width: location.pathname !== '/' ? '560px' : '100%',
                height: '100%',
            }}
            ref={setMap}
            dragging={false}
            doubleClickZoom={false}
            attributionControl={false}
        >
            {/* <TileLayer
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAP}`}
            /> */}
            {newInfo.length > 0 && valueRadius.length > 0 && newInfo.map((state, index) => (
                <div key={Object?.values(state)[0]} >
                    <CircleMarker
                        center={Object?.values(state)[0]}
                        pathOptions={fillBlueOptions}
                        radius={valueRadius[index]}
                    >
                        <Popup>{Object?.keys(state)[0]}</Popup>
                    </CircleMarker>
                </div>

            ))
            }
        </MapContainer >
    );
}
