/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingScreen from 'components/LoadingScreen';
import { RowsContext } from 'context/RowsContext';
import { UserContext } from 'context/UserContext';
import useInfoTable from 'hooks/InfoTable';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import minMaxValorFaceCurrencyMask from 'utils/minMaxValorFaceCurrencyMask';

const BoxFilter = styled(Box)((props) => ({
    width: '264px',
    // height: '1045px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '16px',
    background: props.theme.palette.themePrimary.primaryWhiteLight,
}));

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const TypographyTitleFilter = styled(Typography)((props) => ({
    margin: '5px 0 0 14px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const TypographyTitle = styled(Typography)((props) => ({
    marginTop: '25px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const TypographyOptions = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const CheckBox = styled(Checkbox)({
    color: '#595959',
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            fill: '#1477F8',
        },
    },
});

const ButtonReset = styled(Button)({
    marginTop: '25px',
    width: '100%',
    color: 'primary',
    borderColor: 'primary',
});

export default function Filter() {
    const {
        setRows,
        oldRows,
        minFaceValue,
        maxFaceValue,
        setHasNotCheckedFilters,
        setHasNotInputMinMax,
        currentPage,
        setCurrentPage,
    } = useContext(RowsContext);

    const { user } = useContext(UserContext);

    const [checked, setChecked] = useState({
        pulverizado: false,
        precatorio: false,
        acoesJudiciais: false,
        contas: false,
        boletos: false,
        financiamento: false,
        financiamentoGarantia: false,
        outros: false,
        disponivel: false,
        aguardandoAssinatura: false,
        assinado: false,
        comprado: false,
        recusado: false,
        vendido: false,
        emProcessamento: false,
        aguardandoUpload: false,
        arquivado: false,
        ateUmAno: false,
        ateTresAnos: false,
        ateCincoAnos: false,
        acimaCincoAnos: false,
    });
    const {
        pulverizado,
        precatorio,
        acoesJudiciais,
        contas,
        boletos,
        financiamento,
        financiamentoGarantia,
        outros,
        disponivel,
        aguardandoAssinatura,
        assinado,
        comprado,
        recusado,
        vendido,
        emProcessamento,
        aguardandoUpload,
        arquivado,
        ateUmAno,
        ateTresAnos,
        ateCincoAnos,
        acimaCincoAnos,
    } = checked;

    const [inputMinFaceValue, setInputMinFaceValue] = useState('');
    const [inputMaxFaceValue, setInputMaxFaceValue] = useState('');

    const location = useLocation();
    const { infosFilter, loading } = useInfoTable();
    let fieldsFilter;

    useEffect(() => {
        fieldsFilter = {
            activeType: {
                'Créditos Pulverizados': pulverizado,
                'Créditos com Entidades Públicas': precatorio,
                'Créditos Corporativos e Discussões Judiciais': acoesJudiciais,
            },
            walletType: {
                'Contas de Utilidades': contas,
                'Boletos e Duplicatas': boletos,
                Financiamento: financiamento,
                'Financiamento com garantia': financiamentoGarantia,
                'Outros créditos pulverizados': outros,
            },
            status: {
                Disponível: disponivel,
                'Aguardando assinatura': aguardandoAssinatura,
                Assinado: assinado,
                Comprado: comprado,
                Recusado: recusado,
                Vendido: vendido,
                'Em processamento': emProcessamento,
                'Aguardando upload': aguardandoUpload,
                Arquivado: arquivado,
            },
            // ageWallet: {
            //     'Até 1 ano': ateUmAno,
            //     'Até 3 anos': ateTresAnos,
            //     'Até 5 anos': ateCincoAnos,
            //     'Acima de 5 anos': acimaCincoAnos,
            // },
        };
        if (user?.email) {
            if (minFaceValue !== '' && maxFaceValue !== '') {
                if (!Object.values(checked).every((value) => value === false)) {
                    setHasNotCheckedFilters(false);
                }
                if (inputMinFaceValue !== '' && inputMaxFaceValue === '') {
                    infosFilter(fieldsFilter, inputMinFaceValue, maxFaceValue);
                } else if (inputMinFaceValue === '' && inputMaxFaceValue !== '') {
                    infosFilter(fieldsFilter, minFaceValue, inputMaxFaceValue);
                } else if (inputMinFaceValue !== '' && inputMaxFaceValue !== '') {
                    infosFilter(fieldsFilter, inputMinFaceValue, inputMaxFaceValue);
                } else {
                    setHasNotInputMinMax(false);
                    infosFilter(fieldsFilter, minFaceValue, maxFaceValue);
                }
            }

            if (
                minFaceValue === '' &&
                maxFaceValue === '' &&
                user?.group === 'vendedor'
            ) {
                setHasNotInputMinMax(false);
                infosFilter(fieldsFilter, '1,00', '10000000,00');
            }
        }
        // }
    }, [
        checked,
        inputMinFaceValue,
        inputMaxFaceValue,
        currentPage,
        user
        /*  minFaceValue,
        maxFaceValue, */
    ]);


    /*   const oldComparator = (a, b) => {
        const numberA = parseInt(a.idadeCarteira.match(/\d+/)[0], 10);
        const numberB = parseInt(b.idadeCarteira.match(/\d+/)[0], 10);

        return numberB - numberA;
    };
 */
    const handleChange = (event) => {
        setChecked({
            ...checked,
            [event.target.name]: event.target.checked,
        });
        setCurrentPage(1);
    };

    const handleReset = () => {
        setRows(oldRows);
        setChecked({
            pulverizado: false,
            precatorio: false,
            acoesJudiciais: false,
            contas: false,
            boletos: false,
            financiamento: false,
            financiamentoGarantia: false,
            outros: false,
            disponivel: false,
            aguardandoAssinatura: false,
            assinado: false,
            comprado: false,
            recusado: false,
            vendido: false,
            emProcessamento: false,
            aguardandoUpload: false,
            arquivado: false,
            ateUmAno: false,
            ateTresAnos: false,
            ateCincoAnos: false,
            acimaCincoAnos: false,
        });
        setCurrentPage(1);
        setInputMinFaceValue('');
        setInputMaxFaceValue('');
    };

    return (
        <>
            {loading ? <LoadingScreen /> : null}
            <BoxFilter sx={{ marginLeft: '30px' }}>
                <BoxTitle>
                    <img src='/static/image/Filtro.svg' alt='Filtro' />
                    <TypographyTitleFilter variant='h3'>
                        Filtros
                    </TypographyTitleFilter>
                </BoxTitle>
                <Box>
                    <TypographyTitle
                        variant='body1'
                        sx={{ marginBottom: '4px' }}
                    >
                        Valor original
                    </TypographyTitle>
                    <TextField
                        id='valor-de'
                        label='Valor a partir de'
                        sx={{ marginTop: '4px' }}
                        type='text'
                        placeholder={`R$ ${minFaceValue?.toLocaleString(
                            'pt-BR'
                        )}`}
                        disabled={
                            maxFaceValue === minFaceValue ||
                            minFaceValue === '0.00'
                        }
                        value={inputMinFaceValue && `R$ ${inputMinFaceValue}`}
                        onChange={(e) =>
                            setInputMinFaceValue(
                                minMaxValorFaceCurrencyMask(e.target.value)
                            )
                        }
                    />

                    <TextField
                        id='valor-ate'
                        label='Valor até'
                        sx={{ marginTop: '8px' }}
                        type='text'
                        placeholder={`R$ ${maxFaceValue?.toLocaleString(
                            'pt-BR'
                        )}`}
                        prefix='R$'
                        disabled={
                            maxFaceValue === minFaceValue ||
                            maxFaceValue === '0.00'
                        }
                        value={inputMaxFaceValue && `R$ ${inputMaxFaceValue}`}
                        onChange={(e) =>
                            setInputMaxFaceValue(
                                minMaxValorFaceCurrencyMask(e.target.value)
                            )
                        }
                    />
                </Box>

                <Box>
                    <TypographyTitle variant='body1'>
                        Tipo de ativo
                    </TypographyTitle>
                    <FormGroup>
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={pulverizado}
                                    onChange={handleChange}
                                    name='pulverizado'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Créditos Pulverizados
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={acoesJudiciais}
                                    onChange={handleChange}
                                    name='acoesJudiciais'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Créditos Corporativos e Discussões Judiciais
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={precatorio}
                                    onChange={handleChange}
                                    name='precatorio'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Créditos com Entidades Públicas
                                </TypographyOptions>
                            }
                        />
                    </FormGroup>
                </Box>
                {checked.pulverizado && (
                    <Box>
                        <TypographyTitle variant='body1'>
                            Tipo de carteira créditos pulverizados
                        </TypographyTitle>
                        <FormGroup>
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={contas}
                                        name='contas'
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Contas de Utilidades
                                    </TypographyOptions>
                                }
                            />
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={boletos}
                                        name='boletos'
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Boletos e Duplicatas
                                    </TypographyOptions>
                                }
                            />
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={financiamento}
                                        name='financiamento'
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Financiamento
                                    </TypographyOptions>
                                }
                            />
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={financiamentoGarantia}
                                        name='financiamentoGarantia'
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Financiamento com garantia
                                    </TypographyOptions>
                                }
                            />
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={outros}
                                        name='outros'
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Outros créditos pulverizados
                                    </TypographyOptions>
                                }
                            />
                        </FormGroup>
                    </Box>
                )}

                <Box>
                    <TypographyTitle variant='body1'>Status</TypographyTitle>
                    <FormGroup>
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={disponivel}
                                    onChange={handleChange}
                                    name='disponivel'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Disponível
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px', width: '100%' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={aguardandoAssinatura}
                                    onChange={handleChange}
                                    name='aguardandoAssinatura'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Aguardando assinatura
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={assinado}
                                    onChange={handleChange}
                                    name='assinado'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Assinado
                                </TypographyOptions>
                            }
                        />
                        {location.pathname.includes('/buyer') !== false ? (
                            <FormControlLabel
                                sx={{ marginTop: '4px' }}
                                control={
                                    <CheckBox
                                        size='small'
                                        checked={comprado}
                                        onChange={handleChange}
                                        name='comprado'
                                    />
                                }
                                label={
                                    <TypographyOptions variant='body2'>
                                        Comprado
                                    </TypographyOptions>
                                }
                            />
                        ) : (
                            <>
                                <FormControlLabel
                                    sx={{ marginTop: '4px', width: '100%' }}
                                    control={
                                        <CheckBox
                                            size='small'
                                            checked={emProcessamento}
                                            onChange={handleChange}
                                            name='emProcessamento'
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='body2'>
                                            Em processamento
                                        </TypographyOptions>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ marginTop: '4px' }}
                                    control={
                                        <CheckBox
                                            size='small'
                                            checked={aguardandoUpload}
                                            onChange={handleChange}
                                            name='aguardandoUpload'
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='body2'>
                                            Aguardando <i>upload</i>
                                        </TypographyOptions>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ marginTop: '4px' }}
                                    control={
                                        <CheckBox
                                            size='small'
                                            checked={arquivado}
                                            onChange={handleChange}
                                            name='arquivado'
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='body2'>
                                            Arquivado
                                        </TypographyOptions>
                                    }
                                />
                            </>
                        )}

                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={recusado}
                                    onChange={handleChange}
                                    name='recusado'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Recusado
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={vendido}
                                    onChange={handleChange}
                                    name='vendido'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Vendido
                                </TypographyOptions>
                            }
                        />
                    </FormGroup>
                </Box>

                {/* <Box>
                    <TypographyTitle variant='body1'>
                        Idade do ativo
                    </TypographyTitle>
                    <FormGroup>
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={ateUmAno}
                                    onChange={handleChange}
                                    name='ateUmAno'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Até 1 ano
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={ateTresAnos}
                                    onChange={handleChange}
                                    name='ateTresAnos'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Até 3 anos
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={ateCincoAnos}
                                    onChange={handleChange}
                                    name='ateCincoAnos'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Até 5 anos
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{ marginTop: '4px' }}
                            control={
                                <CheckBox
                                    size='small'
                                    checked={acimaCincoAnos}
                                    onChange={handleChange}
                                    name='acimaCincoAnos'
                                />
                            }
                            label={
                                <TypographyOptions variant='body2'>
                                    Acima de 5 anos
                                </TypographyOptions>
                            }
                        />
                    </FormGroup>
                </Box> */}
                <ButtonReset variant='outlined' onClick={handleReset}>
                    Limpar filtros
                </ButtonReset>
            </BoxFilter>
        </>
    );
}
