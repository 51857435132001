/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
import { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

const BoxContainerFooter = styled(Box)((props) => ({
    width: '100%',
    height: '100%',
    background: props.theme.palette.themePrimary.primaryBlack,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
}));

export default function Footer() {
    const { loading } = useContext(UserContext);
    const location = useLocation();
    const showFooter = [
        '/seller/active',
        '/seller/administration',
        '/buyer/opportunities',
        '/buyer/details/active/',
        '/buyer/administration',
    ].includes(location.pathname);

    const marginFooter = [
        '/seller/administration',
        '/buyer/administration',
        '/seller/active',
    ].includes(location.pathname);

    return (
        <>
            {(loading && showFooter) || location.pathname === '/' ? (
                <BoxContainerFooter
                    sx={{
                        marginTop: marginFooter && `calc(100vh - 32%)`,
                    }}
                >
                    <Typography
                        variant='subtitle2'
                        sx={{
                            color: '#FFFFFF',
                            margin: '20px 30px',
                        }}
                    >
                       © 2024 KPMG Consultoria Ltda., uma sociedade simples
                        brasileira, de responsabilidade limitada e firma-membro
                        da organização global KPMG de firmas membro
                        independentes licenciadas da KPMG International Limited,
                        uma empresa inglesa privada de responsabilidade
                        limitada. Todos os direitos reservados. O nome KPMG e o
                        seu logotipo são marcas utilizadas sob licença pelas
                        firmas-membro independentes da organização global KPMG.
                        <Link
                            style={{color:'#fff', fontWeight:'bold'}}
                            onClick={() => window.scrollTo(0, 0)}
                            to='https://privacy-central.securiti.ai/#/notices/d8427eaf-4139-40ab-a502-c2bc6222000e'
                            target='blank'
                        >  Aviso de privacidade</Link>
                        <Button 
                            type="button" 
                            className="cmp-revoke-consent"
                            sx={{ 
                                fontWeight: 600, 
                                marginRight: 5, 
                                marginTop:'18px',
                                background: 'none',
                                border: 'none', 
                                padding: 0, 
                                fontSize: '11px',
                                color: '#fff', 
                                cursor: 'pointer', 
                            }}>
                            Configurações
                        </Button>
                    </Typography>
                </BoxContainerFooter>
            ) : null}
        </>
    );
}
