/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { RowsContext } from 'context/RowsContext';
import { UserContext } from 'context/UserContext';
import debounce from 'lodash/debounce';
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';

export default function useInfoTable() {
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);
    const { setRows, setTotalRows, currentPage } = useContext(RowsContext);

    let sortingStatus;

    if (user?.group === 'comprador') {
        sortingStatus = {
            Assinado: 1,
            'Aguardando assinatura': 2,
            Disponível: 3,
            Comprado: 4,
            Recusado: 5,
            Vendido: 6,
        };
    }
    // else {
    //     sortingStatus = {
    //         Disponível: 1,
    //         'Aguardando assinatura': 2,
    //         Recusado: 3,
    //         'Em processamento': 4,
    //         'Aguardando upload': 5,
    //         Vendido: 6,
    //         Arquivado: 7,
    //     };
    // }

    function sortDate(a, b) {
        return (
            new Date(b?.publication?.split('/').reverse().join('-')).getTime() -
            new Date(a?.publication?.split('/').reverse().join('-')).getTime()
        );
    }

    function sortFields(a, b) {
        return (
            sortingStatus[a?.status] - sortingStatus[b?.status] ||
            sortDate(a, b)
        );
    }

    async function handleDebounceFilter(fields, min, max) {
        const arrayActiveType = Object.entries(fields.activeType)
            .filter(([, value]) => value)
            .map(([key]) => key);

        const arrayWalletType = Object.entries(fields.walletType)
            .filter(([, value]) => value)
            .map(([key]) => key);

        const arrayStatus = Object.entries(fields.status)
            .filter(([, value]) => value)
            .map(([key]) => `"${key}"`);

        // const arrayAgeWallet = Object.entries(fields.ageWallet)
        //     .filter(([, value]) => value)
        //     .map(([key]) => key);

        try {
            setLoading(true);
            const status = arrayStatus.length ? `&status=[${arrayStatus}]` : ''
            const { data } = await api.get(`/api/active?page=${currentPage}${status}`, {
                params: {
                    activeType: arrayActiveType,
                    walletType: arrayWalletType,
                    // ageWallet: arrayAgeWallet,
                    // faceValue__gte: min.replace(/[^0-9]/g, ''),
                    // faceValue__lte: max.replace(/[^0-9]/g, ''),
                    faceValue__gte: min?.replaceAll('.', '')?.replace(',', '.'),
                    faceValue__lte: max?.replaceAll('.', '')?.replace(',', '.'),
                },
                paramsSerializer: {
                    indexes: null,
                },
            });

            data?.active?.sort(
                user.group === 'comprador' ? sortFields : sortDate
            );

            data?.active?.forEach((item) => {
                if (item.status === 'ACCEPTED') {
                    item.status = 'Assinado'
                }
                if (item.status === 'PENDING') {
                    item.status = 'Aguardando assinatura'
                }
                if (item.status === 'REFUSED') {
                    item.status = 'Recusado'
                }
            });
            data?.active?.map((item) => item.idGenerate = Number(item.idGenerate));

            const dataSellerFiltered = data?.active?.map((item) =>
                item.status === 'Aguardando upload' ||
                    item.status === 'Em processamento'
                    ? {
                        ...item,
                        coin: '',
                        updatedValue: '-------------',
                        faceValue: '-------------',
                        amountDoc: '-------------',
                    }
                    : item
            );

            const rowsGroupCondition =
                user.group === 'comprador' ? data?.active : dataSellerFiltered;

            setRows(rowsGroupCondition);

            setTotalRows(data.totalPages);

            setLoading(false);
        } catch (error) {
            toast.error(error.response?.data?.message || error.message || error);
        }
    }

    const debounceFilter = useCallback(debounce(handleDebounceFilter, 1000), [
        currentPage,
    ]);

    async function infosFilter(fields, min, max) {
        debounceFilter(fields, min, max);
    }

    return { infosFilter, loading, currentPage };
}
