/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const StepperBuyer = styled(Stepper)(() => ({
    border: 0,
    color: '#666666',
    background: '#FFFFFF',
    height: '55px',
    margin: '32px 80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 30px',
    borderRadius: '24px',
}));

const StepCustom = styled(Step)(() => ({
    '&. MuiStepConnector-line': {
        display: 'none',
    },
    '&. MuiStepConnector-lineVertical': {
        display: 'none',
    },
    '&. MuiStepConnector-icon': {
        display: 'block',
    },
}));

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
    color: '#fff',
    width: 16,
    height: 16,
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: '#1E49E2',
    }),
    ...(ownerState.completed && {
        background: '#1E49E2',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed } = props;

    const icons = {
        1: (
            <img
                src={
                    completed || active
                        ? '/static/image/UmBranco.svg'
                        : '/static/image/Um.svg'
                }
                alt='Um'
            />
        ),
        2: (
            <img
                src={
                    completed || active
                        ? '/static/image/DoisBranco.svg'
                        : '/static/image/Dois.svg'
                }
                alt='Dois'
            />
        ),
        3: (
            <img
                src={
                    completed || active
                        ? '/static/image/TresBranco.svg'
                        : '/static/image/Tres.svg'
                }
                alt='Tres'
            />
        ),
        4: (
            <img
                src={
                    completed || active
                        ? '/static/image/QuatroBranco.svg'
                        : '/static/image/Quatro.svg'
                }
                alt='Quatro'
            />
        ),
        5: (
            <img
                src={
                    completed || active
                        ? '/static/image/CincoBranco.svg'
                        : '/static/image/Cinco.svg'
                }
                alt='Cinco'
            />
        ),
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const steps = [1, 2, 3, 4, 5];

export default function StepperAssessementsBuyer() {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [resultAssessment, setResultAssessment] = useState({
        step1: {},
        step2: [],
        step3: [],
        step4: { option: '', states: [] },
        step5: '',
    });
    const [isSmaller, setIsSmaller] = useState(false);
    const [blockNextButton, setBlockNextButton] = useState(true);
    const assessment = {
        'Selecione um ou mais ativo de sua preferência.':
            resultAssessment.step1,
        'Qual o montante mínimo e máximo do valor atualizado do ativo que você busca?':
            resultAssessment.step2,
        'Qual o valor mínimo e máximo de desembolso?': resultAssessment.step3,
        'Preferência por localidade?': resultAssessment.step4,
        'Descreva breve resumo da sua companhia e estratégia de compra de Ativos Alternativos.':
            resultAssessment.step5,
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSendAssessment = async () => {
        try {
            await api.post('/api/assessment', {
                assessment,
                group: 'comprador',
            });
            navigate('/buyer/opportunities');
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Step1
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 1:
                return (
                    <Step2
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                        isSmaller={isSmaller}
                        setIsSmaller={setIsSmaller}
                    />
                );
            case 2:
                return (
                    <Step3
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                        isSmaller={isSmaller}
                        setIsSmaller={setIsSmaller}
                    />
                );
            case 3:
                return (
                    <Step4
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 4:
                return (
                    <Step5
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );

            default:
                return null;
        }
    }

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (
                    resultAssessment?.step1?.pulverizado?.length > 0 ||
                    resultAssessment?.step1?.acoesJudiciais?.length > 0 ||
                    resultAssessment?.step1?.precatorio?.length > 0
                ) {
                    setBlockNextButton(false);
                } else {
                    setBlockNextButton(true);
                }

                break;

            case 1:
                if (
                    resultAssessment.step2[0] &&
                    resultAssessment.step2[1] &&
                    !isSmaller
                ) {
                    setBlockNextButton(false);
                } else {
                    setBlockNextButton(true);
                }

                break;

            case 2:
                setBlockNextButton(false);
                break;

            case 3:
                if (
                    resultAssessment.step4.option === '' ||
                    (resultAssessment.step4.option === 'sim' &&
                        resultAssessment.step4.states.length === 0)
                ) {
                    setBlockNextButton(true);
                } else {
                    setBlockNextButton(false);
                }
                break;

            case 4:
                setBlockNextButton(false);
                break;

            default:
                break;
        }
    }, [activeStep, resultAssessment]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '32px 80px',
                }}
            >
                <BoxTitle>
                    <img
                        src='/static/image/CriarConta.svg'
                        alt='Cadeado - Criar conta'
                    />
                    <TypographyTitle variant='h1'>
                        Entendendo seu perfil
                    </TypographyTitle>
                </BoxTitle>
                <Button
                    variant='text'
                    color='primary'
                    endIcon={<CloseRoundedIcon />}
                    onClick={() => (window.location.href = '/signout')}
                >
                    Fechar
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    margin: 'auto 70px',
                }}
            >
                <Typography variant='body1'>
                    Para entendermos melhor o seu perfil, preencha as
                    informações abaixo. Poderemos, assim, comunicar você no
                    momento em que lançarmos um ativo do seu interesse.
                </Typography>
            </Box>
            <StepperBuyer
                activeStep={activeStep}
                connector={
                    <ArrowForwardIosRoundedIcon sx={{ width: '12px' }} />
                }
            >
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <StepCustom key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={ColorlibStepIcon}
                            />
                        </StepCustom>
                    );
                })}
            </StepperBuyer>

            {getStepContent(activeStep)}
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px 80px',
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    sx={{ background: '#FFFFFF' }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<ArrowBackIosRoundedIcon />}
                >
                    Passo anterior
                </Button>
                {activeStep === steps.length - 1 ? (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSendAssessment}
                        endIcon={<CheckRoundedIcon />}
                    >
                        Concluir cadastro
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={blockNextButton}
                        onClick={handleNext}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                        Próximo passo
                    </Button>
                )}
            </Box>
        </Box>
    );
}
