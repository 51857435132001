import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Hexagon = styled(Box)({
    float: 'left',
    height: '400px',
    marginRight: '-62px',
    marginBottom: '-120px',
    position: 'relative',
});

const HexLeft = styled(Box)({
    float: 'left',
    width: 0,
    borderRight: '72px solid', 
    borderTop: '124.8px solid transparent', 
    borderBottom: '124.8px solid transparent', 
});

const HexRight = styled(Box)({
    float: 'left',
    width: 0,
    borderLeft: '72px solid', 
    borderTop: '124.8px solid transparent', 
    borderBottom: '124.8px solid transparent', 
});

const HexMiddle = styled(Box)({
    float: 'left',
    width: '154px',
    height: '249.6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const HexagonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-50px'
});

const EvenHexagon = styled(Hexagon)({
    marginTop: '124.8px',
});

const TypographyTitle = styled(Typography)({
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#FFF',
});

const TypographyContent = styled(Typography)({
    color: '#FFF',
    textAlign: 'center',
    padding: '20px 0px',
});

function Hexagons() {
    return (
        <HexagonContainer>
            <Hexagon>
                <HexLeft color='#1E49E2' />
                <HexMiddle backgroundColor='#1E49E2'>
                    <TypographyTitle variant='h4'>Usabilidade</TypographyTitle>
                    <TypographyContent variant='subtitle1'>
                        Ferramenta de fácil navegação.
                    </TypographyContent>
                </HexMiddle>
                <HexRight color='#1E49E2' />
            </Hexagon>

            <EvenHexagon>
                <HexLeft color='#50AC90' />
                <HexMiddle backgroundColor='#50AC90'>
                    <TypographyTitle variant='h4'>
                        Escalabilidade
                    </TypographyTitle>
                    <TypographyContent variant='subtitle1'>
                        Agilidade na compra e venda de ativos alternativos e
                        integração futura com outros serviços.
                    </TypographyContent>
                </HexMiddle>
                <HexRight color='#50AC90' />
            </EvenHexagon>

            <Hexagon>
                <HexLeft color='#6727E1' />
                <HexMiddle backgroundColor='#6727E1'>
                    <TypographyTitle variant='h4'>Segurança</TypographyTitle>
                    <TypographyContent variant='subtitle1'>
                        A plataforma, além de ser uma excelente vitrine de
                        ativos alternativos, conta com a expertise da KPMG.
                    </TypographyContent>
                </HexMiddle>
                <HexRight color='#6727E1' />
            </Hexagon>

            <EvenHexagon>
                <HexLeft color='#E84A9A' />
                <HexMiddle backgroundColor='#E84A9A'>
                    <TypographyTitle variant='h4'>Dados</TypographyTitle>
                    <TypographyContent variant='subtitle1'>
                        A plataforma faz uma análise completa dos dados do
                        ativo, produzindo outputs essenciais para entendimento
                        do ativo.
                    </TypographyContent>
                </HexMiddle>
                <HexRight color='#E84A9A' />
            </EvenHexagon>

            <Hexagon>
                <HexLeft color='#6481EB' />
                <HexMiddle backgroundColor='#6481EB'>
                    <TypographyTitle variant='h4'>
                        Serviços Adicionais
                    </TypographyTitle>
                    <Typography
                        sx={{
                            color: '#FFF',
                            padding: '20px 0px',
                        }}
                        variant='subtitle1'
                    >
                        <ul>
                            <li>
                                <i>Due Diligence</i>
                            </li>
                            <li>
                                <i>Valuation</i>
                            </li>
                            <li>Assessoria Fiscal</li>
                            <li>Entre outros</li>
                        </ul>
                    </Typography>
                </HexMiddle>
                <HexRight color='#6481EB' />
            </Hexagon>
        </HexagonContainer>
    );
}

export default Hexagons;
