import { Box } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = {
    facePerson: {
        backgroundColor: [
            '#AF99F8',
            '#6727E1',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ],
        /*  borderColor: [
            '#6954b1',
            '#5824b9',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ], */
    },
    warrantyPerson: {
        backgroundColor: [
            '#E84A9A',
            '#F2A7D7',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ],
        /*  borderColor: [
            '#bc1f6e',
            '#c84f9c',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ], */
    },
    propertyType: {
        backgroundColor: [
            '#4f64b1',
            '#8CD0FA',
            '#1a96de',
            '#4E0C82',
            '#043551',
        ],
    },
    outstandingBalance: {
        backgroundColor: ['#56BDAD', '#B2EDDF'],
        // borderColor: ['#56BDAD', '#B2EDDF'],
    },
};

export default function PieGraphics({ labels, data, content, size }) {
    let background;
    let widhtConditional;
    let heightConditional;
    // let border;

    switch (content) {
        case 'facePerson':
            background = colors.facePerson.backgroundColor;
            // border = colors.facePerson.borderColor;
            break;

        case 'warrantyPerson':
            background = colors.warrantyPerson.backgroundColor;
            // border = colors.warrantyPerson.borderColor;
            break;

        case 'outstandingBalance':
            background = colors.outstandingBalance.backgroundColor;
            // border = colors.outstandingBalance.borderColor;
            break;

        case 'propertyType':
            background = colors.propertyType.backgroundColor;
            // border = colors.outstandingBalance.borderColor;
            break;

        default:
            background = colors.facePerson.backgroundColor;
        // border = colors.facePerson.borderColor;
    }
    const dataInfo = {
        labels,
        datasets: [
            {
                label: ' ',
                data,
                backgroundColor: background,
                borderColor: background,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 32,
                    padding: 16,
                },
            },
        },
    };

    if (size === 'lg') {
        widhtConditional = '300px';
        heightConditional = '300px';
    } else if (size === 'sm') {
        widhtConditional = '190px';
        heightConditional = '190px';
    } else {
        widhtConditional = '220px';
        heightConditional = '220px';
    }

    return (
        <Box
            sx={{
                width: widhtConditional,
                height: heightConditional,
            }}
        >
            <Pie data={dataInfo} options={options} />
        </Box>
    );
}
