/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-fallthrough */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import ComplementaryData from './ComplementaryData';
import TermsOfUse from './Terms';

const StepperBuyer = styled(Stepper)((props) => ({
    border: 0,
    color: '#666666',
    background: '#FFFFFF',
    height: '55px',
    margin: '32px 80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: `${props.center ? 'center' : 'space-between'}`,
    padding: '0 30px',
    borderRadius: '24px',
}));

const StepCustom = styled(Step)(() => ({
    '&. MuiStepConnector-line': {
        display: 'none',
    },
    '&. MuiStepConnector-lineVertical': {
        display: 'none',
    },
    '&. MuiStepConnector-icon': {
        display: 'block',
    },
}));

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
    color: '#fff',
    width: 16,
    height: 16,
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: '#1E49E2',
    }),
    ...(ownerState.completed && {
        background: '#1E49E2',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed } = props;

    const icons = {
        1: (
            <img
                src={
                    completed || active
                        ? '/static/image/UmBranco.svg'
                        : '/static/image/Um.svg'
                }
                alt='Um'
            />
        ),
        2: (
            <img
                src={
                    completed || active
                        ? '/static/image/DoisBranco.svg'
                        : '/static/image/Dois.svg'
                }
                alt='Dois'
            />
        ),
        3: (
            <img
                src={
                    completed || active
                        ? '/static/image/TresBranco.svg'
                        : '/static/image/Tres.svg'
                }
                alt='Tres'
            />
        ),
        4: (
            <img
                src={
                    completed || active
                        ? '/static/image/QuatroBranco.svg'
                        : '/static/image/Quatro.svg'
                }
                alt='Quatro'
            />
        ),
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const steps2 = ['Dados complementares', 'Termo de Responsabilidade'];
const steps = ['Termo de Responsabilidade'];

export default function StepperLoginBuyer() {
    const { setUser, getUsers } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorCPF, setErrorCPF] = useState(false);
    const [CPF, setCPF] = useState('');
    const [position, setPosition] = useState('');
    const [cellNumber, setCellNumber] = useState('');
    const [commercialPhone, setCommercialPhone] = useState('');
    const [CNPJ, setCNPJ] = useState('');
    const [isUserAcceptedTerms, setIsUserAcceptedTerms] = useState({
        use: false,
        notification: false,
    });
    const [hasSocialReason, setHasSocialReason] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [disableButton, setDisableButton] = useState(true);

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        // try {
        // if (CNPJ?.length > 17 && activeStep === 0) {
        //     setIsLoading(true);
        //     const cnpj = CNPJ.replaceAll('.', '')
        //         .replace('-', '')
        //         .replace('/', '');
        //     const res = await api.post('/api/company/verification', {
        //         CNPJ: cnpj,
        //         socialReasonLogin: socialReason,
        //     });
        //     setStepResponse(res.data);
        // }
        // } catch (error) {
        //     toast.error(error.response.data.message || error.message || error);
        // } finally {
        //     setIsLoading(false);
        // }
    };

    const handleBack = () => {
        setErrorCPF(false);
        setHasSocialReason(false);
        if (hasSocialReason) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleCompleteRegistration = async () => {
        try {
            setIsLoading(true);
            console.log("cellNumber?.length",cellNumber?.length)
            if(cellNumber?.length < 16 && cellNumber?.length > 19) {
                toast.error("Telefone celular não pode ser vazio / número inválido");
                return
            }
            
            let res;
            if (CNPJ) {
                res = await api.put('/api/user/update/buyer/guest', {
                    CPF,
                    position,
                    phone: {
                        cell: cellNumber,
                        commercial: commercialPhone,
                    },
                });
            } else {
                res = await api.get('/api/user/update/complement/info');
            }

            setUser(res.data);

            // activeStep === 2 && navigate('/buyer/assessment');
            navigate('/buyer/assessment');
            getUsers();
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
            setIsUserAcceptedTerms({
                ...isUserAcceptedTerms,
                use: false,
                notification: false,
            });
            setActiveStep(-1);
        } finally {
            setIsLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            // getUsers();
        }
    };

    const getStepContent = () => {
        const userAdmin = {
            0: (
                <TermsOfUse
                    isUserAcceptedTerms={isUserAcceptedTerms}
                    setIsUserAcceptedTerms={setIsUserAcceptedTerms}
                    hasSocialReason={hasSocialReason}
                    setHasSocialReason={setHasSocialReason}
                />
            ),
        };

        const userGuest = {
            0: (
                <ComplementaryData
                    CPF={CPF}
                    setCPF={setCPF}
                    setErrorCPF={setErrorCPF}
                    position={position}
                    setPosition={setPosition}
                    cellNumber={cellNumber}
                    setCellNumber={setCellNumber}
                    setCommercialPhone={setCommercialPhone}
                    commercialPhone={commercialPhone}
                />
            ),
            1: (
                <TermsOfUse
                    isUserAcceptedTerms={isUserAcceptedTerms}
                    setIsUserAcceptedTerms={setIsUserAcceptedTerms}
                    hasSocialReason={hasSocialReason}
                    setHasSocialReason={setHasSocialReason}
                />
            ),
        };

        return !CNPJ ? userAdmin[activeStep] : userGuest[activeStep];
    };

    const getInvitedUser = async () => {
        try {
            const res = await api.get(`/api/notification`);
            setCNPJ(res.data?.user?.cnpj);
            if (res.data?.user?.subject) {
                setHasSocialReason(true);
            }
        } catch (error) {
            // if (
            //     error.response?.data?.message ===
            //     'Usuário já cadastrado no sistema'
            // ) {
            //     toast.error('Usuário já cadastrado no sistema');
            // }
            // toast.error(error?.response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        if (CNPJ && !errorCPF && CPF.length === 14 && cellNumber?.length === 19) {
            setDisableButton(false);
        }
        // if (
        //     !CNPJ &&
        //     (!isUserAcceptedTerms.use || !isUserAcceptedTerms.notification)
        // ) {
        //     setDisableButton(false);
        // }
    }, [errorCPF, steps2, CNPJ, CPF, isUserAcceptedTerms, disableButton,cellNumber]);

    useEffect(() => {
        getInvitedUser();
    }, []);

    return (
        <Box sx={{ width: '100%', maxWidth: '1380px', margin: '0 auto' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '32px 80px',
                }}
            >
                <BoxTitle>
                    <img
                        src='/static/image/CriarConta.svg'
                        alt='Cadeado - Criar conta'
                    />
                    <TypographyTitle variant='h1'>Criar conta</TypographyTitle>
                </BoxTitle>
                <Button
                    variant='text'
                    color='primary'
                    endIcon={<CloseRoundedIcon />}
                    onClick={() => (window.location.href = '/signout')}
                >
                    Fechar
                </Button>
            </Box>
            <StepperBuyer
                center={!CNPJ}
                activeStep={activeStep}
                connector={
                    <ArrowForwardIosRoundedIcon sx={{ width: '12px' }} />
                }
            >
                {(CNPJ ? steps2 : steps).map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <StepCustom key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={ColorlibStepIcon}
                            >
                                {label}
                            </StepLabel>
                        </StepCustom>
                    );
                })}
            </StepperBuyer>
            {getStepContent()}
            <Box
                style={{
                    display: 'flex',
                    justifyContent:
                        activeStep === 0 ? 'flex-end' : 'space-between',
                    margin: '20px 80px',
                }}
            >
                {activeStep >= 1 && (
                    <Button
                        variant='outlined'
                        color='primary'
                        sx={{ background: '#FFFFFF' }}
                        // disabled={activeStep === 1}
                        onClick={handleBack}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        Passo anterior
                    </Button>
                )}

                {activeStep === (CNPJ ? steps2 : steps).length - 1 ? (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleCompleteRegistration}
                        endIcon={<CheckRoundedIcon />}
                        disabled={
                            !isUserAcceptedTerms?.use ||
                            !isUserAcceptedTerms?.notification
                        }
                    >
                        Concluir cadastro
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                        disabled={disableButton}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                        Próximo passo
                    </Button>
                )}
            </Box>
        </Box>
    );
}
