/* eslint-disable react/button-has-type */

import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isValid, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '410px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));

const BoxFormControl = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '25px',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));

export default function Step3({ setResultAssessment, resultAssessment }) {
    const [inputValue, setInputValue] = useState(resultAssessment?.step3 || '');
    const [isInvalidDate, setIsInvalidDate] = useState(false);

    const handleInputchange = (event) => {
        const { value } = event.target;
        const digitsOnly = value.replace(/\D/g, '');

        const formattedvalue = digitsOnly
            .replace(/(\d{2})(\d)/, '$1/$2')
            .replace(/(\d{2})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1');

        setInputValue(formattedvalue);
        const parsedDate = parse(formattedvalue, 'dd/MM/yyyy', new Date());

        if (isValid(parsedDate)) {
            setIsInvalidDate(false);
            return;
        }
        setIsInvalidDate(true);
    };

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: inputValue,
            step4: resultAssessment?.step4,
            step5: resultAssessment?.step5,
            step6: resultAssessment?.step6,
            step7: resultAssessment?.step7,
        });
    }, [inputValue]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/TresBranco.svg' alt='Tres' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        marginTop: '4vh',
                        marginBottom: '20px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Data final para recebimento das propostas, caso deseje estabelecer uma. Não obrigatório.
                </Typography>
            </Box>

            <BoxFormControl>
                <FormControlLabel
                    sx={{
                        margin: '12px auto',
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column-reverse',
                    }}
                    control={
                        <TextField
                            value={inputValue}
                            onChange={handleInputchange}
                            inputProps={{
                                maxLength: 10,
                                pattern: '\\d{2}/\\d{2}/\\d{4}',
                            }}
                            error={inputValue.length > 3 && isInvalidDate}
                            helperText={
                                inputValue.length > 3 &&
                                isInvalidDate &&
                                'Data inválida'
                            }
                            placeholder='DD/MM/YYYY'
                        />
                    }
                />
            </BoxFormControl>
        </FormBox>
    );
}
