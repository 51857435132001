/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
    Box,
    Button,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { UserContext } from 'context/UserContext';
import { cpf } from 'cpf-cnpj-validator';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';
import { handleFormatCPF, handleFormatPhone } from 'utils/formats';

const HeaderContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '32px 32px 32px 0px',
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

const TypographyOptions = styled(Typography)({
    color: theme.palette.themePrimary.primaryGrayDark,
});

export default function Profile() {
    const navigate = useNavigate();

    const { user, getUsers } = useContext(UserContext);

    const [CPF, setCPF] = useState(
        user?.cpf?.replace('-', '')?.replaceAll('.', '') || ''
    );
    const [position, setPosition] = useState(user?.position || '');
    const [cellNumber, setCellNumber] = useState(user?.phone?.cell || '');
    const [commercialPhone, setCommercialPhone] = useState(
        user?.phone?.commercial || ''
    );
    const [reasonSocial, setReasonSocial] = useState(
        'Sem empresa vinculada...'
    );

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (JSON.stringify(user) !== '{}') {
            setCPF(user?.cpf?.replace('-', '')?.replaceAll('.', ''));
            setPosition(user?.position);
            setCellNumber(user?.phone?.cell);
            setCommercialPhone(user?.phone?.commercial);
            setReasonSocial(
                user?.company?.idCompany?.reasonSocial ||
                    'Sem empresa vinculada...'
            );
        }
    }, [user]);

    useEffect(() => {
        if (user?.company?.status === 'ACCEPTED') {
            setReasonSocial(user?.company?.idCompany?.reasonSocial);
        } else {
            setReasonSocial('Sem empresa vinculada...');
        }
    }, [user]);

    const handleUpdateProfile = async () => {
        try {
            setIsLoading(true);
            if(cellNumber?.length !== 19) {
                toast.error("Telefone celular não pode ser vazio / número inválido");
                return
            }

            const res = await api.put(`/api/user/update/${user._id}`, {
                // cpf: handleFormatCPF(CPF),
                position,
                phone: {
                    cell: cellNumber,
                    commercial: commercialPhone,
                },
                // reasonSocial,
            });
            toast.success(res.data.message);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    };

    // const handleLeaveCompany = async () => {
    //     try {
    //         setIsLoading(true);
    //         const res = await api.delete(
    //             `/api/user/delete/company/${user._id}/REMOVED_USER_COMPANY`
    //         );
    //         toast.success(res.data.message);
    //         setReasonSocial('Sem empresa vinculada...');
    //     } catch (error) {
    //         toast.error(error.response.data.message || error.message || error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleGoBack = async () => {
        navigate(-1);
        getUsers();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '85%',
                maxWidth: '1380px',
                margin: '0 auto',
            }}
        >
            <HeaderContainer>
                <Button
                    variant='text'
                    sx={{
                        marginTop: '32px',
                        width: '32px',
                    }}
                    color='primary'
                    size='4px'
                    startIcon={<ArrowBackIosRoundedIcon />}
                    onClick={handleGoBack}
                >
                    Voltar
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            margin: '32px 32px 32px 0px',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <img
                                src='/static/image/Perfil.svg'
                                alt='Perfil'
                                width={24}
                            />
                            <TypographyTitle
                                sx={{ marginLeft: '14px' }}
                                variant='h1'
                            >
                                Perfil
                            </TypographyTitle>
                        </Box>

                        <Typography
                            variant='body1'
                            sx={{ margin: '20px 32px 0px 32px' }}
                        >
                            Aqui você pode ver e atualizar os dados do seu
                            perfil
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            variant='contained'
                            sx={{
                                margin: '32px 0px 32px 8px',
                            }}
                            color='primary'
                            size='8px'
                            endIcon={<CheckRoundedIcon />}
                            onClick={handleUpdateProfile}
                        >
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </HeaderContainer>

            {isLoading || user.email !== '' ? (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            marginBottom: '40px',
                            columnGap: 3,
                            rowGap: 1,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            display: 'grid',
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='CPF'
                                    disabled
                                    value={CPF && handleFormatCPF(CPF)}
                                    onChange={(e) => {
                                        setCPF(handleFormatCPF(e.target.value));
                                    }}
                                    inputProps={{ maxLength: 14 }}
                                    error={CPF?.length > 3 && !cpf.isValid(CPF)}
                                    helperText={
                                        CPF?.length > 3 &&
                                        !cpf.isValid(CPF) &&
                                        'Insira um CPF válido'
                                    }
                                    placeholder='000.000.000-00'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    CPF{' '}
                                    <spam style={{ color: '#D10000' }}>*</spam>
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    value={position}
                                    onChange={(e) =>
                                        setPosition(e.target.value)
                                    }
                                    id='cargo'
                                    placeholder='Qual o seu cargo?'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                        background: '#FFFFFF',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Cargo
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='celular'
                                    value={
                                        cellNumber &&
                                        handleFormatPhone(cellNumber)
                                    }
                                    onChange={(e) =>
                                        setCellNumber(
                                            handleFormatPhone(e.target.value)
                                        )
                                    }
                                    inputProps={{ maxLength: 19 }}
                                    error={
                                        cellNumber?.length > 2 &&
                                        cellNumber?.length < 19
                                    }
                                    helperText={
                                        cellNumber?.length > 2 &&
                                        cellNumber?.length < 19 &&
                                        'Insira um número válido'
                                    }
                                    placeholder='+00 (00) 00000-0000'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                        background: '#FFFFFF',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Telefone celular{' '}
                                    <spam style={{ color: '#D10000' }}>*</spam>
                                </TypographyOptions>
                            }
                        />
                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='commercialPhone'
                                    value={
                                        commercialPhone &&
                                        handleFormatPhone(commercialPhone)
                                    }
                                    onChange={(e) =>
                                        setCommercialPhone(
                                            handleFormatPhone(e.target.value)
                                        )
                                    }
                                    inputProps={{ maxLength: 19 }}
                                    error={
                                        commercialPhone?.length > 2 &&
                                        commercialPhone?.length < 19
                                    }
                                    helperText={
                                        commercialPhone?.length > 2 &&
                                        commercialPhone?.length < 19 &&
                                        'Insira um número válido'
                                    }
                                    placeholder='+00 (00) 00000-0000'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                        background: '#FFFFFF',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Telefone comercial
                                </TypographyOptions>
                            }
                        />
                    </Box>

                    {!user?.company?.isAdmin && (
                        <>
                            <HeaderContainer>
                                <BoxTitle>
                                    <img
                                        src='/static/image/DadosEmpresa.svg'
                                        alt='Organização'
                                        width={24}
                                    />
                                    <TypographyTitle
                                        sx={{ marginLeft: '14px' }}
                                        variant='h1'
                                    >
                                        Organização
                                    </TypographyTitle>
                                </BoxTitle>
                            </HeaderContainer>

                            <Box
                                sx={{
                                    width: '100%',
                                    marginBottom: '40px',
                                    columnGap: 3,
                                    rowGap: 1,
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <FormControlLabel
                                    sx={{
                                        margin: '12px auto',
                                        display: 'flex',
                                        alignItems: 'start',
                                        flexDirection: 'column-reverse',
                                    }}
                                    control={
                                        <TextField
                                            value={reasonSocial}
                                            disabled
                                            onChange={(e) =>
                                                setPosition(e.target.value)
                                            }
                                            id='razao'
                                            // placeholder='Sem empresa vinculada...'
                                            variant='outlined'
                                            sx={{
                                                marginTop: '8px',
                                                width: '312px',
                                            }}
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='h5'>
                                            Razão social
                                        </TypographyOptions>
                                    }
                                />
                                {/* <Button
                                    sx={{
                                        margin: '30px 15px 0 15px',
                                    }}
                                    disabled={
                                        reasonSocial === '' ||
                                        reasonSocial ===
                                            'Sem empresa vinculada...' ||
                                        reasonSocial === undefined
                                    }
                                    variant='text'
                                    size='8px'
                                    color='error'
                                    startIcon={<FaRegTrashAlt />}
                                    onClick={handleLeaveCompany}
                                >
                                    Sair da empresa
                                </Button> */}
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
