/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RowsContext } from 'context/RowsContext';
import { useContext } from 'react';
import chipStatus from 'utils/chipStatus';
import Filter from '../../../components/Filter';
import DataTable from '../../../components/Table';

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    // height: '100%',
    justifyContent: 'center',
});

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '32px',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGray,
}));

export default function Opportunities() {
    const { rows, setRows, oldRows, setOldRows } = useContext(RowsContext);

    const absComparator = (a, b) => Math.round(a.replace(/\./g, '')) - Math.round(b.replace(/\./g, ''));

    const columns = [
        {
            field: 'idGenerate',
            headerName: 'Identificador',
            minWidth: 120,
        },
        {
            field: 'publication',
            headerName: 'Publicação',
            minWidth: 120,
        },
        {
            field: 'activeType',
            headerName: 'Tipo de Ativo',
            minWidth: 230,
        },
        {
            field: 'walletType',
            headerName: 'Tipo de Carteira',
            minWidth: 220,
        },
        {
            field: 'coin',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Typography
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'end',
                    }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'faceValue',
            headerName: 'Valor Original',
            minWidth: 150,
            sortComparator: absComparator,
        },
        {
            field: 'coin',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Typography
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'end',
                    }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'updatedValue',
            headerName: 'Valor Atualizado',
            minWidth: 150,
            sortComparator: absComparator
        },
        {
            field: 'amountDoc',
            headerName: 'Qtd. Documentos',
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            renderCell: (params) => chipStatus(params.value),
        },
    ];

    const rowsPerPage = 100;
    // const [rows, setRows] = useState([]);
    // const [oldRows, setOldRows] = useState([]);

    return (
        <Box
            style={{
                width: '100%',
                height: '100vh',
                minHeight: '1400px',
                flexDirection: 'column',
            }}
        >
            <BoxTitle>
                <img
                    src='/static/image/Oportunidades.svg'
                    alt='Etiqueta - Oportunidades'
                />
                <TypographyTitle variant='h1'>Oportunidades</TypographyTitle>
            </BoxTitle>
            <BoxContainer>
                <Filter rows={rows} setRows={setRows} oldRows={oldRows} />
                <DataTable
                    rows={rows}
                    columns={columns}
                    setRows={setRows}
                    setOldRows={setOldRows}
                    rowsPerPage={rowsPerPage}
                />
            </BoxContainer>
        </Box>
    );
}
