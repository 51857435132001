/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';

import { theme } from 'themes';

const Container = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px auto',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '600px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));
const ContainerBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '32px',
    maxWidth: '600px',
}));

export default function SendEmail() {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    async function handleSendEmail(resendEmail) {
        if (resendEmail) {
            toast.success(
                'E-mail re-enviado! Poderá levar até 10 minutos para recebê-lo.'
            );
        }

        try {
            const res = await api.post(`/api/assessment/sendEmail`, {
                text: 'Teste para envio de email',
            });
        } catch (error) {
            toast.error('Erro ao enviar o email. Tente novamente mais tarde.');
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        handleSendEmail(false);
    }, []);

    return (
        <Container>
            <img src='/static/image/EnvioAtivo.svg' alt='EnvioAtivo' />
            <ContainerBox>
                <Typography
                    variant='h1'
                    align='center'
                    paddingTop='12px'
                    sx={{
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    Envio das informações
                </Typography>
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                    A última etapa do processo de anúncio do ativo é o envio das
                    informações.
                </Typography>
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                    Em instantes, você receberá um <i>e-mail</i> com as instruções para
                    realização do <i> upload</i> do <i> template</i> preenchido
                    e dos documentos de comprovação do crédito.
                </Typography>

                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                    Não recebeu o <i>e-mail</i>?
                </Typography>

                <Button
                    onClick={() => handleSendEmail(true)}
                    endIcon={<CachedIcon />}
                >
                    Reenviar
                </Button>

                <Button
                    variant='contained'
                    color='primary'
                    sx={{
                        width: '240px',
                        height: '48px',
                    }}
                    onClick={() => navigate('/seller/active')}
                >
                    <Typography variant='h5'>
                        Ir para a lista de ativos
                    </Typography>
                </Button>
            </ContainerBox>
        </Container>
    );
}
