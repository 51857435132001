import { Box, Typography } from '@mui/material';
import AsideCard from 'components/AsideCard';
import VerticalBarGraphics from 'components/VerticalBarGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';

const DistributionRegionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const GrapichPieBox = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

export default function Template14({ walletDetails }) {
    const [valuesGraphicsBreakdown, setValuesGraphicsBreakdown] = useState({
        Ano: [],
        Valor: [],
    });
    const getValue = () => {
        if (
            walletDetails?.calculationsWallet[0]['Breakdown do Crédito por Ano']
        ) {
            const anos = walletDetails?.calculationsWallet[0][
                'Breakdown do Crédito por Ano'
            ].map((item) => item.Ano);
            const valores = walletDetails?.calculationsWallet[0][
                'Breakdown do Crédito por Ano'
            ].map((item) => item.Valor);

            setValuesGraphicsBreakdown({
                ...valuesGraphicsBreakdown,
                Ano: [...valuesGraphicsBreakdown.Ano, ...anos],
                Valor: [...valuesGraphicsBreakdown.Valor, ...valores],
            });
        }
    };
    useEffect(() => {
        getValue();
    }, [walletDetails]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Valor Original'
                    content={
                        walletDetails?.calculationsWallet[0]?.[
                            'Total Valor Original'
                        ]
                    }
                    lg
                />
                <AsideCard
                    title='Ente Público'
                    content={
                        walletDetails?.calculationsWallet[0]?.['Ente Público']
                    }
                    lg
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Total Valor do Crédito Tributário'
                    content={
                        walletDetails?.calculationsWallet[0]?.[
                            'Total da Ação Atualizado'
                        ]
                    }
                    lg
                />
                <AsideCard
                    title='Status do Processo'
                    content={
                        walletDetails?.calculationsWallet[0]?.[
                            'Fase Processual'
                        ]
                    }
                    lg
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Período'
                    content={
                        walletDetails?.calculationsWallet[0]?.[
                            'Data do Último Andamento'
                        ]
                    }
                    lg
                />
                <AsideCard
                    title='Data da Última Atualização'
                    content={
                        walletDetails?.calculationsWallet[0]?.[
                            'Data de Último Vencimentos'
                        ]
                    }
                    lg
                />
            </Box>{' '}
            <DistributionRegionBox>
                <Title>Breakdown do Crédito por Ano</Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '240px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <GrapichPieBox>
                        <VerticalBarGraphics
                            labels={valuesGraphicsBreakdown?.Ano}
                            data={valuesGraphicsBreakdown?.Valor}
                            widthGraphics='100%'
                        />
                    </GrapichPieBox>
                </Box>
            </DistributionRegionBox>
        </>
    );
}
