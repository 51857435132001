/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import IndicatorCard from 'components/IndicatorCard';
import ModalSuportSolicitation from 'components/ModalSuportSolicitation';
import UserInterestedTable from 'components/UserInterestedTable';
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';
import Details from 'views/Details';

const Container = styled(Box)({
    // backgroundColor: theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px auto',
    borderRadius: '24px',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // alignItems: 'center',
    minHeight: '600px',
    '& .MuiFormHelperText-root': { height: '0px' },
});

const IndicatorsBox = styled(Box)({
    backgroundColor: theme.palette.themePrimary.primaryWhiteLight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '720px',
    width: '30%',
    margin: '32px',
    border: '1px #D4D4D4 solid',
    borderRadius: '8px',
});

const InterestedBox = styled(Box)({
    backgroundColor: theme.palette.themePrimary.primaryWhiteLight,
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '720px',
    alignItems: 'center',
    // margin: '32px',
    border: '1px #D4D4D4 solid',
    borderRadius: '8px',
});

const FooterBox = styled(Box)({
    width: '1200px',
    margin: '20px 32px 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export default function DetailsActive({
    walletDetails,
    idActive,
    idResume,
    setHasActiveDetails,
    walletResume,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [infosInterest, setInfosInterest] = useState([]);
    const { getUsers } = useContext(UserContext);

    async function handleFileOrUnfileActive() {
        try {
            await api.put(`/api/active/${idActive}`);
            getUsers();
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    }

    async function getInformationsInterest() {
        try {
            const res = await api.get(`/api/user/list/${idActive}`);
            setInfosInterest(res.data);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getInformationsInterest();
    }, []);

    function handleOpenModal() {
        setIsModalOpen(true);
    }
    function handleCloseModal() {
        handleFileOrUnfileActive();
        setIsModalOpen(false);
    }

    return (
        <>
            <ModalSuportSolicitation
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleFileOrUnfileActive={handleFileOrUnfileActive}
                handleCloseModal={handleCloseModal}
                idActive={idActive}
            />
            <Container>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <IndicatorsBox>
                            <IndicatorCard
                                title='Visualizações deste ativo'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.viewsAsset
                                }
                                position='first'
                            />
                            {/* <IndicatorCard
                                title='Solicitações de assinatura de Acordos de Confidencialidade'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.requestsSigningConfidentialityAgreements
                                }
                                position='second'
                            /> */}
                            <IndicatorCard
                                title='Acordos de Confidencialidade em processo de assinatura'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.confidentialityAgreementsSigningProcess
                                }
                                position='second'
                            />
                            <IndicatorCard
                                title='Acordos de Confidencialidade assinados'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.confidentialityAgreementsSigned
                                }
                                position='third'
                            />
                            <IndicatorCard
                                title='Informações detalhadas enviadas'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.detailedInformationSent
                                }
                                position='fourth'
                            />
                            <IndicatorCard
                                title='Propostas recebidas'
                                value={
                                    walletResume?.detailsSellerActive
                                        ?.proposalsReceived
                                }
                                position='fifth'
                            />
                        </IndicatorsBox>

                        <InterestedBox>
                            <Typography
                                variant='h2'
                                sx={{
                                    margin: '0 0 32px 32px',
                                    alignSelf: 'start',
                                }}
                            >
                                Interessados
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: '820px',
                                    height: '70%',
                                    margin: '0 0 0 36px',
                                }}
                            >
                                <UserInterestedTable
                                    interested={infosInterest}
                                />
                            </Box>
                        </InterestedBox>
                    </Box>
                    <Box sx={{ margin: '16px', padding: '4px' }}>
                        <Details
                            sellerIdResume={idResume}
                            setHasActiveDetails={setHasActiveDetails}
                        />
                    </Box>

                    <FooterBox>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >

                            <Typography variant='subtitle1'>
                                Os dados da sua carteira estão incorretos?
                            </Typography>
                            <Button
                                variant='outlined'
                                onClick={handleOpenModal}
                                sx={{
                                    width: '240px',
                                }}
                            >
                                Entre em contato com o suporte
                            </Button>
                        </Box>

                        {/* {(walletDetails.status === 'Disponível' ||
                            walletDetails.status === 'Aguardando assinatura' ||
                            walletDetails.status === 'Recusado' ||
                            walletDetails.status === 'Assinado') && (
                            <Button
                                variant='outlined'
                                startIcon={<WarningAmberIcon />}
                                sx={{
                                    width: '180px',
                                    marginRight: '20px',
                                    background:
                                        theme.palette.themePrimary
                                            .primaryWhiteLight,
                                }}
                                disabled={isLoading}
                                onClick={handleFileOrUnfileActive}
                            >
                                Arquivar carteira
                            </Button>
                        )} */}
                        {/* 
                        {walletDetails.status === 'Arquivado' && (
                            <Button
                                variant='outlined'
                                startIcon={<WarningAmberIcon />}
                                sx={{
                                    width: '200px',
                                    marginRight: '20px',
                                    background:
                                        theme.palette.themePrimary
                                            .primaryWhiteLight,
                                }}
                                disabled={isLoading}
                                onClick={handleFileOrUnfileActive}
                            >
                                Desarquivar carteira
                            </Button>
                        )} */}
                    </FooterBox>
                </Box>
            </Container>
        </>
    );
}
