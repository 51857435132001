/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, Button, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';
import chipStatus from 'utils/chipStatus';
import DetailsActive from './DetailsActive';
import WithoutDetailsActive from './WithoutDetailsActive';

const HeaderContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '32px auto',
    maxWidth: '1200px',
});
const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

export default function DetailsActiveSeller() {
    const navigate = useNavigate();
    const { idActive, idResume } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [walletDetails, setWalletDetails] = useState({});
    const [statusActive, setStatusActive] = useState('');
    const [walletResume, setWalletResume] = useState({});
    const [hasActiveDetails, setHasActiveDetails] = useState(true);
    // const { statusActive } = useContext(RowsContext);

    async function getWalletDetails() {
        try {
            setIsLoading(true);
            const res = await api.post('/api/active', { id: idActive, idBuyer: null });
            setWalletDetails(res.data);
            setStatusActive(res.data.status);
        } catch (e) {
            toast.error(e.response.data.message || e.message || e);
        } finally {
            setIsLoading(false);
        }
    }

    async function getWalletResume() {
        if (!idResume || idResume === 'undefined') {
            return;
        }

        try {
            const res = await api.get(`/api/resume/${idResume}`);
            setWalletResume(res.data);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    }

    useLayoutEffect(() => {
        if (idResume === 'undefined') {
            setHasActiveDetails(false);
        }
        getWalletDetails();
        getWalletResume();
    }, []);

    return !hasActiveDetails ? (
        <WithoutDetailsActive
            walletDetails={walletDetails}
            idActive={idActive}
        />
    ) : (
        <>
            <HeaderContainer>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Button
                        variant='text'
                        color='primary'
                        sx={{ width: '24px', marginBottom: '24px' }}
                        size='4px'
                        startIcon={<ArrowBackIosRoundedIcon />}
                        onClick={() => navigate('/seller/active')}
                    >
                        Voltar
                    </Button>
                    <HeaderBox>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img
                                src='/static/image/Oportunidades.svg'
                                alt='Etiqueta - Oportunidades'
                            />
                            <TypographyTitle
                                sx={{ marginLeft: '14px' }}
                                variant='h1'
                            >
                                Resumo do ativo {walletDetails.idGenerate}
                            </TypographyTitle>
                        </Box>

                        {chipStatus(statusActive)}
                    </HeaderBox>
                </Box>
            </HeaderContainer>

            <DetailsActive
                walletDetails={walletDetails}
                idActive={idActive}
                walletResume={walletResume}
                idResume={idResume}
                setHasActiveDetails={setHasActiveDetails}
            />
        </>
    );
}
