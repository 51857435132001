/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';

const BoxContainer = styled(Box)((props) => ({
    width: '800px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '24px',
    background: props.theme.palette.themePrimary.primaryWhiteLight,
}));

const BoxTitle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    gap: '25px',
});

export default function ModalInterest({
    isOpenModal,
    setIsOpenModal,
    idActive,
}) {
    const { user } = useContext(UserContext);

    const [interest, setInterest] = useState(false);
    const [text, setText] = useState('');

    function handleDeclareInterest() {
        // fazer disparo no pipefy para criar card
        try {
            const res = api.post('/api/declarationInterests', {
                // name: user.name?.toLowerCase(),
                // email: user.email?.toLowerCase(),
                textInterest: text,
                // cnpj: user.company.idCompany.cpnj?.toLowerCase(),
                idActive,
            });
            setInterest(true);

            toast.success(res.data);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    }

    return (
        <Dialog
            onClose={() => setIsOpenModal(false)}
            open={isOpenModal}
            fullWidth
            maxWidth='md'
        >
            <DialogContent
                sx={{
                    height: '410px',
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {interest ? (
                    <BoxContainer>
                        <BoxTitle>
                            <img src='/static/image/Check.svg' alt='Check' />
                            <Typography
                                variant='h1'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Declaração de interesse enviada
                            </Typography>
                        </BoxTitle>

                        <Box
                            sx={{
                                textAlign: 'center',
                                margin: '30px auto',
                            }}
                        >
                            <Typography variant='body1'>
                                Estamos analisando a sua declaração de interesse
                                no ativo.
                            </Typography>
                            <Typography variant='body1'>
                                Em breve, entraremos em contato para informar os
                                próximos passos.
                            </Typography>
                            <Typography variant='body1'>
                                Enquanto isso, continue navegando em busca de
                                oportunidades.
                            </Typography>
                        </Box>
                        <Link to='/buyer/opportunities'>
                            <Button
                                variant='contained'
                                color='primary'
                                endIcon={<OpenInNewRoundedIcon />}
                                onClick={() => setInterest(true)}
                            >
                                Voltar para oportunidades
                            </Button>
                        </Link>
                    </BoxContainer>
                ) : (
                    <BoxContainer>
                        <BoxTitle>
                            <img
                                src='/static/image/Oportunidades.svg'
                                alt='Etiqueta'
                            />
                            <Typography
                                variant='h1'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Declaração de interesse
                            </Typography>
                        </BoxTitle>

                        <Box
                            sx={{
                                textAlign: 'center',
                                margin: '40px auto',
                            }}
                        >
                            <Typography variant='body1'>
                                Por gentileza, descrever em linhas gerais sua
                                proposta para aquisição deste ativo.
                            </Typography>
                            <Typography variant='body1'>
                                Após sua declaração, iremos entrar em contato
                                por e-mail ou telefone para agendar uma reunião
                                e continuar as negociações e definir os próximos
                                passos. Obrigado!
                            </Typography>
                            <TextField
                                id='declaração-interesse'
                                label='Declaração de interesse'
                                sx={{ width: '100%', marginTop: '20px' }}
                                type='text'
                                placeholder='Escreva aqui sobre seu interesse nessa carteira'
                                variant='outlined'
                                minRows={4}
                                maxRows={4}
                                multiline
                                defaultValue={text}
                                onChange={(e) => {
                                    setText(e.target.value);
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 3 }}>
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{ background: '#FFFFFF' }}
                                startIcon={<ArrowBackIosRoundedIcon />}
                                onClick={() => setIsOpenModal(false)}
                            >
                                Não, voltar para resumo
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                endIcon={<CheckRoundedIcon />}
                                disabled={text === ''}
                                onClick={handleDeclareInterest}
                            >
                                Sim, declarar interesse
                            </Button>
                        </Box>
                    </BoxContainer>
                )}
            </DialogContent>
        </Dialog>
    );
}
