import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { IoIosArrowForward } from 'react-icons/io';
import { theme } from 'themes';

const Header = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    margin: '10px 0',
});

export default function CommonQuestions() {
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();

    const getQuestions = async () => {
        try {
            const { data } = await api.get('/api/about');
            setQuestions(data.question);
        } catch (err) {
            console.log('error', err);
        }
    };

    useEffect(() => {
        getQuestions();
    }, []);

    return (
        <>
            <Header>
                <Typography
                    variant='h1'
                    align='left'
                    marginBottom='8px'
                    sx={{ color: theme.palette.themePrimary.primaryGray }}
                >
                    Perguntas Frequentes
                </Typography>
                <Button
                    type='button'
                    onClick={() => navigate('/')}
                    sx={{
                        padding: '10px',
                    }}
                >
                    Voltar
                </Button>
            </Header>
            <Box
                sx={{
                    padding: '0 20px',
                    margin: '20px 0',
                    '> h2': {
                        fontWeight: '600',
                        fontSize: '20px',
                        margin: '10px 0',
                    },
                }}
            >
                {questions?.map((item, index) => (
                    <>
                        {index === 0 && (
                            <Typography
                                component='h2'
                                style={{ color: '#1E49E2' }}
                            >
                                FAQ - Geral
                            </Typography>
                        )}
                        {index === 8 && (
                            <Typography
                                component='h2'
                                style={{ color: '#1E49E2' }}
                            >
                                FAQ - Vendedor
                            </Typography>
                        )}
                        {index === 22 && (
                            <Typography
                                component='h2'
                                style={{ color: '#1E49E2' }}
                            >
                                FAQ - Comprador
                            </Typography>
                        )}

                        <Accordion key={item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <Typography>{item?.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item?.type ? (
                                    <List component='div' disablePadding>
                                        {item.response.map((listItem) => (
                                            <ListItem key={listItem}>
                                                <ListItemIcon>
                                                    <IoIosArrowForward color='#1E49E2' />
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{
                                                        marginLeft: '5px',
                                                    }}
                                                    primary={listItem}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography>{item?.response}</Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </>
                ))}
            </Box>
        </>
    );
}
