/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material';
import AsideCard from 'components/AsideCard';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DetailsActive from 'views/DetailsActiveHeader';
import Template1 from './Template1';
import Template12 from './Template12';
import Template13 from './Template13';
import Template14 from './Template14';
import Template2and9and11 from './Template2and9and11';
import Template3and7and8and10 from './Template3and7and8and10';
import Template4 from './Template4';
import Template5 from './Template5';
import Template6 from './Template6';

// import api from 'services/api';

const Container = styled(Box)({
    width: '100%',
    height: '100%',
    minHeight: '650px',
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
});
const BoxContainer = styled(Box)({
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
});

const BoxNotes = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: '24px',
    // border: '1px #D4D4D4 solid',
    // borderRadius: '4px',
    // background: '#fff',
});

export default function BaseTemplate({ template, walletDetails }) {
    const [titleConditions, setTitleConditinos] = useState('');
    const [contentConditions, setContentConditions] = useState('');
    function handleSwitchTemplate() {
        switch (template) {
            case 1:
                return <Template1 walletDetails={walletDetails} />;
            // case 2:
            //     return <Template3and7and8and10 walletDetails={walletDetails}/>;
            case 3:
                return <Template3and7and8and10 walletDetails={walletDetails} />;
            case 4:
                return <Template4 walletDetails={walletDetails} />;
            case 5:
                return <Template5 walletDetails={walletDetails} />;
            case 6:
                return <Template6 walletDetails={walletDetails} />;
            case 7:
            case 8:
            case 10:
                return <Template3and7and8and10 walletDetails={walletDetails} />;
            case 12:
                return <Template12 walletDetails={walletDetails} />;
            case 13:
                return <Template13 walletDetails={walletDetails} />;
            case 14:
                return <Template14 walletDetails={walletDetails} />;
            default:
                return '';
        }
    }

    useEffect(() => {
        if (walletDetails?.calculationsWallet !== undefined) {
            // eslint-disable-next-line no-constant-condition
            if ([3, 4, 5, 6, 7, 8, 9, 10].includes(template)) {
                setTitleConditinos('Total Saldo em Aberto');
                setContentConditions(
                    `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Total Saldo em Aberto']}`
                );
            } else if (template === 13) {
                setTitleConditinos('Total da Ação Atualizado');
                setContentConditions(
                    `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Total da Ação Atualizado']}`
                );
            } else if (template === 1 || template === 2 || template === 11) {
                setTitleConditinos('Total Valor Atualizado');
                setContentConditions(
                    `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Total Valor Atualizado']}`
                );
            } else if (template === 12) {
                setTitleConditinos('Valor Total Atualizado da Causa');
                setContentConditions(
                    `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Valor Total Atualizado da Causa']}`
                );
            }

            // setContentConditions(
            //     template === 13
            //         ? `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Total da Ação Atualizado']}`
            //         : `${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails.calculationsWallet[0]['Total Valor Atualizado']}`
            // );
        }
    }, [walletDetails]);

    return (
        <Container>
            {walletDetails?.length !== 0 &&
            walletDetails !== undefined &&
            walletDetails !== null ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '1380px',
                        margin: '0 auto',
                    }}
                >
                    <BoxContainer>
                        <DetailsActive
                            activeName={walletDetails?.resume?.activeName}
                            activeType={walletDetails?.resume?.activeType}
                            walletType={walletDetails?.resume?.walletType}
                            creditType={walletDetails?.resume?.creditType}
                            finalDate={walletDetails?.resume?.finalDate}
                            publicationDate={
                                walletDetails?.resume?.publicationDate
                            }
                            baseDate={walletDetails?.resume?.activeDateBase}
                            descripton={
                                walletDetails?.resume?.activeDescription
                            }
                        />

                        {![12, 14].includes(template) && (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '20px',
                                        gap: '4px',
                                    }}
                                >
                                    {!!walletDetails?.calculationsWallet
                                        .length && (
                                        <>
                                            <AsideCard
                                                title='Total Valor Original'
                                                content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Total Valor Original']}`}
                                                // icon='/static/image/VenderAtivos.svg'
                                            />
                                            <AsideCard
                                                title='Quantidade de Documentos'
                                                content={
                                                    walletDetails
                                                        ?.calculationsWallet[0]?.[
                                                        'Quantidade Documentos'
                                                    ]
                                                }
                                                // icon='/static/image/Titulos.svg'
                                            />
                                            <AsideCard
                                                title='Ticket Médio por Documento'
                                                content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Ticket Médio Por Doc.']}`}
                                                templateNumber={`${walletDetails?.template?.number}`}
                                                // icon='/static/image/TicketMedio.svg'
                                            />
                                        </>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '20px',
                                        gap: '4px',
                                    }}
                                >
                                    {!!walletDetails?.calculationsWallet
                                        ?.length && (
                                        <>
                                            <AsideCard
                                                title={titleConditions}
                                                content={contentConditions}
                                                // icon='/static/image/VenderAtivos.svg'
                                            />
                                            <AsideCard
                                                title='Quantidade de Devedores'
                                                content={
                                                    walletDetails
                                                        ?.calculationsWallet[0]?.[
                                                        'Quantidade De Devedores'
                                                    ]
                                                }
                                                // icon='/static/image/Titulos.svg'
                                            />
                                            <AsideCard
                                                title='Ticket Médio por Devedor'
                                                content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Ticket Médio Por Devedor']}`}
                                                templateNumber={`${walletDetails?.template?.number}`}
                                                // icon='/static/image/TicketMedio.svg'
                                            />
                                        </>
                                    )}
                                </Box>
                            </>
                        )}

                        {![12, 13, 14].includes(template) && (
                            <Template2and9and11 walletDetails={walletDetails} />
                        )}

                        {handleSwitchTemplate()}
                        {walletDetails?.notesTeam &&
                            walletDetails?.notesTeam !== '' && (
                                <BoxNotes>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Observação:
                                    </Typography>{' '}
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {walletDetails?.notesTeam}
                                    </Typography>
                                </BoxNotes>
                            )}
                    </BoxContainer>
                </Box>
            ) : (
                <Typography
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    Ops... não é possível visualizar o resumo deste ativo... :(
                </Typography>
            )}
        </Container>
    );
}
