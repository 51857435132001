/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import useUser from 'hooks/User';
import { createContext } from 'react';

const UserContext = createContext();

function UserContextProvider({ children }) {
    const {
        user,
        setUser,
        loading,
        isUserLinkedToCompany,
        setIsUserLinkedToCompany,
        nda,
        setNda,
        getUsers,
    } = useUser();

    // const authUser = useMemo(
    //     () => ({
    //         user,
    //         setUser,
    //         isUserLinkedToCompany,
    //         setIsUserLinkedToCompany,
    //         doc,
    //         setDoc,
    //         getUsers,
    //     }),
    //     [
    //         user,
    //         setUser,
    //         isUserLinkedToCompany,
    //         setIsUserLinkedToCompany,
    //         doc,
    //         setDoc,
    //         getUsers,
    //     ]
    // );

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                loading,
                isUserLinkedToCompany,
                setIsUserLinkedToCompany,
                nda,
                setNda,
                getUsers,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserContextProvider };
