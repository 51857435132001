import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import chipStatus from 'utils/chipStatus';

const StyledDataGrid = styled(DataGrid)(() => ({
    border: 0,
    height: '100%',
    width: '95%',

    '&.MuiDataGrid-root': {
        border: 'none',
        // maxWidth: '1300px',
        overflow: 'hidden',
    },
    '& .MuiDataGrid-main': {
        width: '100%',
    },

    '& .MuiDataGrid-virtualScroller css-1w5m2wr-MuiDataGrid-virtualScroller': {
        // background: '#F9F9F9',
    },

    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
        // background: '#F9F9F9',
        display: 'flex',
        alignItems: 'center',
        // padding: '4px 16px',
        width: '100%',
    },
    '& .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnHeaderTitleContainer':
    {
        display: 'flex',
        width: '100%',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        // color: '#666666',
    },

    // '& .MuiDataGrid-root .MuiDataGrid-row': {
    //     width: '400px',
    // },

    '& .MuiDataGrid-row': {
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        marginBottom: '8px',
        border: `2px solid ${'#EDEDED'}`,
        borderRadius: '8px',
        width: '98%',
        cursor: 'pointer',
    },

    // '& .MuiDataGrid-virtualScrollerRenderZone': {
    //     width: '400px',
    // },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders, .MuiDataGrid-cell':
    {
        border: 0,
    },
    '& .MuiDataGrid-cell': {
        // color: '#666666',
    },
    '& .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
    {
        outline: 0,
    },

    '& .MuiDataGrid-footerContainer': {
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor: '#F9F9F9',
        border: 0,
    },
}));
const NoRowsText = styled(Typography)(() => ({
    display: 'flex',
    height: '100%',
    width: '95%',
    justifyContent: 'center',
    alignItems: 'center',
}));

// const columns = [
//     {
//         field: 'name',
//         headerName: 'Nome do comprador',
//         minWidth: 260,
//     },
//     {
//         field: 'data',
//         headerName: 'Data da declaração de interesse',
//         minWidth: 300,
//     },
//     {
//         field: 'status',
//         headerName: 'Status',
//         minWidth: 240,
//     },
// ];

function formattedDate(date) {
    const originalDate = new Date(date);
    originalDate.setHours(originalDate.getHours() - 3);
    const month = `0${originalDate.getMonth() + 1}`.slice(-2);
    const day = `0${originalDate.getDate()}`.slice(-2);
    const year = originalDate.getFullYear();

    const formatted = `${day}/${month}/${year}`;

    return formatted;
};


const columns = [
    {
        field: 'name',
        headerName: 'Nome do comprador',
        minWidth: 350,
        sortable: false,
    },
    {
        field: 'data',
        headerName: 'Data da ação',
        minWidth: 150,
        sortable: false,
        renderCell: (params) => formattedDate(params.value),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 200,
        // renderCell: (params) => chipStatus(params.value),
        renderCell: (params) => {
            switch (params.value) {
                case 'PENDING':
                case 'Aguardando assinatura':
                    return chipStatus('Aguardando assinatura');
                case 'ACCEPTED':
                case 'Assinado':
                    return chipStatus('Assinado');
                case 'REFUSED':
                case 'Recusado':
                    return chipStatus('Recusado');
                default:
                    return chipStatus(params.value);
            }
        },
    },
];

// const rows = [
//     {
//         id: 1,
//         buyerName: 'Snow',
//         dateInterested: '10/03/2023',
//         status: 'Aguardando assinatura',
//     },
//     {
//         id: 2,
//         buyerName: 'Carlos Drummond de andrade',
//         dateInterested: '10/03/2023',
//         status: 'Vendido',
//     },
//     {
//         id: 3,
//         buyerName: 'Clarice Lispector',
//         dateInterested: '10/03/2023',
//         status: 'Assinado',
//     },
//     {
//         id: 4,
//         buyerName: 'Machado de Assis',
//         dateInterested: '10/03/2023',
//         status: 'Aguardando assinatura',
//     },
//     {
//         id: 5,
//         buyerName: 'Florbela Espanca',
//         dateInterested: '10/03/2023',
//         status: 'Em processamento',
//     },
//     {
//         id: 6,
//         buyerName: 'Augusto dos Anjos',
//         dateInterested: '10/03/2023',
//         status: 'Aguardando assinatura',
//     },
//     {
//         id: 7,
//         buyerName: 'Cecília Meireles',
//         dateInterested: '10/03/2023',
//         status: 'Assinado',
//     },
//     {
//         id: 8,
//         buyerName: 'Cecília Meireles',
//         dateInterested: '10/03/2023',
//         status: 'Assinado',
//     },
//     {
//         id: 9,
//         buyerName: 'Cecília Meireles',
//         dateInterested: '10/03/2023',
//         status: 'Assinado',
//     },
// ];

export default function UserInterestedTable({ interested }) {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            {interested?.length > 0 ? (
                <StyledDataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    getRowId={(row) => row.data}
                    rows={interested}
                    columns={columns}
                />
            ) : (
                <NoRowsText>Sem usuários interessados</NoRowsText>
            )}
        </Box>
    );
}
