/* eslint-disable no-underscore-dangle */
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { RowsContext } from 'context/RowsContext';
import { UserContext } from 'context/UserContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';
import validator from 'validator';

const BoxContainer = styled(Box)((props) => ({
    width: '800px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '24px',
    background: props.theme.palette.themePrimary.primaryWhiteLight,
}));

const BoxTitle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    gap: '25px',
});

export default function ModalInviteUser({ isOpenModal, setIsOpenModal }) {
    const { user } = useContext(UserContext);
    const { getRowsUsers } = useContext(RowsContext);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleSubmit = async () => {
        try {
            const res = await api.post(`/api/user/invite/company/${user._id}`, {
                name,
                email,
            });
            getRowsUsers();
            toast.success(res.message || res.data.message || res.data);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
        setIsOpenModal(false);
    };

    const handleSetName = (e) => {
        setName(e);
        if (e === '') {
            setNameError(true);
            return;
        }
        setNameError(false);
    };

    const handleSetEmail = (e) => {
        const { value } = e.target;
        if (validator.isEmail(value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
        setEmail(e.target.value);
    };

    return (
        <Dialog
            onClose={() => setIsOpenModal(false)}
            open={isOpenModal}
            fullWidth
            maxWidth='sm'
        >
            <DialogContent
                sx={{
                    height: '450px',
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <BoxContainer>
                    <BoxTitle>
                        <Typography
                            variant='h1'
                            sx={{
                                color: theme.palette.themePrimary
                                    .primaryGrayDark,
                            }}
                        >
                            Convidar usuário
                        </Typography>
                    </BoxTitle>

                    <Box
                        sx={{
                            textAlign: 'center',
                            margin: '20px 0px 30px 0px',
                        }}
                    >
                        <Typography variant='body1'>
                            Aqui você pode convidar usuários para participar da
                            sua organização
                        </Typography>
                    </Box>

                    <FormControlLabel
                        sx={{
                            margin: '12px auto',
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column-reverse',
                        }}
                        control={
                            <TextField
                                id='nome'
                                placeholder='Nome completo'
                                variant='outlined'
                                error={nameError}
                                helperText={nameError && 'Insira um nome'}
                                sx={{
                                    marginTop: '6px',
                                    width: '250px',
                                }}
                                onChange={(e) => handleSetName(e.target.value)}
                            />
                        }
                        label={
                            <Typography variant='h5'>
                                Nome completo *
                            </Typography>
                        }
                    />

                    <FormControlLabel
                        sx={{
                            margin: '12px auto',
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column-reverse',
                        }}
                        control={
                            <TextField
                                id='e-mail'
                                placeholder='usuário@email.com'
                                variant='outlined'
                                error={emailError !== '' && emailError}
                                helperText={
                                    emailError && 'Insira um e-mail válido'
                                }
                                sx={{
                                    marginTop: '6px',
                                    width: '250px',
                                }}
                                onChange={(e) => handleSetEmail(e)}
                            />
                        }
                        label={<Typography variant='h5'>E-mail *</Typography>}
                    />

                    <Box sx={{ display: 'flex', margin: '25px auto' }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setIsOpenModal(false)}
                            sx={{ margin: '0 5px' }}
                        >
                            Cancelar
                        </Button>

                        <Button
                            variant='contained'
                            color='primary'
                            sx={{ margin: '0 5px' }}
                            onClick={handleSubmit}
                            disabled={
                                name === '' ||
                                email === '' ||
                                name.length === 0 ||
                                email.length === 0 ||
                                emailError
                            }
                        >
                            Enviar convite
                        </Button>
                    </Box>
                </BoxContainer>
            </DialogContent>
        </Dialog>
    );
}
