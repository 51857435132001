/* eslint-disable react/button-has-type */
import { useEffect, useState } from 'react';

import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { cpf } from 'cpf-cnpj-validator';
import api from 'services/api';
import { theme } from 'themes';
import { handleFormatCPF, handleFormatPhone } from 'utils/formats';

const TypographyOptions = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '484px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));

export default function ComplementaryData({
    CPF,
    setCPF,
    setErrorCPF,
    position,
    setPosition,
    cellNumber,
    setCellNumber,
    commercialPhone,
    setCommercialPhone,
}) {
    const [userName, setUserName] = useState('');

    const getUserName = async () => {
        try {
            const { data } = await api.get('/profile/b2c');
            if (data?.token) localStorage.setItem('token', data.token);
            const name = data.name.includes(',')
                ? data.name?.split(' ')[1]
                : data.name?.split(' ')[0];
            setUserName(name);
        } catch (e) {
            // console.log('error', e);
        }
    };

    useEffect(() => {
        getUserName();
    }, []);

    useEffect(() => {
        if (!cpf.isValid(CPF)) {
            setErrorCPF(true);
            return;
        }
        setErrorCPF(false);
    }, [CPF]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '24px',
                        height: '24px',
                        background: '#1E49E2',
                        display: 'flex',
                        borderRadius: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '46px 0 12px 0',
                    }}
                >
                    <img src='/static/image/UmBranco.svg' alt='Um' />
                </Box>
                <Typography
                    variant='h1'
                    align='center'
                    marginBottom='8px'
                    paddingTop='8px'
                    sx={{ color: theme.palette.themePrimary.primaryGray }}
                >
                    Dados complementares
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '35px' }}>
                    {userName}, precisamos de mais alguns dados seus para
                    avançarmos com o cadastro
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ marginLeft: '120px', alignSelf: 'start' }}
                >
                    Dados do responsável
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            marginBottom: '40px',
                            columnGap: 3,
                            rowGap: 1,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            display: 'grid',
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='CPF'
                                    value={CPF}
                                    onChange={(e) => {
                                        setCPF(handleFormatCPF(e.target.value));
                                    }}
                                    inputProps={{ maxLength: 14 }}
                                    error={CPF.length > 3 && !cpf.isValid(CPF)}
                                    helperText={
                                        CPF.length > 3 &&
                                        !cpf.isValid(CPF) &&
                                        'Insira um CPF válido'
                                    }
                                    placeholder='000.000.000-00'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    CPF{' '}
                                    <spam style={{ color: '#D10000' }}>*</spam>
                                </TypographyOptions>
                            }
                        />

                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    value={position}
                                    onChange={(e) =>
                                        setPosition(e.target.value)
                                    }
                                    id='cargo'
                                    placeholder='Qual o seu cargo?'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Cargo
                                </TypographyOptions>
                            }
                        />

                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='commercialPhone'
                                    value={commercialPhone}
                                    onChange={(e) =>
                                        setCommercialPhone(
                                            handleFormatPhone(e.target.value)
                                        )
                                    }
                                    inputProps={{ maxLength: 19 }}
                                    error={
                                        commercialPhone.length > 2 &&
                                        commercialPhone.length < 19
                                    }
                                    helperText={
                                        commercialPhone.length > 2 &&
                                        commercialPhone.length < 19 &&
                                        'Insira um número válido'
                                    }
                                    placeholder='+00 (00) 00000-0000'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Telefone comercial
                                </TypographyOptions>
                            }
                        />

                        <FormControlLabel
                            sx={{
                                margin: '12px auto',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column-reverse',
                            }}
                            control={
                                <TextField
                                    id='celular'
                                    value={cellNumber}
                                    onChange={(e) =>
                                        setCellNumber(
                                            handleFormatPhone(e.target.value)
                                        )
                                    }
                                    inputProps={{ maxLength: 19 }}
                                    error={
                                        cellNumber.length > 2 &&
                                        cellNumber.length < 19
                                    }
                                    helperText={
                                        cellNumber.length > 2 &&
                                        cellNumber.length < 19 &&
                                        'Insira um número válido'
                                    }
                                    placeholder='+00 (00) 00000-0000'
                                    variant='outlined'
                                    sx={{
                                        marginTop: '8px',
                                        width: '312px',
                                    }}
                                />
                            }
                            label={
                                <TypographyOptions variant='h5'>
                                    Telefone celular{' '}
                                    <spam style={{ color: '#D10000' }}>*</spam>
                                </TypographyOptions>
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </FormBox>
    );
}
