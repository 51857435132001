import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '500px',
}));

const CheckBox = styled(Checkbox)({
    color: '#595959',
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            fill: '#1477F8',
        },
    },
});

export default function TermsOfUse({
    isUserAcceptedTerms,
    setIsUserAcceptedTerms,
    hasSocialReason,
}) {
    const [term, setTerm] = useState('');

    async function getTermsOfUse() {
        try {
            const res = await api.get('/api/user/list/term/comprador');
            setTerm(res.data?.terms);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    }

    useEffect(() => {
        getTermsOfUse();
    }, []);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '24px',
                        height: '24px',
                        background: '#1E49E2',
                        display: 'flex',
                        borderRadius: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '50px 0 15px 0',
                    }}
                >
                    {hasSocialReason ? (
                        <img src='/static/image/DoisBranco.svg' alt='Dois' />
                    ) : (
                        <img src='/static/image/UmBranco.svg' alt='Um' />
                    )}
                </Box>
                <Typography
                    variant='h1'
                    align='center'
                    marginBottom='8px'
                    sx={{ color: theme.palette.themePrimary.primaryGray }}
                >
                    Termo de Responsabilidade
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ margin: '10px 40px 40px 40px', textAlign: 'center' }}
                >
                    Abaixo destacamos os termos e condições para utilização da
                    KPMG Plataforma de Ativos Alternativos. Para o Comprador,
                    não existe custo algum para acessar a plataforma.
                </Typography>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: '40px',
                        overflow: 'auto',
                        maxHeight: '300px',
                    }}
                >
                    <Scrollbars
                        style={{
                            width: '100%',
                            height: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant='body1'
                            width='90%'
                            marginLeft='4%'
                            marginRight='12px'
                        >
                            {term?.responsability}
                        </Typography>
                    </Scrollbars>

                    <FormControlLabel
                        sx={{ marginTop: '4px' }}
                        control={
                            <CheckBox
                                size='small'
                                sx={{ borderRadius: '4px' }}
                                defaultChecked={isUserAcceptedTerms.use}
                                onClick={() =>
                                    setIsUserAcceptedTerms({
                                        ...isUserAcceptedTerms,
                                        use: !isUserAcceptedTerms.use,
                                    })
                                }
                            />
                        }
                        label={
                            <Typography
                                variant='body2'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Eu li e aceito o Termo de Responsabilidade da KPMG Plataforma de Ativos Alternativos.
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        sx={{ marginTop: '2px' }}
                        control={
                            <CheckBox
                                size='small'
                                sx={{ borderRadius: '4px' }}
                                defaultChecked={
                                    isUserAcceptedTerms.notification
                                }
                                onClick={() =>
                                    setIsUserAcceptedTerms({
                                        ...isUserAcceptedTerms,
                                        notification:
                                            !isUserAcceptedTerms.notification,
                                    })
                                }
                            />
                        }
                        label={
                            <Typography
                                variant='body2'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Aceito receber notificações da KPMG Plataforma
                                de Ativos Alternativos.
                            </Typography>
                        }
                    />
                </Box>
            </Box>
        </FormBox>
    );
}
