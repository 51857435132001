/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */

import { UserContext } from 'context/UserContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';

const RowsContext = createContext();

function RowsContextProvider({ children }) {
    const { user, getUsers } = useContext(UserContext);
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [rowsUsers, setRowsUsers] = useState([]);
    const [oldRowsUser, setOldRowsUser] = useState(rowsUsers);
    const [oldRows, setOldRows] = useState([]);
    const [minFaceValue, setMinFaceValue] = useState('');
    const [maxFaceValue, setMaxFaceValue] = useState('');
    const [totalRows, setTotalRows] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNotCheckedFilters, setHasNotCheckedFilters] = useState(true);
    const [hasNotInputMinMax, setHasNotInputMinMax] = useState(true);
    // const [statusActive, setStatusActive] = useState('');

    let sortingStatus;

    if (user?.group === 'comprador') {
        sortingStatus = {
            Assinado: 1,
            'Aguardando assinatura': 2,
            Disponível: 3,
            Comprado: 4,
            Recusado: 5,
            Vendido: 6,
        };
    }
    // else {
    //     sortingStatus = {
    //         Disponível: 1,
    //         'Aguardando assinatura': 2,
    //         Recusado: 3,
    //         'Em processamento': 4,
    //         'Aguardando upload': 5,
    //         Vendido: 6,
    //         Arquivado: 7,
    //     };
    // }

    function sortDate(a, b) {
        return (
            new Date(b?.publication?.split('/').reverse().join('-')).getTime() -
            new Date(a?.publication?.split('/').reverse().join('-')).getTime()
        );
    }

    function sortFields(a, b) {
        return (
            sortingStatus[a?.status] - sortingStatus[b?.status] ||
            sortDate(a, b)
        );
    }
    const arrayPathname = ['/buyer/opportunities', '/seller/active'];

    // const isFirstRender = useRef(true);

    // useEffect(() => {
    //     if (isFirstRender.current) {
    //         isFirstRender.current = false;
    //         return;
    //     }
    //     getUsers();
    // }, []);

    function formattedDate(date) {
        const originalDate = new Date(date);

        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formatted = `${day}/${month}/${year}`;

        return formatted;
    }

    const getRowsActive = async () => {

        try {
            let { data } = await api.get(`/api/active?page=1`);

            if (data?.active === '[]' || data?.active.length === 0) {
                setMinFaceValue('0.00');
                setMaxFaceValue('0.00');
            } else {
                setMinFaceValue(data.min);
                setMaxFaceValue(data.max);
            }

            setTotalRows(data.totalPages);

            data = data?.active?.map((item) => item);

            data?.sort(user.group === 'comprador' ? sortFields : sortDate);

            data?.map(
                (item) => (item.idGenerate = Number(item.idGenerate))
            );
            data?.forEach((item) => {
                if (item.status === 'ACCEPTED') {
                    item.status = 'Assinado';
                }
                if (item.status === 'PENDING') {
                    item.status = 'Aguardando assinatura';
                }
                if (item.status === 'REFUSED') {
                    item.status = 'Recusado';
                }
            });

            const dataSellerFiltered = data?.map((item) =>
                item.status === 'Aguardando upload' ||
                    item.status === 'Em processamento'
                    ? {
                        ...item,
                        coin: '',
                        updatedValue: '-------------',
                        faceValue: '-------------',
                        amountDoc: '-------------',
                    }
                    : item
            );

            const rowsGroupCondition =
                user.group === 'comprador' ? data : dataSellerFiltered;

            setRows(rowsGroupCondition);
            setOldRows(rowsGroupCondition);
        } catch (error) {
            toast.error(
                error.response.data.message || error.message || error
            );
        }

    };

    const getRowsUsers = async () => {
        try {
            if (user._id !== undefined) {
                const { data } = await api.get(`/api/user/admin/${user._id}`);

                data?.map(
                    (item) => (item.createdAt = formattedDate(item?.createdAt))
                );

                data?.sort((a, b) => {
                    if (a?.company?.status < b?.company?.status) {
                        return 1;
                    } else if (a?.company?.status > b?.company?.status) {
                        return -1;
                    }
                    return a.name.localeCompare(b.name);
                });
                setRowsUsers(data);
                setOldRowsUser(data);
            }
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    };

    useEffect(() => {
        if (user?.email && localStorage.getItem('token')) {
            if (arrayPathname.some((element) => element === location.pathname)) {
                if (hasNotInputMinMax && hasNotCheckedFilters) {
                    setCurrentPage(1);
                    getRowsActive();
                }
            }
        }
    }, [location.pathname, user]);

    return (
        <RowsContext.Provider
            value={{
                getRowsUsers,
                rows,
                setRows,
                rowsUsers,
                setRowsUsers,
                oldRowsUser,
                setOldRowsUser,
                oldRows,
                setOldRows,
                minFaceValue,
                maxFaceValue,
                totalRows,
                currentPage,
                setCurrentPage,
                setTotalRows,
                hasNotCheckedFilters,
                setHasNotCheckedFilters,
                hasNotInputMinMax,
                setHasNotInputMinMax,
                // statusActive,
                // setStatusActive,
            }}
        >
            {children}
        </RowsContext.Provider>
    );
}

export { RowsContext, RowsContextProvider };

