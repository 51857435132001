import { Box } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(...registerables);

export default function BarGraphics({ labels, data, values, type }) {
    const dataInfo = {
        labels,
        datasets: [
            {
                label: 'Valor: ',
                data,
                minBarThickness: 8,
                minBarLength: 2,
                backgroundColor: [
                    'rgba(101, 216, 174, 0.8)',
                    'rgba(197, 144, 229, 0.8)',
                    'rgba(220, 145, 121, 0.8)',
                    'rgba(100, 129, 235, 0.8)',
                    'rgba(236, 223, 112, 0.8)',
                    'rgba(115, 214, 220, 0.8)',
                    'rgba(143, 215, 255, 0.8)',
                ],
                borderColor: [
                    'rgba(101, 216, 174, 1)',
                    'rgba(197, 144, 229, 1)',
                    'rgba(220, 145, 121, 1)',
                    'rgba(100, 129, 235, 1)',
                    'rgba(236, 223, 112, 1)',
                    'rgba(115, 214, 220, 1)',
                    'rgba(143, 215, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    let yTitle;
    let xTitle;

    switch (type) {
        case 'ageRange':
            xTitle = 'Valor Original';
            yTitle = 'Ano de Fabricação';
            break;

        case 'index':
            xTitle = 'Saldo em Aberto';
            yTitle = 'Índice';
            // border = colors.warrantyPerson.borderColor;
            break;

        default:
            xTitle = 'Valor Original';
            yTitle = 'Ano';

        // border = colors.facePerson.borderColor;
    }

    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                title: {
                    display: true,
                    text: xTitle,
                },
            },
            y: {
                title: {
                    display: true,
                    text: yTitle,
                },
            },
        },
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                values,
            },
            legend: {
                // position: 'bottom',
                display: false,
            },
        },
    };

    return (
        <Box sx={{ width: '500px', minHeight: '500px' }}>
            <Bar data={dataInfo} options={options} />
        </Box>
    );
}
