import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'themes';

const DetailsBox = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    marginRight: '8px',
    color: theme.palette.primary.main,
});

export default function DetailsActive({
    activeName,
    activeType,
    walletType,
    creditType,
    finalDate,
    publicationDate,
    baseDate,
    descripton,
}) {
    return (
        <DetailsBox>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Title variant='body'>Nome do Ativo:</Title>
                        <Typography variant='body'>{activeName}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Title variant='body'>Tipo de Ativo:</Title>
                        <Typography variant='body'>{activeType}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Title variant='body'>Tipo de Carteira:</Title>
                        <Typography variant='body'>{walletType}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Title variant='body'>Tipo de Crédito:</Title>
                        <Typography variant='body'>{creditType}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Title variant='body'>
                            Data Final para Recebimento das Propostas:
                        </Title>
                        <Typography variant='body'>{finalDate}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                marginRight: '20px',
                            }}
                        >
                            <Title variant='body'>Data Publicação:</Title>
                            <Typography variant='body'>
                                {publicationDate}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Title variant='body'>Data Base Ativo:</Title>
                            <Typography variant='body'>{baseDate}</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Title variant='body'>Descrição do Ativo:</Title>
                        <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>{descripton}</Typography>
                    </Box>
                </Box>
            </Box>
        </DetailsBox>
    );
}
