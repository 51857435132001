import { Box, Button, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import fileDownload from 'js-file-download';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '600px',
}));
const ContainerBox = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '24px',
    flexDirection: 'column',
    maxWidth: '600px',
});

export default function Download() {
    const navigate = useNavigate();
    const { idActive, idResume } = useParams();
    const [userPermission, setUserPermission] = useState(true);

    const handleDownloadTemplate = async () => {
        try {
            const res = await api.get(`/api/resume/download/${idResume}`, {
                params: {
                    cacheBustTimestamp: Date.now(),
                },
                responseType: 'blob',
            });

            if (!(res.data instanceof Blob)) return;

            fileDownload(res.data, `${idResume}-${+new Date()}.xlsx`);

            toast.success('O Download iniciará em instantes.');
        } catch (error) {
            const convertReponsoForText = JSON.parse(
                await error.response.data.text()
            );
            if (
                convertReponsoForText.message ===
                    'Sem permissão para download!' ||
                error.response.data.message === 'Sem permissão para download!'
            ) {
                setUserPermission(false);
                window.history.go(-1);
                return;
            }
            toast.error(
                'Erro ao fazer o download do ativo. Tente novamente mais tarde.'
            );
        }
    };

    useLayoutEffect(() => {
        if (userPermission) handleDownloadTemplate();
    }, []);

    return (
        <FormBox>
            <ContainerBox>
                {userPermission ? (
                    <>
                        <img src='/static/image/Download.svg' alt='Download' />
                        <Typography
                            variant='h1'
                            align='center'
                            marginBottom='8px'
                            paddingTop='12px'
                            sx={{
                                color: theme.palette.themePrimary.primaryGray,
                            }}
                        >
                            <i>Download</i> do ativo
                        </Typography>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src='/static/image/ValidationCross.svg'
                            alt='Cancel'
                        />
                        <Typography
                            variant='h1'
                            width='700px'
                            align='center'
                            marginBottom='8px'
                            paddingTop='12px'
                            sx={{
                                color: theme.palette.themePrimary.primaryGray,
                            }}
                        >
                            Você não tem acesso ao download do ativo
                        </Typography>
                    </Box>
                )}

                <Typography
                    variant='body1'
                    sx={{ textAlign: 'center', marginBottom: '35px' }}
                >
                    {userPermission ? (
                        <>
                            Caso o download da carteira não comece em instantes,
                            <Button onClick={handleDownloadTemplate}>
                                clique aqui.
                            </Button>
                        </>
                    ) : (
                        'Solicite ao administrador da conta da empresa o acesso do detalhe do ativo.'
                    )}
                </Typography>

                {userPermission ? (
                    <Button
                        variant='contained'
                        color='primary'
                        sx={{
                            width: '240px',
                            height: '48px',
                            marginTop: '8px',
                        }}
                        onClick={() =>
                            navigate(
                                `/buyer/details/active/${idActive}/${idResume}`
                            )
                        }
                    >
                        <Typography variant='h5'>
                            Voltar para o resumo do ativo
                        </Typography>
                    </Button>
                ) : (
                    <Button
                        variant='outlined'
                        color='primary'
                        sx={{
                            width: '240px',
                            height: '48px',
                            marginTop: '8px',
                        }}
                        endIcon={
                            <img
                                src='/static/image/VoltarOportunidades.svg'
                                alt='VoltarOportunidades'
                            />
                        }
                        onClick={() => navigate('/buyer/opportunities')}
                    >
                        <Typography variant='h5'>
                            Voltar para oportunidades
                        </Typography>
                    </Button>
                )}
            </ContainerBox>
        </FormBox>
    );
}
