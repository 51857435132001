/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useState } from 'react';

import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

const StepperBuyer = styled(Stepper)(() => ({
    border: 0,
    color: '#666666',
    background: '#FFFFFF',
    height: '55px',
    margin: '32px 80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 30px',
    borderRadius: '24px',
}));

const StepCustom = styled(Step)(() => ({
    '&. MuiStepConnector-line': {
        display: 'none',
    },
    '&. MuiStepConnector-lineVertical': {
        display: 'none',
    },
    '&. MuiStepConnector-icon': {
        display: 'block',
    },
}));

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
    color: '#fff',
    width: 16,
    height: 16,
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: '#1E49E2',
    }),
    ...(ownerState.completed && {
        background: '#1E49E2',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed } = props;

    const icons = {
        1: (
            <img
                src={
                    completed || active
                        ? '/static/image/UmBranco.svg'
                        : '/static/image/Um.svg'
                }
                alt='Um'
            />
        ),
        2: (
            <img
                src={
                    completed || active
                        ? '/static/image/DoisBranco.svg'
                        : '/static/image/Dois.svg'
                }
                alt='Dois'
            />
        ),
        3: (
            <img
                src={
                    completed || active
                        ? '/static/image/TresBranco.svg'
                        : '/static/image/Tres.svg'
                }
                alt='Tres'
            />
        ),
        4: (
            <img
                src={
                    completed || active
                        ? '/static/image/QuatroBranco.svg'
                        : '/static/image/Quatro.svg'
                }
                alt='Quatro'
            />
        ),
        5: (
            <img
                src={
                    completed || active
                        ? '/static/image/CincoBranco.svg'
                        : '/static/image/Cinco.svg'
                }
                alt='Cinco'
            />
        ),
        6: (
            <img
                src={
                    completed || active
                        ? '/static/image/SeisBranco.svg'
                        : '/static/image/Seis.svg'
                }
                alt='Seis'
            />
        ),
        7: (
            <img
                src={
                    completed || active
                        ? '/static/image/SeteBranco.svg'
                        : '/static/image/Sete.svg'
                }
                alt='Sete'
            />
        ),
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const steps = [1, 2, 3, 4, 5, 6, 7];

export default function StepperAssessementsSeller() {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [resultAssessment, setResultAssessment] = useState({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
        step5: null,
        step6: '',
        step7: null,
    });
    const [blockNextButton, setBlockNextButton] = useState(true);
    const assessment = {
        'Algum critério específico para a seleção do ativo objeto da venda?':
            resultAssessment.step1,
        'Descreva as principais caracterisicas de seu ativo. Importante: não prover informações que possam vir a identificar o ativo, credores ou mesmo devedores. Esta informação será publicada na plataforma. O que incluir aqui: Como o crédito/ativo foi originado?Qual o tamanho do ativo/carteira/crédito ? ':
            resultAssessment.step2,
        'Data final para recebimento das propostas': resultAssessment.step3,
        'Qual o valor atualizado estimado do ativo?': resultAssessment.step4,
        'Os documentos suportes estão prontos para uma diligência?':
            resultAssessment.step5,
        'Por favor, indique um nome para seu ativo. Exemplo: Crédito ABC, Carteira XYZ, Precatório 123, Carteira Varejo 1, etc. Importante: não prover informações que possam vir a identificar o ativo, credores ou mesmo devedores. Esta informação será publicada na plataforma.':
            resultAssessment.step6,
        'Qual o tipo do seu ativo?': resultAssessment.step7,
        creditType: resultAssessment?.step7?.[1],
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSendAssessment = async () => {
        try {
            const res = await api.post('/api/assessment', {
                assessment,
            });
            navigate('/seller/register/wallet', {
                state: { data: res.data },
            });
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Step1
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 1:
                return (
                    <Step2
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 2:
                return (
                    <Step3
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 3:
                return (
                    <Step4
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 4:
                return (
                    <Step5
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 5:
                return (
                    <Step6
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );
            case 6:
                return (
                    <Step7
                        setResultAssessment={setResultAssessment}
                        resultAssessment={resultAssessment}
                    />
                );

            default:
                return null;
        }
    }

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (resultAssessment.step1.length < 3) {
                    setBlockNextButton(true);
                    return;
                }

                setBlockNextButton(false);
                break;

            case 1:
                if (resultAssessment.step2.length < 3) {
                    setBlockNextButton(true);
                    return;
                }
                setBlockNextButton(false);
                break;

            case 2:
                setBlockNextButton(false);
                break;

            case 3:
                if (resultAssessment.step4 < 3) {
                    setBlockNextButton(true);
                    return;
                }
                setBlockNextButton(false);
                break;

            case 4:
                if (resultAssessment.step5 === null) {
                    setBlockNextButton(true);
                    return;
                }
                setBlockNextButton(false);
                break;

            case 5:
                if (resultAssessment.step6?.length < 3) {
                    setBlockNextButton(true);
                    return;
                }
                setBlockNextButton(false);
                break;

            case 6:
                if (resultAssessment.step7 === null) {
                    setBlockNextButton(true);
                    return;
                }
                setBlockNextButton(false);
                break;

            default:
                break;
        }
    }, [activeStep, resultAssessment]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '32px 80px 16px 80px',
                }}
            >
                <BoxTitle>
                    <img
                        src='/static/image/CriarConta.svg'
                        alt='Cadeado - Criar conta'
                    />
                    <TypographyTitle variant='h1'>
                        Detalhamento das características do seu ativo
                    </TypographyTitle>
                </BoxTitle>
                <Button
                    variant='text'
                    color='primary'
                    endIcon={<CloseRoundedIcon />}
                    onClick={() => navigate('/seller/active')}
                >
                    Fechar
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // textAlign: 'center',
                    margin: 'auto 115px',
                }}
            >
                <Typography variant='body1'>
                    O processo de anúncio de um ativo está dividido em três
                    etapas:
                </Typography>
                <Box
                    sx={{
                        margin: '5px 25px',
                    }}
                >
                    <Typography variant='body1'>
                        1. Detalhamento das características do seu ativo;
                    </Typography>
                    <Typography variant='body1'>
                        2. <i>Download</i> e preenchimento do <i>template</i>{' '}
                        com as informações específicas do seu ativo;
                    </Typography>
                    <Typography variant='body1'>
                        3. <i> Upload </i> do <i> template </i> e de documentos
                        de comprovação do crédito.
                    </Typography>
                </Box>

                <Typography variant='body1'>
                    Nesta primeira etapa, precisamos que você preencha as
                    informações solicitadas abaixo:
                </Typography>
            </Box>
            <StepperBuyer
                activeStep={activeStep}
                connector={
                    <ArrowForwardIosRoundedIcon sx={{ width: '12px' }} />
                }
            >
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <StepCustom key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={ColorlibStepIcon}
                            />
                        </StepCustom>
                    );
                })}
            </StepperBuyer>

            {getStepContent(activeStep)}
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px 80px',
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    sx={{ background: '#FFFFFF' }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<ArrowBackIosRoundedIcon />}
                >
                    Passo anterior
                </Button>
                {activeStep === steps.length - 1 ? (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSendAssessment}
                        endIcon={<CheckRoundedIcon />}
                        disabled={blockNextButton}
                    >
                        Concluir cadastro
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={blockNextButton}
                        onClick={handleNext}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                        Próximo passo
                    </Button>
                )}
            </Box>
        </Box>
    );
}
