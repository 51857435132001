import { Box, Typography } from '@mui/material';
import DetailsActiveTable from 'components/DetailsActiveTable';
import VerticalBarGraphics from 'components/VerticalBarGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';

const DistributionRegionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

export default function Template1({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsPublicVsPrivate, setValuesGraphicsPublicVsPrivate] =
        useState({});

    useEffect(() => {
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição Valor Original e Quantidade de documentos do Setor Público / Privado'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição Valor Original e Quantidade de documentos do Setor Público / Privado'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = { ...valuesGraphics, [key]: valueKey };
        });
        setValuesGraphicsPublicVsPrivate(valuesGraphics);
    }, [walletDetails]);

    return (
        <DistributionRegionBox>
            <Title>
                Distribuição Valor Original e Quantidade de documentos do Setor
                Público / Privado
            </Title>
            <Box
                sx={{
                    width: '100%',
                    height: '280px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                }}
            >
                <VerticalBarGraphics
                    labels={valuesGraphicsPublicVsPrivate?.Setor}
                    data={valuesGraphicsPublicVsPrivate?.['Valor Original']}
                />
                <DetailsActiveTable
                    rows={formatRows(
                        walletDetails?.calculationsWallet?.[0]?.[
                        'Distribuição Valor Original e Quantidade de documentos do Setor Público / Privado'
                        ], walletDetails?.calculationsWallet?.[0]?.Moeda
                    )}
                    columns={formatColumns(
                        walletDetails?.calculationsWallet?.[0]?.[
                        'Distribuição Valor Original e Quantidade de documentos do Setor Público / Privado'
                        ]
                    )}
                    sx={{ height: '200px' }}
                />
            </Box>
        </DistributionRegionBox>
    );
}
