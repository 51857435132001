/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import {
    Box,
    Button,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { UserContext } from 'context/UserContext';
import fileDownload from 'js-file-download';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';
import { handleFormatCNPJ } from 'utils/formats';

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '32px 32px 32px 0px',
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

const TypographyOptions = styled(Typography)({
    color: theme.palette.themePrimary.primaryGrayDark,
});

export default function CompanyData() {
    const navigate = useNavigate();

    const { user, getUsers } = useContext(UserContext);

    const [CNPJ, setCNPJ] = useState(
        user?.company?.idCompany?.cnpj
            ?.replaceAll('.', '')
            ?.replace('-', '')
            ?.replace('/', '') || ''
    );
    const [name, setName] = useState(
        user?.nda?.nameSigner || ''
    );
    const [email, setEmail] = useState(
        user?.nda?.emailSigner || ''
    );
    const [reasonSocial, setReasonSocial] = useState(
        user?.company?.idCompany?.reasonSocial || ''
    );
    const [contractSocialFile, setContractSocialFile] = useState(
        user?.company?.idCompany?.contractSocialFile?.split('/')[2] || ''
    );
    const [confidencialitylFile, setConfidencialityFile] = useState(
        user?.nda?.documentName || ''
    );

    const [isLoading, setIsLoading] = useState(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getUsers();
    }, []);

    useEffect(() => {
        setCNPJ(
            user?.company?.idCompany?.cnpj
                ?.replaceAll('.', '')
                ?.replace('-', '')
                ?.replace('/', '')
        );
        setReasonSocial(user?.company?.idCompany?.reasonSocial);
        setName(user?.nda?.nameSigner);
        setEmail(user?.nda?.emailSigner);
        setContractSocialFile(
            user?.company?.idCompany?.contractSocialFile?.split('/')[2]
        );
        setConfidencialityFile(
            user?.nda?.documentName
        );
    }, [user]);

    // useEffect(() => {
    //     getUsers();
    // }, []);

    const handleDownloadContract = async () => {
        try {
            setIsLoading(true);
            const res = await api.get(
                `/api/blob/download/file/company/${CNPJ}/social`,
                {
                    params: {
                        cacheBustTimestamp: Date.now(),
                    },
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/octet-stream',
                    },
                }
            );

            if (!(res.data instanceof Blob)) return;

            fileDownload(
                res.data,
                `${contractSocialFile.split('.')[0]}-${+new Date()}.pdf`
            );

            toast.success(res.data.message);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadConfidentiality = async () => {
        try {
            setIsLoading(true);
            const res = await api.get(
                `/api/blob/download/file/company/${CNPJ}/confidentiality/${user.nda._id}`,
                {
                    params: {
                        cacheBustTimestamp: Date.now(),
                    },
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/octet-stream',
                    },
                }
            );

            if (!(res.data instanceof Blob)) return;

            fileDownload(
                res.data,
                `${confidencialitylFile.split('.')[0]}-${+new Date()}.pdf`
            );

            toast.success(res.data.message);
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    //     if (confidencialitylFile === undefined) {
    //         getConfidentiality();
    //     } else {
    //         setAwaitAproveConfidenciality(false);
    //     }
    // }, [user, confidencialitylFile]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '85%',
                maxWidth: '1380px',
                margin: '0 auto',
            }}
        >
            {isLoading || user.email !== '' ? (
                <>
                    <Box
                        sx={{ minHeight: user.group === 'vendedor' && '65vh' }}
                    >
                        <Box
                            sx={{
                                margin: '0 0 20px 60px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <FormControlLabel
                                sx={{
                                    margin: '12px 0',
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                    alignItems: 'start',
                                    flexDirection: 'column-reverse',
                                }}
                                control={
                                    <TextField
                                        id='Nome da organização'
                                        value={reasonSocial}
                                        disabled
                                        variant='outlined'
                                        sx={{
                                            marginTop: '8px',
                                            width: '400px',
                                        }}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='h5'>
                                        Nome
                                    </TypographyOptions>
                                }
                            />
                            <FormControlLabel
                                sx={{
                                    margin: '12px auto',
                                    display: 'flex',
                                    alignItems: 'start',
                                    flexDirection: 'column-reverse',
                                }}
                                control={
                                    <TextField
                                        value={CNPJ && handleFormatCNPJ(CNPJ)}
                                        disabled
                                        id='CNPJ'
                                        variant='outlined'
                                        sx={{
                                            marginTop: '8px',
                                            width: '400px',
                                        }}
                                    />
                                }
                                label={
                                    <TypographyOptions variant='h5'>
                                        CNPJ
                                    </TypographyOptions>
                                }
                            />
                        </Box>

                        {/* <TypographyTitle
                            sx={{ margin: '10px 0 20px 40px' }}
                            variant='h1'
                        >
                            Contrato social
                        </TypographyTitle> */}

                        <TypographyTitle
                            sx={{ margin: '0px 0px 2px 60px' }}
                            variant='h5'
                        >
                            Contrato social
                        </TypographyTitle>
                        <Box
                            sx={{
                                margin: '0 0 20px 60px',
                                width: '100%',
                                display: 'flex',
                            }}
                        >
                            <Button
                                sx={{
                                    width: '400px',
                                    height: '45px',
                                    margin: '5px 0',
                                    justifyContent: 'flex-start',
                                    background: '#FFFFFF',
                                }}
                                variant='outlined'
                                startIcon={
                                    <img
                                        src='/static/image/Documento.svg'
                                        alt='Documento'
                                        width={20}
                                        style={{ margin: '0 10px' }}
                                    />
                                }
                                onClick={handleDownloadContract}
                            >
                                {contractSocialFile}
                            </Button>
                        </Box>
                    </Box>

                    {user.group === 'comprador' && (
                        <>
                            <TypographyTitle
                                sx={{ margin: '15px 0 10px 40px' }}
                                variant='h1'
                            >
                                Poder de assinatura
                            </TypographyTitle>

                            <Box
                                sx={{
                                    margin: '0 0 20px 60px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <FormControlLabel
                                    sx={{
                                        margin: '12px 0',
                                        justifyContent: 'flex-start',
                                        display: 'flex',
                                        alignItems: 'start',
                                        flexDirection: 'column-reverse',
                                    }}
                                    control={
                                        <TextField
                                            id='Nome de quem assina'
                                            value={
                                                name || 'Nome de quem assina...'
                                            }
                                            disabled
                                            variant='outlined'
                                            sx={{
                                                marginTop: '8px',
                                                width: '400px',
                                            }}
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='h5'>
                                            Nome de quem assina
                                        </TypographyOptions>
                                    }
                                />
                                <FormControlLabel
                                    sx={{
                                        margin: '12px auto',
                                        display: 'flex',
                                        alignItems: 'start',
                                        flexDirection: 'column-reverse',
                                    }}
                                    control={
                                        <TextField
                                            value={
                                                email ||
                                                'E-mail de quem assina...'
                                            }
                                            disabled
                                            id='E-mail de quem assina'
                                            variant='outlined'
                                            sx={{
                                                marginTop: '8px',
                                                width: '400px',
                                            }}
                                        />
                                    }
                                    label={
                                        <TypographyOptions variant='h5'>
                                            E-mail de quem assina
                                        </TypographyOptions>
                                    }
                                />
                            </Box>

                            {user.nda?.documentName &&
                                user.nda?.documentName !== null && (
                                    <>
                                        <TypographyTitle
                                            sx={{ margin: '8px 0px 2px 60px' }}
                                            variant='h5'
                                        >
                                            Documento(s) que comprove(m) poder
                                            de assinatura
                                        </TypographyTitle>
                                        <Box
                                            sx={{
                                                margin: '0 0 20px 60px',
                                                width: '100%',
                                                display: 'flex',
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    width: '400px',
                                                    height: '45px',
                                                    margin: '5px 0 50px 0',
                                                    justifyContent:
                                                        'flex-start',
                                                    background: '#FFFFFF',
                                                }}
                                                variant='outlined'
                                                startIcon={
                                                    <img
                                                        src='/static/image/Documento.svg'
                                                        alt='Documento'
                                                        width={20}
                                                        style={{
                                                            margin: '0 10px',
                                                        }}
                                                    />
                                                }
                                                onClick={
                                                    handleDownloadConfidentiality
                                                }
                                            >
                                                {confidencialitylFile}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                        </>
                    )}
                </>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
