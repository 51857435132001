/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { Box, Pagination, PaginationItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { RowsContext } from 'context/RowsContext';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledDataGrid = styled(DataGrid)(() => ({
    border: 0,
    color: '#666666',
    background: '#F9F9F9',

    '&.MuiDataGrid-root': {
        border: 'none',
        maxWidth: '1555px',
        overflow: 'hidden',
    },
    '& .MuiDataGrid-main': {
        width: '100%',
    },

    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
        background: '#F9F9F9',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 16px',
        width: '100%',
    },
    '& .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnHeaderTitleContainer':
        {
            display: 'flex',
            justifyContent:
                window.location.pathname === '/buyer/administration' &&
                'center',
            width: '100%',
        },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        color: '#666666',
    },
    '& .MuiDataGrid-row': {
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        marginBottom: '8px',
        border: `2px solid ${'#EDEDED'}`,
        borderRadius: '8px',
        width: '98%',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders, .MuiDataGrid-cell':
        {
            border: 0,
        },
    '& .MuiDataGrid-cell': {
        color: '#666666',
    },
    '& .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
        {
            outline: 0,
        },

    '& .MuiDataGrid-footerContainer': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        border: 0,
    },
    '& .MuiPaginationItem-root': {
        borderRadius: '8px',
        background: '#FFFFFF',
        border: '1px solid #EDEDED',
        color: '#1E49E2',
        marginTop: '20px',
        padding: '16px 20px',
        width: '49px',
        height: '48px',
    },
}));

function CustomPagination() {
    const { totalRows, currentPage, setCurrentPage } = useContext(RowsContext);
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const location = useLocation();

    return (
        <Pagination
            color='primary'
            variant='outlined'
            shape='rounded'
            page={
                location.pathname.includes('/administration')
                    ? page + 1
                    : currentPage
            }
            count={
                location.pathname.includes('/administration')
                    ? pageCount
                    : totalRows
            }
            renderItem={(props2) => (
                <PaginationItem {...props2} disableRipple />
            )}
            onChange={(_, value) =>
                location.pathname.includes('/administration')
                    ? apiRef.current.setPage(value - 1)
                    : setCurrentPage(value)
            }
        />
    );
}

export default function DataGridTable({ columns, rowsPerPage, userType }) {
    const { rows, rowsUsers } = useContext(RowsContext);
    const location = useLocation();
    const navigate = useNavigate();

    // const setStatusAndNavigate = (r) => {
    //     // setStatusActive(r?.status);
    //     if (
    //         !location.pathname.includes('/administration') &&
    //         userType !== 'seller'
    //     ) {
    //         navigate(`/buyer/details/active/${r._id}/${r.idResume}`);
    //     } else {
    //         navigate(`/seller/active/${r._id}/${r.idResume}`);
    //     }
    // };
    // useEffect(() => {
    //     if (location.pathname.includes('/administration')) {
    //         setRows([]);
    //     } else {
    //         setRowsUsers([]);
    //     }
    // }, []);

    return (
        <Box
            sx={{
                height: !location.pathname.includes('/administration')
                    ? '1045px'
                    : '450px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                margin: '0 30px',
            }}
        >
            {((rows?.length > 0 &&
                !location.pathname.includes('/administration')) ||
                (rowsUsers?.length > 0 &&
                    location.pathname.includes('/administration'))) && (
                <StyledDataGrid
                    rows={
                        location.pathname.includes('/administration')
                            ? rowsUsers
                            : rows
                    }
                    getRowId={(row) => row._id}
                    columns={columns}
                    pageSize={rowsPerPage}
                    rowsPerPageOptions={[rowsPerPage]}
                    // onRowClick={({ row }) => setStatusAndNavigate(row)}
                    onRowClick={({ row }) =>
                        !location.pathname.includes('/administration') &&
                        (userType !== 'seller'
                            ? navigate(
                                  `/buyer/details/active/${row._id}/${row.idResume}`
                              )
                            : navigate(
                                  `/seller/active/${row._id}/${row.idResume}`
                              ))
                    }
                    /* initialState={{
                            sorting: {
                                sortModel: [{ field: 'publicacao', sort: 'asc' }],
                            },
                        }} */
                    disableSelectionOnClick
                    disableColumnMenu
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        Pagination: CustomPagination,
                    }}
                />
            )}

            {(location.pathname.includes('/buyer/opportunities') ||
                location.pathname.includes('/seller/active')) &&
                rows?.length === 0 && (
                    <Typography sx={{ marginTop: '46px' }}>
                        Não existem ativos cadastrados...
                    </Typography>
                )}

            {location.pathname.includes('/administration') &&
                rowsUsers?.length === 0 && (
                    <Typography sx={{ marginTop: '46px' }}>
                        Não existem outros usuários vinculados à esta
                        organização...
                    </Typography>
                )}
        </Box>
    );
}
