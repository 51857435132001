import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';
import minMaxValorFaceCurrencyMask from 'utils/minMaxValorFaceCurrencyMask';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    minHeight: '410px',
}));

const BoxFormControl = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '25px',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));
const TypographyOptions = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

export default function Step2({
    setResultAssessment,
    resultAssessment,
    isSmaller,
    setIsSmaller,
}) {
    const [minValue, setMinValue] = useState(
        resultAssessment.step2 ? resultAssessment?.step2[0] : ''
    );
    const [maxValue, setMaxValue] = useState(
        resultAssessment.step2 ? resultAssessment?.step2[1] : ''
    );
    let min;
    let max;

    useEffect(() => {
        if (typeof minValue === 'string' && typeof maxValue === 'string') {
            setIsSmaller(
                Number(minValue?.replace(',', '.').replaceAll('.', '')) >
                    Number(maxValue?.replace(',', '.').replaceAll('.', ''))
            );
            min = Number(minValue?.replace(',', '.').replaceAll('.', ''));
            max = Number(maxValue?.replace(',', '.').replaceAll('.', ''));
        } else if (
            typeof minValue === 'string' &&
            typeof maxValue !== 'string'
        ) {
            setIsSmaller(
                Number(minValue?.replace(',', '.').replaceAll('.', '')) >
                    maxValue
            );
            min = Number(minValue?.replace(',', '.').replaceAll('.', ''));
            max = maxValue;
        } else if (
            typeof minValue !== 'string' &&
            typeof maxValue === 'string'
        ) {
            setIsSmaller(
                minValue >
                    Number(maxValue?.replace(',', '.').replaceAll('.', ''))
            );
            min = minValue;
            max = Number(maxValue?.replace(',', '.').replaceAll('.', ''));
        } else {
            setIsSmaller(minValue > maxValue);
            min = minValue;
            max = maxValue;
        }

        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: [min, max],
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: resultAssessment?.step5,
        });
    }, [minValue, maxValue]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/DoisBranco.svg' alt='Dois' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        marginTop: '4vh',
                        marginBottom: '20px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Qual é o montante mínimo e máximo do ativo que você busca,
                    considerando o valor atualizado do ativo?
                    <spam
                        style={{
                            color: '#D10000',
                            fontSize: '16px',
                            margin: '0 5px',
                        }}
                    >
                        *
                    </spam>
                </Typography>
            </Box>
            <BoxFormControl>
                <FormControlLabel
                    sx={{
                        margin: '12px auto',
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column-reverse',
                    }}
                    control={
                        <TextField
                            id='minimo'
                            placeholder='Digite o valor mínimo...'
                            variant='outlined'
                            defaultValue={
                                minValue &&
                                `R$ ${minMaxValorFaceCurrencyMask(minValue)}`
                            }
                            sx={{
                                marginTop: '8px',
                                width: '235px',
                            }}
                            onChange={(e) =>
                                setMinValue(
                                    minMaxValorFaceCurrencyMask(e.target.value)
                                )
                            }
                            value={
                                minValue &&
                                `R$ ${minMaxValorFaceCurrencyMask(minValue)}`
                            }
                        />
                    }
                    label={
                        <TypographyOptions variant='h5'>
                            Mínimo
                        </TypographyOptions>
                    }
                />
                <FormControlLabel
                    sx={{
                        margin: '12px auto',
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column-reverse',
                    }}
                    control={
                        <TextField
                            id='maximo'
                            placeholder='Digite o valor máximo...'
                            variant='outlined'
                            defaultValue={maxValue}
                            error={isSmaller}
                            helperText={
                                isSmaller &&
                                'Máximo deve ser maior que o mínimo'
                            }
                            sx={{
                                marginTop: '8px',
                                width: '235px',
                            }}
                            onChange={(e) =>
                                setMaxValue(
                                    minMaxValorFaceCurrencyMask(e.target.value)
                                )
                            }
                            value={
                                maxValue &&
                                `R$ ${minMaxValorFaceCurrencyMask(maxValue)}`
                            }
                        />
                    }
                    label={
                        <TypographyOptions variant='h5'>
                            Máximo
                        </TypographyOptions>
                    }
                />
            </BoxFormControl>
        </FormBox>
    );
}
