/* eslint-disable import/extensions */
/* eslint-disable react/function-component-definition */
import { Route, Routes } from 'react-router-dom';

import Assessment from 'views/Buyer/Assessment';
import ConfidentialityAgreement from 'views/Buyer/ConfidentialityAgreement';
import Download from 'views/Buyer/Download';
import StepperLoginBuyer from 'views/Buyer/Login/index';
import Opportunities from 'views/Buyer/Opportunities';
import CompanyAdministration from 'views/CompanyAdministration';
import Details from 'views/Details';
import Home from 'views/Home';
import NotFound from 'views/NotFound';
import Profile from 'views/Profile';
import Upload from 'views/Upload';

import AboutToPlataform from 'views/About/aboutToPlataform';
import CommonQuestions from 'views/About/commonQuestions';
import MarketOfActiveAlternatives from 'views/About/marketplaceOfActiveAlternatives';

import Active from 'views/Seller/Active';
import StepperAssessementsSeller from 'views/Seller/Assessment';
import DetailsActiveSeller from 'views/Seller/DetailsActive';
import StepperLoginSeller from 'views/Seller/Login';
import RegisterWallet from 'views/Seller/RegisterWallet';
import SendEmail from 'views/Seller/SendEmail';
import AuthRoute from './authRoute';

const RoutesView = () => (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/upload' element={<Upload />} />

        <Route path='/about'>
            <Route index element={<AboutToPlataform />} />
            <Route path='questions' element={<CommonQuestions />} />
            <Route path='market' element={<MarketOfActiveAlternatives />} />
        </Route>

        <Route path='/buyer'>
            <Route element={<AuthRoute />}>
                <Route index element={<StepperLoginBuyer />} />
                <Route path='assessment' element={<Assessment />} />
                <Route path='opportunities' element={<Opportunities />} />
                <Route
                    path='details/active/:id/:idResume'
                    element={<Details />}
                />
                <Route path='profile' element={<Profile />} />

                <Route
                    path='administration'
                    element={<CompanyAdministration />}
                />

                <Route
                    path='download/active/:idActive/:idResume'
                    element={<Download />}
                />

                <Route
                    path='download/accessrequest'
                    element={<ConfidentialityAgreement />}
                />
            </Route>
        </Route>

        <Route path='/seller'>
            <Route element={<AuthRoute />}>
                <Route index element={<StepperLoginSeller />} />
                <Route path='active' element={<Active />} />
                <Route path='profile' element={<Profile />} />
                <Route
                    path='administration'
                    element={<CompanyAdministration />}
                />
                <Route
                    path='active/:idActive/:idResume'
                    element={<DetailsActiveSeller />}
                />
                <Route
                    path='assessment'
                    element={<StepperAssessementsSeller />}
                />
                <Route path='register/wallet' element={<RegisterWallet />} />

                <Route path='send/email' element={<SendEmail />} />
            </Route>
        </Route>

        <Route path='*' element={<NotFound />} />
    </Routes>
);

export default RoutesView;
