/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from '@mui/material';
import { UserContext } from 'context/UserContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';

const Container = styled(Box)({
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '18px'
});
const ModalSubtitle = styled(Typography)({
    marginBottom: '24px'
});



export default function ModalSuportSolicitation({ handleFileOrUnfileActive, isModalOpen, setIsModalOpen, idActive }) {
    const [solicitationText, setSolicitationText] = useState('')
    const { getUsers } = useContext(UserContext);

    function handleCloseModal() {
        setIsModalOpen(false);
        setSolicitationText('');
    }

    async function handleSubmitSolicitation() {
        try {
            await api.post('/api/notification/contactSupport', {
                idActive, message: solicitationText
            });
            handleFileOrUnfileActive();
            getUsers();
            handleCloseModal()
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
        }
    }


    return (
        <Dialog
            onClose={handleCloseModal}
            open={isModalOpen}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>
                <Typography variant='h1' sx={{
                    color: theme.palette.themePrimary
                        .primaryGrayDark,
                }}>
                    Solicitação de contato com o suporte
                </Typography></DialogTitle>
            <DialogContent
                sx={{
                    height: '450px',
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Container>
                    <Box
                        sx={{
                            textAlign: 'center',
                            margin: '20px 0px 30px 0px',
                        }}
                    >
                        <ModalSubtitle variant='body1'>
                            Sua solicitação de suporte será enviada ao time, e sua carteira será previamente arquivada.
                        </ModalSubtitle>
                    </Box>

                    <FormControlLabel
                        sx={{
                            margin: '12px auto',
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column-reverse',
                        }}
                        control={
                            <TextField
                                id='descrição'
                                placeholder='Digite aqui um complemento'
                                variant='outlined'
                                minRows={3}
                                multiline
                                sx={{
                                    marginTop: '6px',
                                    width: '480px',
                                }}
                                value={solicitationText}
                                onChange={(e) => setSolicitationText(e.target.value)}
                            />
                        } label={
                            <Typography variant='h5'>
                                Caso queira, digite um complemento para sua solicitação
                            </Typography>
                        }
                    />


                    <Box sx={{ display: 'flex', margin: '25px auto' }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleCloseModal}
                            sx={{ margin: '0 5px' }}
                        >
                            Cancelar
                        </Button>

                        <Button
                            variant='contained'
                            color='primary'
                            sx={{ margin: '0 5px' }}
                            onClick={handleSubmitSolicitation}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    )
}