import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Open Sans';
        src: url('static/font/OpenSans-Regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        /* font-family: 'Poppins Regular';
        src: url('static/font/poppins-v20-latin-regular.woff2') format('woff2'); */
    } 
    *, 
    *:after,
    *:before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif !important;
        /* font-family:'Poppins Regular', sans-serif !important; */
    }

    body {
        font-size: 100%;
        list-style-type: none;
        background-color: #F9F9F9 !important;
    }
`;

export default GlobalStyles;
