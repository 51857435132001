import {
    Box,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '0 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    minHeight: '400px',
}));
const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '0 auto',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));
export default function Step5({ setResultAssessment, resultAssessment }) {
    const [readyDocuments, setReadyDocuments] = useState(
        resultAssessment?.step5 !== null ? resultAssessment?.step5 : null
    );

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: readyDocuments,
            step6: resultAssessment?.step6,
            step7: resultAssessment?.step7,
        });
    }, [readyDocuments]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/CincoBranco.svg' alt='Cinco' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '30px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Os documentos suportes estão prontos para uma diligência?{' '}
                    <spam style={{ color: '#D10000', fontSize: '16px' }}>
                        *
                    </spam>
                </Typography>
            </Box>
            <FormControlLabelStyle
                control={
                    <RadioGroup
                        defaultValue={
                            resultAssessment?.step5 &&
                            (resultAssessment.step5 === true ? 'sim' : 'nao')
                        }
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                value='sim'
                                control={
                                    <Radio
                                        size='small'
                                        onChange={() => setReadyDocuments(true)}
                                    />
                                }
                                label={
                                    <Typography variant='body2'>Sim</Typography>
                                }
                            />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                value='nao'
                                control={
                                    <Radio
                                        size='small'
                                        onChange={() =>
                                            setReadyDocuments(false)
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='body2'>Não</Typography>
                                }
                            />
                        </Box>
                    </RadioGroup>
                }
            />
        </FormBox>
    );
}
