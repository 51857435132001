import {
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
}));
const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
}));

const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '2px 0',
    display: 'flex',
    alignItems: 'center',
    width: '25%',
}));
const FormGroupStyle = styled(FormGroup)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '95%',
    padding: 'auto 8px',
    margin: 'auto 10px',
}));

export default function Step7({ setResultAssessment, resultAssessment }) {
    const [checkedCreditType, setCheckedCreditType] = useState([]);
    const [creditTypeOfActive, setCreditTypeOfActive] = useState(
        resultAssessment?.step7 || null
    );

    const ativoPulverizado = [
        { name: 'agua', label: 'Conta de água' },
        { name: 'luz', label: 'Conta de luz' },
        { name: 'gas', label: 'Conta de gás' },
        { name: 'telefone', label: 'Conta de telefone' },
        { name: 'internet', label: 'Conta de internet' },
        { name: 'boletos', label: 'Boletos / Duplicatas' },
        { name: 'leasin', label: 'Leasing' },
        { name: 'autoloan', label: 'Autoloan' },
        { name: 'imobiliario', label: 'Imobiliário / Habitacional' },
        { name: 'consignadoPublico', label: 'Consignado público' },
        { name: 'consignadoPrivado', label: 'Consignado privado' },
        { name: 'cartaoCredito', label: 'Cartão de crédito' },
        { name: 'chequeEspecial', label: 'Cheque especial' },
        { name: 'creditoPessoal', label: 'Crédito pessoal' },
        { name: 'capitalGiro', label: 'Capital de giro' },
        { name: 'creditoEstFi', label: 'Crédito estudantil (financiamento)' },
        {
            name: 'creditoEstBol',
            label: 'Crédito estudantil (boletos e duplicatas)',
        },

        { name: 'cpr', label: 'Cédula do produtor rural / recebíveis rurais' },
        {
            name: 'outrosCreditosPulverizados',
            label: 'Outros créditos pulverizados',
        },
    ];

    const ativoAcoesJudiciais = [
        {
            name: 'acoesJudiciais',
            label: 'Ações judiciais e discussões em câmaras de arbitragem',
        },
        {
            name: 'creditosFalencia',
            label: 'Créditos em recuperação judicial, recuperação extra-Judicial ou falência',
        },
        { name: 'imoveis', label: 'Imóveis' },
        {
            name: 'outrosDiscussoesJudiciais',
            label: 'Outras discussões judiciais',
        },
        {
            name: 'creditosCorporativos',
            label: 'Créditos Corporativos',
        },
    ];

    const ativoPrecatorio = [
        {
            name: 'precatorios',
            label: 'Precatórios federais, estaduais e municipais',
        },
        { name: 'outrasAcoes', label: 'Outras ações com entidades públicas' },
        { name: 'creditosTributarios', label: 'Créditos tributários' },
        {
            name: 'outrosCreditosComEntidadesPublicas',
            label: 'Outros créditos com entidades públicas',
        },
    ];

    const handleSetCreditType = (isChecked, label, credit) => {
        if (isChecked) {
            setCheckedCreditType(label);
            const formatted = [credit, label];
            setCreditTypeOfActive(formatted);
        }
    };

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: resultAssessment?.step5,
            step6: resultAssessment?.step6,
            step7: creditTypeOfActive,
        });
    }, [creditTypeOfActive]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/SeteBranco.svg' alt='Sete' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '30px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Qual o tipo de crédito que compõe esse ativo?{' '}
                    <spam style={{ color: '#D10000', fontSize: '16px' }}>
                        *
                    </spam>
                </Typography>
                <Typography
                    variant='subtitle1'
                    sx={{
                        textAlign: 'center',
                        margin: '0px 80px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Caso seu ativo seja composto por mais de um tipo de crédito,
                    recomendamos segregar as informações conforme classificações
                    abaixo. A seleção do tipo de crédito abaixo dará acesso a um{' '}
                    <i>template</i> exclusivo para o tipo de ativo, considerando
                    informações relevantes do tipo de ativo selecionado. Em caso
                    de dúvidas, entre em contato conosco.
                </Typography>
            </Box>

            <FormGroupStyle>
                <RadioGroup>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Créditos Pulverizados
                    </Typography>
                    {/* <Grid
                        container
                        spacing={2}
                        // sx={{ display: 'flex', margin: '0 20px 20px 20px' }}
                    > */}
                    {/* <Grid container item sx={{ padding: '20px' }}> */}
                    <Grid
                        container
                        spacing={1}
                        sx={{ display: 'flex', margin: '0 15px 20px 0' }}
                    >
                        <Grid container item>
                            {ativoPulverizado.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Radio
                                            size='small'
                                            key={item.name}
                                            checked={checkedCreditType.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleSetCreditType(
                                                    event.target.checked,
                                                    item.label,
                                                    'Créditos Pulverizados'
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontStyle:
                                                    (item.label === 'Leasing' ||
                                                        item.label ===
                                                            'Autoloan') &&
                                                    'italic',
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {/* </Grid> */}

                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Créditos Corporativos e Discussões Judiciais
                    </Typography>

                    <Grid
                        container
                        spacing={1}
                        sx={{ display: 'flex', margin: '0 15px 20px 0' }}
                    >
                        <Grid container item>
                            {ativoAcoesJudiciais.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Radio
                                            size='small'
                                            key={item.name}
                                            checked={checkedCreditType.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleSetCreditType(
                                                    event.target.checked,
                                                    item.label,
                                                    'Créditos Corporativos e Discussões Judiciais'
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant='body2'>
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>

                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Créditos com Entidades Públicas
                    </Typography>

                    <Grid
                        container
                        spacing={1}
                        sx={{ display: 'flex', margin: '0 15px 30px 0' }}
                    >
                        <Grid container item>
                            {ativoPrecatorio.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Radio
                                            size='small'
                                            key={item.name}
                                            checked={checkedCreditType.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleSetCreditType(
                                                    event.target.checked,
                                                    item.label,
                                                    'Créditos com Entidades Públicas'
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant='body2'>
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormGroupStyle>
        </FormBox>
    );
}
