/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)({
    width: '100%',
    margin: '0 auto 20px auto',
    height: '100vh',
    minHeight: '100vh',
    maxWidth: '1800px',
    flexDirection: 'column',
});

const ContentBox = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
});
const ButtonBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    height: '24px',
    margin: '32px 0 64px',
});

const TypographySubtitle = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGray,
    textAlign: 'center',
    marginBottom: '64px',
}));

const CardBox = styled(Box)({
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
});

const CardsContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '0 180px',
});

const BoxImg = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    height: '180px',
});

const TypopraphyCardTitle = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGray,
    textAlign: 'start',
    marginTop: '64px',
}));

export default function EmptyState({ handleCreateNewActive }) {
    // const [rows, setRows] = useState([]);
    // const [oldRows, setOldRows] = useState([]);

    return (
        <Container>
            <ContentBox>
                <TypographySubtitle>
                    <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>
                        Você ainda não tem nenhum ativo cadastrado.
                    </Typography>
                    <Typography>Para subir um ativo, você precisa:</Typography>
                </TypographySubtitle>

                <CardsContainer>
                    <CardBox>
                        <BoxImg>
                            <img
                                src='/static/image/RealizarDetalhamento.svg'
                                alt='Detalhamento'
                            />
                        </BoxImg>
                        <TypopraphyCardTitle variant='h2'>
                            1. Detalhamento das características do seu ativo
                        </TypopraphyCardTitle>
                        <Box>
                            Nesta etapa, precisaremos de algumas informações com
                            relação ao seu ativo. Esses dados serão utilizados
                            para complementar o anúncio e buscar compradores que
                            têm interesse em ativos iguais ou similares ao seu.
                        </Box>
                    </CardBox>
                    <CardBox>
                        <BoxImg>
                            <img
                                src='/static/image/RealizarDownload.svg'
                                alt='Download'
                            />
                        </BoxImg>
                        <TypopraphyCardTitle variant='h2'>
                            2. <i>Download</i> do template do ativo em Excel
                        </TypopraphyCardTitle>
                        <Box>
                            Após informar os detalhes do seu ativo, você terá
                            acesso a um <i>template</i>, no formato Excel,
                            específico para o tipo do seu ativo. Esse arquivo
                            deverá ser preenchido com todas as informações
                            detalhadas relativas ao seu ativo. Com base nessas
                            informações, faremos o anúncio na plataforma e
                            elaboraremos um material detalhado, porém anônimo,
                            sobre o seu ativo.
                        </Box>
                    </CardBox>
                    <CardBox>
                        <BoxImg>
                            <img
                                src='/static/image/RealizarUpload.svg'
                                alt='Upload'
                            />
                        </BoxImg>
                        <TypopraphyCardTitle variant='h2'>
                            3. <i>Upload</i> do <i>template</i> preenchido no
                            KPMG Data Sharing Tool
                        </TypopraphyCardTitle>
                        <Box>
                            Após o <i>download</i> e o preenchimento do
                            <i>template</i>, você deverá enviá-lo de volta para
                            nós por meio do link que receberá por <i>e-mail</i>.
                            Analisaremos as informações, prepararemos o material
                            específico, organizaremos o anúncio na plataforma e
                            comunicaremos aos compradores e investidores.
                        </Box>
                    </CardBox>
                </CardsContainer>

                <ButtonBox>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleCreateNewActive}
                    >
                        Vamos começar?
                    </Button>
                </ButtonBox>
            </ContentBox>

            {/* <BoxContainer>
                <Filter rows={rows} setRows={setRows} oldRows={oldRows} />
                <DataTable
                    rows={rows}
                    setRows={setRows}
                    setOldRows={setOldRows}
                />
            </BoxContainer> */}
        </Container>
    );
}
