/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import * as jose from 'jose';
import md5 from 'md5';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';

export default function useUser() {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [isUserLinkedToCompany, setIsUserLinkedToCompany] = useState(false);
    const [nda, setNda] = useState('Recusado');

    const location = useLocation();
    const arrayPathname = [
        '/',
        // '/buyer',
        // '/seller',
        '/upload',
        '/about',
        '/about/market',
        '/about/questions',
    ];

    const getUsers = async () => {
        setLoading(false);
        try {
            const { data } = await api.get('/profile');
            if (data.user?.company) {
                const secret = new TextEncoder().encode(
                    md5(`${data.user.email}NPL@`)
                );
                const { payload, protectedHeader } = await jose
                    .jwtVerify(data.user.company, secret)
                    .catch((e) => {
                        window.location.href = '/signout';
                    });
                data.user.company = payload.company;
            }

            if (data?.token) {
                localStorage.setItem('token', data.token);
            }

            setUser(data.user);
            setLoading(true);
        } catch (error) {
            setLoading(true);

            if (
                error.message === 'signature verification failed' ||
                error.message === 'Compact JWS must be a string or Uint8Array'
            ) {
                window.location.href = '/signout';
            }

            // toast.error(error.response.data.error);
        }
    };

    useEffect(() => {
        if (
            !arrayPathname.some((element) => element === location.pathname) &&
            user?.email === undefined
        ) {
            getUsers();
        }
    }, [location.pathname, user]);

    return {
        user,
        setUser,
        loading,
        isUserLinkedToCompany,
        setIsUserLinkedToCompany,
        nda,
        setNda,
        getUsers,
    };
}
