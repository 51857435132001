import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';
import minMaxValorFaceCurrencyMask from 'utils/minMaxValorFaceCurrencyMask';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '410px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));
const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '12px auto',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));
export default function Step4({ setResultAssessment, resultAssessment }) {
    const [text, setText] = useState(resultAssessment.step4 || '');

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: text,
            step5: resultAssessment?.step5,
            step6: resultAssessment?.step6,
            step7: resultAssessment?.step7,
        });
    }, [text]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/QuatroBranco.svg' alt='Quatro' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '20px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Qual o valor atualizado do ativo, ainda que estimado?
                    <spam style={{ color: '#D10000', fontSize: '16px', marginLeft:'5px' }}>
                        *
                    </spam>
                </Typography>
            </Box>
            <Box sx={{ marginBottom: '25px' }}>
                <FormControlLabelStyle
                    control={
                        <TextField
                            id='valor'
                            placeholder='Digite o valor atualizado...'
                            variant='outlined'
                            inputProps={{ maxLength: 1000 }}
                            defaultValue={text}
                            onChange={(e) => {
                                setText(
                                    minMaxValorFaceCurrencyMask(e.target.value)
                                );
                            }}
                            value={
                                text &&
                                `R$ ${minMaxValorFaceCurrencyMask(text)}`
                            }
                        />
                    }
                />
            </Box>
        </FormBox>
    );
}
