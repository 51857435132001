import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '0 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    minHeight: '400px',
}));
const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '0 auto',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));
export default function Step5({ setResultAssessment, resultAssessment }) {
    const [text, setText] = useState(resultAssessment.step5 || '');

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: text,
        });
    }, [text]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/CincoBranco.svg' alt='Cinco' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '30px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Explique, brevemente, sobre a sua empresa e acerca da sua
                    estratégia de compra de Ativos Alternativos.
                </Typography>
            </Box>
            <FormControlLabelStyle
                control={
                    <TextField
                        placeholder='Escreva aqui'
                        variant='outlined'
                        minRows={5}
                        maxRows={5}
                        inputProps={{ maxLength: 1000 }}
                        multiline
                        sx={{
                            marginTop: '8vh',
                            width: '70vw',
                            height: '15vh',
                        }}
                        defaultValue={text}
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                    />
                }
            />
        </FormBox>
    );
}
