const columns = [
    { field: 'name', headerName: '', width: '260px' },
    {
        field: 'vendaDireta',
        headerName: 'Venda direta',
        width: '480px',
        color: '#8CD0FA',
    },
    {
        field: 'vendaUpsideSharing',
        headerName: 'Venda Direta com Upside Sharing',
        width: '520px',
        color: '#65D8AE',
    },
    {
        field: 'aquisicao',
        headerName: 'Aquisição em veículo conjunto',
        width: '300px',
        color: '#F2A7D7',
    },
    {
        field: 'gestao',
        headerName: 'Gestão compartilhada de cobrança',
        width: '300px',
        color: '#AF99F8',
    },
];

const rows = [
    {
        id: 1,
        name: 'Geração de caixa antecipada',
        vendaDireta: 'Sim',
        vendaUpsideSharing: 'Parcialmente',
        aquisicao: 'Parcialmente',
        gestao: 'Não',
    },
    {
        id: 2,
        name: 'Transferência de risco',
        vendaDireta: 'Sim',
        vendaUpsideSharing: 'Parcialmente',
        aquisicao: 'Parcialmente',
        gestao: 'Não',
    },
    {
        id: 3,
        name: 'Envolvimento contínuo',
        vendaDireta: 'Não',
        vendaUpsideSharing: 'Sim',
        aquisicao: 'Sim',
        gestao: 'Sim',
    },
    {
        id: 4,
        name: 'Complexidade de execução',
        vendaDireta: 'Menor',
        vendaUpsideSharing: 'Maior',
        aquisicao: 'Maior',
        gestao: 'Maior',
    },
    {
        id: 5,
        name: 'Observação',
        vendaDireta:
            'Geralmente realizada por meio de leilão competitivo ou de negociações bilaterais com um contrato de cessão. Vendedor não retém qualquer tipo de direito ou obrigação após a venda.',
        vendaUpsideSharing:
            'Geralmente realizada por meio de leilão competitivo ou de negociações bilaterias com um contrato de cessão. Opção de manter algum tipo de participação no ativo, seja via formatação de contrato de cessão com gatilhos de performance ou via criação de um veículo específico para tal finalidade.',
        aquisicao:
            'Modalidades em que o vendedor geralmente opta por trazer um parceiro (por exemplo, agência de cobrança ou master servicer) para melhorar seus indicadores de performance com relação à recuperação do crédito. O contrato de cessão tende a se tornar mais complexo, bem como o acompanhamento dos resultados e governança na estratégia de recuperação do crédito.',
    },
];

export default function MarketPlaceTable() {
    return (
        <table
            style={{
                borderCollapse: 'collapse',
                width: '98%',
                margin: '10px 20px',
            }}
        >
            <thead>
                <tr>
                    {columns.map((column, columnIndex) => (
                        <th
                            style={{
                                backgroundColor: column.color,
                                color: '#FFF',
                                fontSize: '16px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                                padding: '8px',
                                width: column.width,
                                borderTop: 'none',
                                borderLeft:
                                    columnIndex === 0
                                        ? 'none'
                                        : '1px solid #dddddd',
                            }}
                            key={column.field}
                        >
                            {column.headerName}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={row.id}>
                        {columns.map((column, columnIndex) => (
                            <td
                                style={{
                                    color:
                                        columnIndex === 0
                                            ? '#6481EB'
                                            : '#666666',
                                    fontWeight:
                                        (columnIndex === 0 ||
                                            rowIndex !== rows.length - 1) &&
                                        'bold',
                                    border:
                                        rowIndex === rows.length - 1 &&
                                        columnIndex >= columns.length - 1
                                            ? 'none'
                                            : '1px solid #dddddd',
                                    textAlign:
                                        columnIndex === 0 ? 'left' : 'center',
                                    padding: '8px',
                                    width: column.width,
                                    fontSize:
                                        rowIndex === rows.length - 1 &&
                                        columnIndex > 0
                                            ? '12px'
                                            : '14px',
                                }}
                                key={column.field}
                                colSpan={
                                    rowIndex === rows.length - 1 &&
                                    columnIndex >= columns.length - 2
                                        ? 2
                                        : 1
                                }
                            >
                                {row[column.field]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
