/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
import axios from 'axios';

const api = axios.create({
    baseURL: '',
    withCredentials: true,
    headers: {
        Authorization: localStorage.getItem('token'),
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) config.headers.Authorization = localStorage.getItem('token');
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (
            error.response.data.message === 'Sessão expirada' ||
            error.message === 'Sessão expirada'
        ) {
            localStorage.clear();
            window.location.href = '/signout';
        }
        if (
            error.response.data.message === 'Action blocked by the system' ||
            error.message === 'Action blocked by the system'
        ) {
            window.history.go(-1);
        }
        if (
            (['/download/active'].includes(window.location.pathname) &&
                JSON.parse(await error?.response?.data?.text())?.message ===
                    'Sem permissão para download!') ||
            error.response.data.message === 'Sem permissão para download!' ||
            error.message === 'Sem permissão para download!'
        ) {
            window.history.go(-1);
        }
        return Promise.reject(error);
    }
);

export default api;
