import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import BarGraphics from 'components/BarGraphics';
import DetailsActiveTable from 'components/DetailsActiveTable';
import PieGraphics from 'components/PieGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';


const DistributionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});
const Title = styled(Typography)({
    fontSize: '18px',
    color: theme.palette.primary.main,
});

const GrapichPieBox = styled(Box)({
    width: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});


export default function Template5({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsImmobile, setValuesGraphicsImmobile] = useState({});
    const [valuesGraphicsSale, setValuesGraphicsSale] = useState({});
    const [valuesGraphicsLVT, setValuesGraphicsLVT] = useState({});
    const [valuesGraphicsPayment, setValuesGraphicsPayment] = useState({});

    const textTooltip = walletDetails?.template.number === 4 ?
        'LTV é o saldo em aberto dividido pelo valor do veículo atualizado.' :
        'LTV é o saldo em aberto dividido pelo valor do imóvel atualizado.'

    useEffect(() => {
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Qtd. Documentos por Índice de pagamento'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Qtd. Documentos por Índice de pagamento'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsPayment(valuesGraphics);

        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = { ...valuesGraphics, [key]: valueKey };
        });

        setValuesGraphicsSale(valuesGraphics);

        Object.values(walletDetails?.template?.number === 4 ?
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo'
            ] : walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Imóvel'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(walletDetails?.template?.number === 4 ?
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo'
                ] : walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Imóvel'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsImmobile(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade Documentos por LTV (Loan To Value)'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade Documentos por LTV (Loan To Value)'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsLVT(valuesGraphics);
    }, [walletDetails]);

    return (
        <>
            <DistributionBox>
                <Title>Distribuição de Saldo em Aberto</Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '240px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <GrapichPieBox>
                        <PieGraphics
                            labels={valuesGraphicsSale?.['Composição de Saldo']}
                            data={valuesGraphicsSale?.Valores}
                            content='outstandingBalance'
                        />
                    </GrapichPieBox>
                    <DetailsActiveTable
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto'
                            ]
                        )}
                        sx={{ height: '200px' }}
                    />
                </Box>
            </DistributionBox>
            <DistributionBox>
                <Title>
                    Distribuição de Saldo em Aberto e Qtd. Documentos por índice
                    de pagamento
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsPayment?.['Índice de Pagamento']}
                        data={valuesGraphicsPayment?.['Saldo em Aberto']}
                    />
                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Qtd. Documentos por Índice de pagamento'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Qtd. Documentos por Índice de pagamento'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionBox>

            {walletDetails.template.number !== 4 && (
                <DistributionBox>
                    <Title>
                        Distribuição de Saldo em Aberto e Quantidade de
                        Documentos por Tipo de Imóvel
                    </Title>
                    <Box
                        sx={{
                            width: '100%',
                            height: '280px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                        }}
                    >
                        {
                            walletDetails?.calculationsWallet?.[0]?.[walletDetails?.template?.number === 4 ?
                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo' :
                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Imóvel'
                            ][walletDetails?.template?.number === 4 ? 'Tipo de Veículos' : 'Tipo de Imóvel'][0] === 'Total' ? (<Typography sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>Infelizmente, não dispomos de dados suficientes para gerar este gráfico</Typography>) : (
                                <>
                                    <GrapichPieBox>
                                        <PieGraphics
                                            labels={walletDetails?.template?.number === 4 ?
                                                valuesGraphicsImmobile?.['Tipo de Veículos'] :
                                                valuesGraphicsImmobile?.['Tipo de Imóvel']
                                            }
                                            data={
                                                valuesGraphicsImmobile?.['Saldo em Aberto']
                                            }
                                            content='propertyType'
                                        />
                                    </GrapichPieBox>
                                    <DetailsActiveTable
                                        rows={formatRows(
                                            walletDetails?.calculationsWallet?.[0]?.[walletDetails?.template?.number === 4 ?
                                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo' :
                                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Imóvel'
                                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                                        )}
                                        columns={formatColumns(
                                            walletDetails?.calculationsWallet?.[0]?.[
                                            walletDetails?.template?.number === 4 ?
                                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo' :
                                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Imóvel'
                                            ]
                                        )}
                                    />
                                </>
                            )}


                    </Box>
                </DistributionBox>
            )}

            <DistributionBox>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',

                    }}
                >
                    <Title>
                        Distribuição de Saldo em Aberto e Quantidade Documentos por LTV (Loan To Value)
                    </Title>
                    <Tooltip title={textTooltip} placement='left' sx={{ width: '100%' }}>
                        <IconButton
                            size='small'
                            sx={{
                                height: '100%',
                                alignSelf: 'end',
                                marginLeft: '4px',
                                color: theme.palette.primary.main,
                            }}
                        >
                            <InfoOutlinedIcon fontSize='10px' />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '480px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsLVT?.LTV}
                        data={valuesGraphicsLVT?.['Saldo em Aberto']}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '56px',
                        }}
                    >
                        <DetailsActiveTable
                            rows={formatRows(
                                walletDetails?.calculationsWallet?.[0]?.[
                                'Distribuição de Saldo em Aberto e Quantidade Documentos por LTV (Loan To Value)'
                                ], walletDetails?.calculationsWallet?.[0]?.Moeda
                            )}
                            columns={formatColumns(
                                walletDetails?.calculationsWallet?.[0]?.[
                                'Distribuição de Saldo em Aberto e Quantidade Documentos por LTV (Loan To Value)'
                                ]
                            )}
                        />
                        <Typography sx={{ marginTop: '8px' }}>
                            *Calculo de índice: Valor total de dívida sobre
                            valor total de garantia
                        </Typography>
                    </Box>
                </Box>
            </DistributionBox>
        </>
    );
}
