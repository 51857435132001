import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ActiveType from 'components/ActiveType';
import ActiveTypeCards from 'components/ActiveTypeCards';
import MarketPlaceTable from 'components/MarketPlaceTable';
import RelevantPoints from 'components/RelevantPoints';
import { useNavigate } from 'react-router-dom';
import { theme } from 'themes';

const Header = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    margin: '10px 0',
});

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    align: 'left',
    marginTop: '24px',
    padding: '10px 20px',
    color: props.theme.palette.primary.main,
}));

const BoxCard = styled(Box)({
    display: 'flex',
    padding: '2px',
    width: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 14px',
    height: '50px',
    border: '1px #D4D4D4 solid',
    borderRadius: '8px',
    // boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2);',
});

const BoxPrice = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '380px',
    height: '90px',
    borderRadius: '8px',
});

export default function MarketplaceOfActiveAlternatives() {
    const navigate = useNavigate();

    const ativoPulverizado = [
        { name: 'utilidades', label: 'Conta de utilidades' },
        { name: 'boletos', label: 'Boletos e duplicatas' },
        { name: 'leasing', label: 'Leasing' },
        { name: 'autoloan', label: 'Autoloan' },
        { name: 'imobiliario', label: 'Crédito imobiliário' },
        { name: 'consignado', label: 'Crédito consignado' },
        { name: 'cartaoCredito', label: 'Cartão de crédito' },
        { name: 'chequeEspecial', label: 'Cheque especial' },
        { name: 'capitalGiro', label: 'Capital de giro' },
        { name: 'creditoEstudantil', label: 'Crédito estudantil' },
        { name: 'creditoPessoal', label: 'Crédito pessoal' },
        { name: 'recebiveisRurais', label: 'Recebíveis rurais' },
    ];

    const ativoAcoesJudiciais = [
        {
            name: 'acoesJudiciais',
            label: 'Ações judiciais',
        },
        {
            name: 'discussoes',
            label: 'Discussões em câmaras de arbitragem',
        },
        {
            name: 'creditosFalencia',
            label: 'Créditos de empresas em recuperação judicial ou falência',
        },
        {
            name: 'creditosCorporativos',
            label: 'Créditos Corporativos',
        },
    ];

    const ativoPrecatorio = [
        {
            name: 'precatorios',
            label: 'Precatórios Federais, estaduais e municipais',
        },
        { name: 'creditosTributarios', label: 'Créditos tributários' },
        {
            name: 'creditosEntidadesPublicas',
            label: 'Créditos com outras entidades públicas',
        },
    ];

    const pontosRelevantes = [
        { number: '01', title: 'Processo de venda ordenado e estruturado' },
        { number: '02', title: 'Diferenças nas expectativas de preço entre o vendedor e comprador' },
        { number: '03', title: 'Falta de informação geralmente frustra o comprador e diminui o valor da transação' },
        { number: '04', title: 'Atratividade do Ativo e perfil dos compradores' },
        { number: '05', title: 'Verificar ambiente e contexto antes de ir a mercado' },
        { number: '06', title: 'Quantificação dos possíveis efeitos contábeis/fiscais e identificação de obstáculos regulatórios' },
        { number: '07', title: 'O potencial comprador não dispõe dos recursos necessários para aquisição da carteira e/ou não tem competência para executar a cobrança' },
        { number: '08', title: 'Falta de clareza quanto à estrutura adequada à transação e suporte operacional pós fechamento' }
    ];

    return (
        <>
            <Header>
                <Typography
                    variant='h1'
                    align='left'
                    marginBottom='8px'
                    sx={{ color: theme.palette.themePrimary.primaryGray }}
                >
                    O Mercado de Ativos Alternativos
                </Typography>
                <Button
                    type='button'
                    onClick={() => navigate('/')}
                    sx={{
                        padding: '10px',
                    }}
                >
                    Voltar
                </Button>
            </Header>

            {/* Breve contextualização do mercado de Ativos Alternativos */}
            <Box>
                <TypographyTitle variant='h2'>
                    Breve contextualização do mercado de Ativos Alternativos
                </TypographyTitle>
                <Typography
                    variant='body2'
                    sx={{
                        margin: '10px 20px',
                        textAlign: 'left',
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    O mercado de Ativos Alternativos vem crescendo e
                    amadurecendo a cada ano, com cada vez mais compradores
                    interessados nesse tipo de ativo.
                    <br />
                    <br />
                    Nos últimos anos, tem ocorrido um aumento dessas operações,
                    com mais participantes no mercado (tanto compradores quanto
                    vendedores). Há uma expectativa de incremento no volume de
                    operações envolvendo ativos alternativos nos próximos anos,
                    em razão da crise financeira que uma parte das empresas e
                    das famílias no Brasil tem passado (gerando mais créditos
                    inadimplidos) e da tendência de queda da taxa básica de
                    juros (o que leva os investidores a buscar ativos com maior
                    potencial de retorno). <br />
                    <br />
                    Por um lado, essa categoria de ativos é uma boa forma de
                    diversificação para investidores, pois não são impactados
                    pela macroeconomia na mesma proporção que os investimentos
                    tradicionais. Geralmente, apresentam rendimentos mesmo em
                    momentos de crise. <br />
                    <br />
                    Por outro, para os vendedores, negociar estes ativos é uma
                    maneira de gerar caixa e obter liquidez imediata, benefícios
                    fiscais, melhoria nos índices financeiros e impacto positivo
                    nos resultados, além da transferência de risco e da redução
                    de custos relacionados à cobrança.
                </Typography>
            </Box>

            {/* Os diferentes tipos de Ativos Alternativos */}
            <Box>
                <TypographyTitle variant='h2'>
                    Os diferentes tipos de Ativos Alternativos
                </TypographyTitle>
                <Typography
                    variant='body2'
                    sx={{
                        margin: '10px 20px',
                        textAlign: 'left',
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    Apesar de existirem diversas terminologias relacionadas a
                    essa classe de ativos, pode-se dividir em três grupos e seus
                    respectivos tipos de crédito. Por vezes, podemos ouvir
                    outras terminologias, tais como: ativos estressados;
                    créditos inadimplidos; créditos podres; e outros.
                    Basicamente, a principal característica desse tipo de ativo
                    é terem valores em aberto e/ou inadimplidos ou estarem
                    atrelados a discussões entre as partes. São eles:
                </Typography>

                <BoxContainer>
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <ActiveType
                            title='Créditos Pulverizados'
                            text='São geralmente carteiras de créditos não performados de pessoa fisica, compostas por muitos devedores e grande quantidade de créditos com ticket médio menor em comparação a outros ativos.'
                            backgroundColor='#1E49E2'
                            color='#1E49E2'
                        />
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                margin: '10px',
                                width: '100%',
                            }}
                        >
                            <Grid
                                container
                                item
                                spacing={1}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                {ativoPulverizado.map((item) => (
                                    <ActiveTypeCards
                                        text={item.label}
                                        color='#1E49E2'
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <ActiveType
                            title='Créditos Corporativos e Discussões Judiciais'
                            text='São geralmente créditos ou carteiras de créditos com valores devidos  por  um  devedor ou um grupo menor de devedores (pessoas jurídicas), quando comparado a outros ativos inadimplidos.'
                            backgroundColor='#6727E1'
                            color='#6727E1'
                        />
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                width: '100%',
                                margin: '10px',
                            }}
                        >
                            <Grid
                                container
                                item
                                spacing={1}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                {ativoAcoesJudiciais.map((item) => (
                                    <ActiveTypeCards
                                        text={item.label}
                                        color='#6727E1'
                                    />
                                ))}
                            </Grid>
                        </Grid>
                        <ActiveType
                            title='Créditos com Entidades Públicas'
                            text='São créditos a receber de entes públicos, com prazo de pagamento geralmente indefinido ou incerto.'
                            backgroundColor='#128F79'
                            color='#128F79'
                        />
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                width: '100%',
                                margin: '10px',
                            }}
                        >
                            <Grid
                                container
                                item
                                spacing={1}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                {ativoPrecatorio.map((item) => (
                                    <ActiveTypeCards
                                        text={item.label}
                                        color='#128F79'
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </BoxContainer>
            </Box>

            {/* Como acontecem as transações de compra e venda */}
            <Box>
                <TypographyTitle variant='h2'>
                    Como acontecem as transações de compra e venda
                </TypographyTitle>
                <Typography
                    variant='body2'
                    sx={{
                        margin: '10px 20px',
                        textAlign: 'left',
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    Há diversas formas de comprar e vender Ativos Alternativos.
                    O modo de negociação vai depender do tipo de ativo/crédito,
                    das expectativas de retorno do comprador e do vendedor, do
                    perfil do comprador e de outros fatores. Confira algumas das
                    maneiras possíveis:
                </Typography>
                <MarketPlaceTable />
            </Box>

            {/* Como precificar */}
            <Box>
                <TypographyTitle variant='h2'>Como precificar</TypographyTitle>
                <Typography
                    variant='body2'
                    sx={{
                        margin: '10px 20px',
                        textAlign: 'left',
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    Existem diversas metodologias para precificação de Ativos
                    Alternativos, tipicamente divididas entre métodos
                    quantitativos e qualitativos. O maior desafio consiste em
                    estimar a curva de recuperação ou a data de
                    desfecho/pagamento do crédito. Quanto maior for a incerteza,
                    menor será a precificação. Além disso, são levados em
                    consideração os custos e as despesas envolvidas. Com essas
                    informações, utiliza-se então a metodologia de Fluxo de
                    Caixa Descontado para encontrar o valor presente do ativo
                    alinhado às taxas de desconto exigidas pelos compradores
                    (que tendem a ser maiores do que o retorno de ativos
                    não-alternativos).
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '80px',
                    }}
                >
                    <Box
                        sx={{
                            left: 0,
                            width: '0',
                            height: '0',
                            borderTop: '36px solid transparent',
                            borderBottom: '36px solid transparent',
                            borderRight: '36px solid #6481EB', // Cor da seta
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80%',
                            height: '40px',
                            background:
                                'linear-gradient(90deg, #6481EB  0%, #C590E5 100%)', // Cor do retângulo
                        }}
                    >
                        <Typography variant='h3' color='#FFFFFF'>
                            Qualitativo
                        </Typography>
                        <Typography variant='h3' color='#FFFFFF'>
                            Quantitativo
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            right: 0,
                            width: '0',
                            height: '0',
                            borderTop: '36px solid transparent',
                            borderBottom: '36px solid transparent',
                            borderLeft: '36px solid #C590E5', // Cor da seta
                        }}
                    />
                </Box>

                <Box sx={{ margin: '0 80px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <BoxCard>
                            <Typography color='#1E49E2' variant='h5'>
                                Créditos Corporativos e Discussões Judiciais
                            </Typography>
                        </BoxCard>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <BoxCard>
                            <Typography color='#6481EB' variant='h5'>
                                Créditos com Entidades Públicas
                            </Typography>
                        </BoxCard>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <BoxCard>
                            <Typography color='#600DB4' variant='h5'>
                                Créditos Pulverizados
                            </Typography>
                        </BoxCard>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <BoxPrice sx={{ background: '#6481EB' }}>
                        <ul style={{ color: '#FFF', fontSize: '14px' }}>
                            <li>Valores dos créditos: maiores</li>
                            <li>
                                Maior complexidade e produtos não padronizados
                            </li>
                            <li>
                                Análise individualizada e baseada em julgamento
                            </li>
                        </ul>
                    </BoxPrice>
                    <BoxPrice sx={{ background: '#C590E5' }}>
                        <ul style={{ color: '#FFF', fontSize: '14px' }}>
                            <li>Valores dos créditos: menores</li>
                            <li>Menor complexidade e produtos homogêneos</li>
                            <li>Análise baseada em modelos estatísticos</li>
                        </ul>
                    </BoxPrice>
                </Box>
            </Box>

            {/* Pontos relevantes no processo de venda */}
            <Box>
                <TypographyTitle variant='h2'>
                    Pontos relevantes no processo de venda
                </TypographyTitle>
                {pontosRelevantes.map((item) => (
                    <RelevantPoints number={item.number} title={item.title} />
                ))}
            </Box>
        </>
    );
}
