import { Box, Dialog } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const spin = keyframes`
            0% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 0;
            }
            4.9% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 0;
            }
            5% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 1;
            }
            100% {
                top: 0px;
                left: 0px;
                width: 72px;
                height: 72px;
                opacity: 0;
            
        }
`;

// eslint-disable-next-line no-unused-vars
const LoadingStyle = styled(Box)(() => ({
    display: 'inline-block',
    position: 'fixed',
    width: '80px',
    height: '80px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 11,
    '& div': {
        position: 'absolute',
        border: '4px solid #0b1ccd',
        opacity: 1,
        borderRadius: '50%',
        animation: `${spin} 2s cubic-bezier(0, 0.2, 0.8, 1) infinite`,
    },
    overflow: 'hidden',
}));

export default function LoadingScreen() {
    return (
        <Dialog
            sx={{
                '& .MuiBackdrop-root': { display: 'none' },
                backdropFilter: 'blur(2px)',
            }}
            open
        >
            <LoadingStyle>
                <div />
                <div style={{ animationDelay: '-0.5s' }} />
            </LoadingStyle>
        </Dialog>
    );
}
