import { Box, Typography } from '@mui/material';
import BarGraphics from 'components/BarGraphics';
import DetailsActiveTable from 'components/DetailsActiveTable';
import Map from 'components/Map';
import PieGraphics from 'components/PieGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';

const DistributionRegionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const GrapichPieBox = styled(Box)({
    width: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

export default function Template2and9and11({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsPJvsPF, setValuesGraphicsPJvsPF] = useState({});
    const [valuesGraphicsTime, setValuesGraphicsTime] = useState({});
    const [valuesGraphicsValue, setValuesGraphicsValue] = useState({});
    const [valuesGraphicsAjuizados, setValuesGraphicsAjuizados] = useState({});
    const [infoMap, setInfoMap] = useState({});

    const titleConditions =
        walletDetails.template.number === 1 ||
        walletDetails.template.number === 2 ||
        walletDetails.template.number === 11;

    const conditionalValueOrBalance = [1, 2, 11].includes(
        walletDetails.template.number
    )
        ? "Valor Original"
        : "Saldo em Aberto";

    const objectInfo = {
        Mapa: {},
    };


    useEffect(() => {
        walletDetails?.calculationsWallet?.[0][
            `Distribuição de ${conditionalValueOrBalance} e Quantidade de Documentos por Região`
        ].Estados?.forEach((itemStatus, index) => {
            const valueStatus =
                walletDetails?.calculationsWallet[0][
                `Distribuição de ${conditionalValueOrBalance} e Quantidade de Documentos por Região`
                ][conditionalValueOrBalance][index];
            if (itemStatus !== "Total") {
                if (objectInfo.Mapa[itemStatus]) {
                    objectInfo.Mapa[itemStatus] +=
                        objectInfo.Mapa[itemStatus];
                } else {
                    objectInfo.Mapa[itemStatus] = parseFloat(valueStatus);
                }
            }
        });
        setInfoMap(objectInfo?.Mapa);
    }, []);

    useEffect(() => {

        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Créditos PF Versus PJ'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Créditos PF Versus PJ'
                ]
            )[index];

            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsPJvsPF(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Tempo'
            ] ||
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Tempo'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Tempo'
                ] ||
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Tempo'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });

        setValuesGraphicsTime(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Valor'
            ] ||
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Valor'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Valor'
                ] ||
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Valor'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsValue(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Créditos Ajuizados Versus Créditos Não Ajuizados'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Créditos Ajuizados Versus Créditos Não Ajuizados'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsAjuizados(valuesGraphics);
    }, [walletDetails]);

    return (
        <>
            <DistributionRegionBox>
                <Title>
                    {titleConditions
                        ? 'Distribuição de Valor Original e Quantidade de Documentos por Região'
                        : 'Distribuição de Saldo em Aberto e Quantidade de Documentos por Região'}
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <Map data={infoMap} />
                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Região'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Região'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Região'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Região'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionRegionBox>

            <DistributionRegionBox>
                <Title>
                    {titleConditions
                        ? 'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Tempo'
                        : 'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Tempo'}
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsTime?.['Faixa de tempo']}
                        data={
                            valuesGraphicsTime?.['Saldo em Aberto'] ||
                            valuesGraphicsTime?.['Valor Original']
                        }
                    />
                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Tempo'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Tempo'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Tempo'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Tempo'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionRegionBox>

            <DistributionRegionBox>
                <Title>
                    {titleConditions
                        ? 'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Valor'
                        : 'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Valor'}
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsValue?.['Faixa de Valor']}
                        data={
                            valuesGraphicsValue?.['Saldo em Aberto'] ||
                            valuesGraphicsValue?.['Valor Original']
                        }
                    />
                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Valor'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Valor'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Valor Original e Quantidade de Documentos por Faixa de Valor'
                            ] ||
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Valor'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionRegionBox>

            <DistributionRegionBox>
                <Title>Distribuição de Créditos PF Versus PJ</Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '240px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <GrapichPieBox>
                        <PieGraphics
                            labels={valuesGraphicsPJvsPF?.['Tipo de Pessoa']}
                            data={
                                valuesGraphicsPJvsPF?.['Valor Original'] ||
                                valuesGraphicsPJvsPF?.['Saldo em Aberto']
                            }
                            content='warrantyPerson'
                        />
                    </GrapichPieBox>

                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Créditos PF Versus PJ'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Créditos PF Versus PJ'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionRegionBox>

            <DistributionRegionBox>
                <Title>
                    Distribuição de Créditos Ajuizados Versus Créditos Não
                    Ajuizados
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '240px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <GrapichPieBox>
                        <PieGraphics
                            labels={
                                valuesGraphicsAjuizados?.['Créditos Ajuizados']
                            }
                            data={
                                valuesGraphicsAjuizados?.['Valor Original'] ||
                                valuesGraphicsAjuizados?.['Saldo em Aberto']
                            }
                            content='facePerson'
                        />
                    </GrapichPieBox>
                    <DetailsActiveTable
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Créditos Ajuizados Versus Créditos Não Ajuizados'
                            ]
                        )}
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Créditos Ajuizados Versus Créditos Não Ajuizados'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                    />
                </Box>
            </DistributionRegionBox>
        </>
    );
}
