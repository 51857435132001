/* eslint-disable no-return-assign */
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const TextPerfil = styled(Typography)({
    color: '#111',
    fontfamily: 'Open Sans',
});

const ContainerMenu = styled(Box)({
    position: 'absolute',
    width: '220px',
    right: '32px',
    top: '43px',
    background: '#ffffff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: 1,
});

export default function ModalUser() {
    const { user, getUsers } = useContext(UserContext);
    const location = useLocation();

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getUsers();
    }, []);

    return (
        <ContainerMenu>
            <Box sx={{ textAlign: 'center', padding: '10px 0' }}>
                <img
                    src='/static/image/Perfil.svg'
                    alt='icon menu'
                    width='36'
                    height='36'
                />

                <TextPerfil sx={{ fontWeight: 700 }}>{user?.name}</TextPerfil>
                <TextPerfil variant='subtitle1'>
                    {user?.reasonSocial}
                </TextPerfil>
            </Box>
            <Box>
                <List>
                    <ListItem
                        disablePadding
                        component={Link}
                        to={
                            location.pathname.includes('/buyer')
                                ? '/buyer/profile'
                                : '/seller/profile'
                        }
                        sx={{
                            color: '#111',
                            '&:hover': {
                                color: '#000',
                            },
                        }}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                px: 3,
                                gap: '10px',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src='/static/image/Perfil.svg'
                                    alt='perfil'
                                    width='16'
                                    height='16'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Perfil'
                                variant='body1'
                                sx={{ fontSize: '14px', fontWeight: 700 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    {user?.company?.isAdmin && (
                        <ListItem
                            disablePadding
                            component={Link}
                            to={
                                location.pathname.includes('/buyer')
                                    ? '/buyer/administration'
                                    : '/seller/administration'
                            }
                            sx={{
                                color: '#111',
                                '&:hover': {
                                    color: '#000',
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    px: 3,
                                    gap: '10px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        src='/static/image/DadosEmpresa.svg'
                                        alt='dados empresa'
                                        width='16'
                                        height='16'
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Dados da empresa'
                                    variant='body1'
                                    sx={{ fontSize: '14px', fontWeight: 700 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}

                    {/*  <ListItem
                        disablePadding
                        component={Link}
                        to='/'
                        sx={{
                            color: '#111',
                            '&:hover': {
                                color: '#000',
                            },
                        }}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                px: 3,
                                gap: '10px',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src='/static/image/CriarConta.svg'
                                    alt='alterar senha'
                                    width='16'
                                    height='16'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Alterar senha'
                                sx={{ fontSize: '14px', fontWeight: 700 }}
                            />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem
                        disablePadding
                        // component='button'
                        onClick={() => (window.location.href = '/signout')}
                        sx={{
                            color: '#111',
                            '&:hover': {
                                color: '#000',
                            },
                        }}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                px: 3,
                                gap: '10px',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src='/static/image/Sair.svg'
                                    alt='sair'
                                    width='16'
                                    height='16'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Sair'
                                sx={{ fontSize: '14px', fontWeight: 700 }}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </ContainerMenu>
    );
}
