/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import LoadingScreen from 'components/LoadingScreen';
import ModalInterest from 'components/ModalInterest';
import { UserContext } from 'context/UserContext';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';
import BaseTemplate from './BaseTemplate';

const HeaderContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '32px 32px 32px 0px',
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

export default function Details({ sellerIdResume }) {
    const [walletDetails, setWalletDetails] = useState([]);
    const [activeDetails, setActiveDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [template, setTemplate] = useState(0);
    const [statusActive, setStatusActive] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    // const [userId, setUserId] = useState('');
    const [infosNdaAndLink, setInfosNdaAndLink] = useState({
        link: false,
        nda: 'Recusado',
    });
    const [userDownloadedActive, setUserDownloadedActive] = useState(false);
    const location = useLocation();

    let { id, idResume } = useParams();
    // quando o usuário tiver acesso ao detalhes do ativo, depois de assinar o NDA, estado ir p true
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    // const { statusActive } = useContext(RowsContext);

    // activeList/resume/63cfd01d416fe21a09fcae18

    async function getWalletDetails() {
        if (!id || id === 'undefined') {
            return;
        }
        try {
            setIsLoading(true);
            const res = await api.post('/api/active', {
                id,
                idBuyer: user.group === 'comprador' ? user._id : null,
            });
            setActiveDetails(res.data);
            setStatusActive(res.data.status);
        } catch (e) {
            toast.error(e.response.data.message || e.message || e);
        } finally {
            setIsLoading(false);
        }
    }

    const getResumeDetails = async () => {
        if (user.group === 'vendedor') {
            idResume = sellerIdResume;
        }

        if (!idResume || idResume === 'undefined') {
            return;
        }

        try {
            setIsLoading(true);
            const res = await api.get(`/api/resume/${idResume}`);
            setWalletDetails(res.data);
            setTemplate(res.data.template?.number);
        } catch (e) {
            toast.error(e.response.data.message || e.message || e);
        } finally {
            // setHasActiveDetails
            setIsLoading(false);
        }
    };

    const handleSendEmail = async () => {
        try {
            const resRequestDownload = await api.post('/api/resume/download', {
                // email: user.email,
                idActive: id,
                idResume,
            });
            toast.success(
                'O link para o download do ativo também será enviado ao seu e-mail.',
                { autoClose: false }
            );
            setUserDownloadedActive(true);
        } catch (error) {
            toast.error('Erro ao solicitar download');
        }
    };

    const handleGetNda = async () => {
        try {
            const res = await api.get(`/api/company/${id}`);
            // if (res.data.nda !== 'Recusado') setStatusActive(res.data.nda);

            // if (res.data.nda === 'Assinado') {
            setUserDownloadedActive(res.data.nda === 'Assinado');
            // }
            setInfosNdaAndLink(res.data);
        } catch (error) {
            // console.log('error', error);
        }
    };

    const handleRequestDownload = async () => {
        try {
            let waitingNda = false;
            setIsLoading(true);

            if (infosNdaAndLink.nda === 'Assinado') {
                navigate(`/buyer/download/active/${id}/${idResume}`);
                handleSendEmail();
                return;
            }
            if (infosNdaAndLink.nda === 'Recusado') {
                waitingNda = false;
                setStatusActive('Recusado');
            } else if (infosNdaAndLink.nda === 'Aguardando assinatura') {
                waitingNda = true;
            }
            navigate('/buyer/download/accessrequest', {
                state: {
                    data: infosNdaAndLink,
                    idResume,
                    idActive: id,
                    waitingNda,
                },
            });
        } catch (e) {
            toast.error('Erro ao solicitar download');
        } finally {
            setIsLoading(false);
        }
    };

    useLayoutEffect(() => {
        if (user.group === 'comprador') handleGetNda();
    }, [user, JSON.stringify(infosNdaAndLink)]);

    useEffect(() => {
        getResumeDetails();
    }, [idResume]);

    useEffect(() => {
        getWalletDetails();
    }, [id]);
    
    return isLoading ? (
        <LoadingScreen />
    ) : (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: location.pathname.includes('/seller') ? '1180px' : '85%',
                maxWidth: '1380px',
                margin: '0 auto',
            }}
        >
            {user.group === 'comprador' && (
                <HeaderContainer>
                    <Button
                        variant='text'
                        sx={{
                            marginTop: '32px',
                            width: '32px',
                        }}
                        color='primary'
                        size='4px'
                        startIcon={<ArrowBackIosRoundedIcon />}
                        onClick={() => navigate('/buyer/opportunities')}
                    >
                        Voltar
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <BoxTitle>
                            <img
                                src='/static/image/Oportunidades.svg'
                                alt='Etiqueta - Oportunidades'
                            />
                            <TypographyTitle
                                sx={{ marginLeft: '14px' }}
                                variant='h1'
                            >
                                Resumo do Ativo {activeDetails.idGenerate}
                            </TypographyTitle>
                        </BoxTitle>
                        <Box>
                            <Button
                                variant={
                                    userDownloadedActive
                                        ? 'outlined'
                                        : 'contained'
                                }
                                sx={{
                                    margin: '32px 0px 32px 8px',
                                }}
                                color='primary'
                                size='8px'
                                disabled={['Recusado', 'Vendido'].includes(
                                    statusActive
                                )}
                                onClick={handleRequestDownload}
                            >
                                {statusActive === 'Assinado' ||
                                statusActive === 'Comprado'
                                    ? 'Download do ativo detalhado'
                                    : 'Solicitar download do ativo detalhado'}
                            </Button>
                            {userDownloadedActive && (
                                <Button
                                    variant='contained'
                                    sx={{
                                        margin: '32px 14px',
                                    }}
                                    color='primary'
                                    size='8px'
                                    disabled={[
                                        'Recusado',
                                        'Vendido',
                                        'Comprado',
                                    ].includes(statusActive)}
                                    onClick={() => setIsOpenModal(true)}
                                >
                                    Declarar interesse
                                </Button>
                            )}
                        </Box>
                    </Box>
                </HeaderContainer>
            )}

            <BaseTemplate template={template} walletDetails={walletDetails} />

            {user.group === 'comprador' && (
                <Box sx={{ alignSelf: 'end' }}>
                    <Button
                        variant={
                            userDownloadedActive ? 'outlined' : 'contained'
                        }
                        sx={{
                            margin: '32px 8px',
                        }}
                        disabled={['Recusado', 'Vendido'].includes(
                            statusActive
                        )}
                        color='primary'
                        size='8px'
                        onClick={handleRequestDownload}
                    >
                        {statusActive === 'Assinado' ||
                        statusActive === 'Comprado'
                            ? 'Download do ativo detalhado'
                            : 'Solicitar download do ativo detalhado'}
                    </Button>
                    {userDownloadedActive && (
                        <Button
                            variant='contained'
                            color='primary'
                            size='8px'
                            disabled={[
                                'Recusado',
                                'Vendido',
                                'Comprado',
                            ].includes(statusActive)}
                            onClick={() => setIsOpenModal(true)}
                        >
                            Declarar interesse
                        </Button>
                    )}
                </Box>
            )}

            <ModalInterest
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                idActive={id}
            />
        </Box>
    );
}
