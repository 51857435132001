/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Button, Typography } from '@mui/material';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import styled from 'styled-components';
import { theme } from 'themes';

const Container = styled(Box)({
    backgroundColor: theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px auto',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '24px',
    alignItems: 'center',
    height: '600px',
    '& .MuiFormHelperText-root': { height: '0px' },
});

const ContainerBox = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '600px',
    gap: '48px',
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '32px auto',
    maxWidth: '1200px',
});
const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

export default function WithoutDetailsActive({ walletDetails, idActive }) {
    const [downloading, setDownloading] = useState(false);
    const [sendingEmail, setSendingemail] = useState(false);
    const navigate = useNavigate();

    async function handleDownloadTemplate() {
        try {
            setDownloading(true);
            const res = await api.get(`/api/assessment/download/${idActive}`, {
                params: {
                    cacheBustTimestamp: Date.now(),
                },
                responseType: 'blob',
                headers: {
                    Accept: 'application/octet-stream',
                },
            });
            if (!(res.data instanceof Blob)) return;

            fileDownload(
                res.data,
                `2023 - KPMG Plataforma de Ativos Alternativos - ${res.headers['file-name']}.xlsx`
            );
        } catch (error) {
            toast.error(
                'Erro ao fazer o download do template. Tente novamente mais tarde.'
            );
        } finally {
            setDownloading(false);
        }
    }

    async function handleSendEmail() {
        try {
            setSendingemail(true);
            const res = await api.post(`/api/assessment/sendEmail`, {
                text: 'Teste email',
                idActive,
            });
            toast.success('O email foi enviado e chegará em instantes!');
        } catch (error) {
            toast.error('Erro ao enviar o email. Tente novamente mais tarde.');
        } finally {
            setSendingemail(false);
        }
    }

    return (
        <>
            <HeaderContainer>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Button
                        variant='text'
                        color='primary'
                        sx={{ width: '24px', marginBottom: '24px' }}
                        size='4px'
                        startIcon={<ArrowBackIosRoundedIcon />}
                        onClick={() => navigate('/seller/active')}
                    >
                        Voltar
                    </Button>
                    <HeaderBox>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img
                                src='/static/image/Oportunidades.svg'
                                alt='Etiqueta - Oportunidades'
                            />
                            <TypographyTitle
                                sx={{ marginLeft: '14px' }}
                                variant='h1'
                            >
                                Ativo {walletDetails.idGenerate}
                            </TypographyTitle>
                        </Box>
                    </HeaderBox>
                </Box>
            </HeaderContainer>
            <Container>
                <ContainerBox>
                    <Typography
                        variant='h3'
                        align='center'
                        paddingTop='12px'
                        fontWeight='600'
                        sx={{
                            color: theme.palette.themePrimary.primaryGray,
                            width: '820px',
                        }}
                    >
                        Você ainda não fez o <i>download</i> e/ou o
                        preenchimento do <i>template</i> do ativo. Clique no
                        botão abaixo para realizar o <i>download</i>.
                    </Typography>

                    <Button
                        variant='contained'
                        color='primary'
                        sx={{
                            width: '240px',
                            height: '48px',
                        }}
                        endIcon={
                            <img
                                src='/static/image/DownloadBranco.svg'
                                width={18}
                                height={18}
                                alt='Download'
                                style={{ marginLeft: '4px' }}
                            />
                        }
                        onClick={handleDownloadTemplate}
                        disabled={downloading}
                    >
                        <Typography variant='h5'>
                            Download do template
                        </Typography>
                    </Button>

                    <Typography
                        variant='body1'
                        sx={{ textAlign: 'center', width: '820px' }}
                    >
                        Já tem um <i>template</i> preenchido? Clique no botão
                        abaixo para receber as instruções de <i>upload</i> em
                        seu <i>e-mail</i>.
                    </Typography>

                    <Button
                        variant='outlined'
                        color='primary'
                        sx={{
                            width: '240px',
                            height: '48px',
                        }}
                        endIcon={<MailOutlineIcon />}
                        onClick={handleSendEmail}
                        disable={sendingEmail}
                    >
                        <Typography variant='h5'>
                            Enviar e-mail com instruções
                        </Typography>
                    </Button>
                </ContainerBox>
            </Container>
        </>
    );
}
