/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
function handleFormatCPF(e) {
    const formattedValue = e
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+$/, '$1');
    return formattedValue;
}

function handleFormatCNPJ(e) {
    const formattedValue = e
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{6})\d+$/, '$1');
    return formattedValue;
}

function handleFormatPhone(e) {
    const formattedValue = e
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '+$1 $2')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})/, '$1-$2');
    return formattedValue;
}

function formatCurrency(header, value, currency) {
    const formatValue = [
        'Valor Original',
        'Saldo em Aberto',
        'Valores',
        'Ticket Médio',
    ].includes(header);

    if (formatValue && value !== '-') {
        const floatValue = Math.round(parseFloat(value?.replace(',', '.')));
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
        }).format(floatValue);

        const currencyFormatted = formattedValue.replace('R$', '');

        return currency ? currency + currencyFormatted : currencyFormatted;
    }

    return value;
}

function formatColumns(info) {
    let switchWidth;
    const keys = Object.keys(info !== undefined && info);

    switch (keys?.length) {
        case 3:
            switchWidth = [340, 320, 200];
            break;
        case 4:
            switchWidth = [340, 320, 320, 160];
            break;

        default:
            switchWidth = [200, 170, 150, 170, 175, 135];
            break;
    }

    const columns = keys.map((key, index) => ({
        field: key,
        headerName: key,
        width: switchWidth[index],
    }));
    return columns;
}

function formatRows(info, currency) {
    const keys = Object.keys(info !== undefined && info);
    const values = Object.values(info !== undefined && info);
    const arrayObjects = [];
    if (info) {
        const headers = keys;
        const data = values?.map((row) => row);

        data[0].map((_, rowIndex) => {
            const row = {};
            headers.forEach((header, headerIndex) => {
                row.id = rowIndex + 1;

                row[header] =
                    header === 'Total' &&
                    data[headerIndex][rowIndex] === undefined
                        ? null
                        : formatCurrency(
                              header,
                              data[headerIndex][rowIndex],
                              currency
                          );
            });
            arrayObjects.push(row);
        });
    }
    return arrayObjects;
}

export {
    handleFormatCPF,
    handleFormatCNPJ,
    handleFormatPhone,
    formatColumns,
    formatRows,
    formatCurrency,
};
