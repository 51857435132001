/* eslint-disable no-unused-vars */
import { Box, Button, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DropzoneComponent from 'components/Dropzone';
import { useLayoutEffect, useState } from 'react';
import api from 'services/api';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '550px',
}));

export default function Upload() {
    const [hasFile, setHasFile] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [id, setId] = useState('');

    const verificationUserPermissions = async () => {
        try {
            await api.get('api/user/admin/upload/blob');
            setShowComponent(true);
        } catch (error) {
            setShowComponent(false);
            // console.log('error', error)
        }
    };

    useLayoutEffect(() => {
        verificationUserPermissions();
    }, []);

    return (
        showComponent && (
            <FormBox>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant='h1'
                        align='center'
                        marginBottom='8px'
                        paddingTop='8px'
                        sx={{ color: theme.palette.themePrimary.primaryGray }}
                    >
                        Upload do ativo
                    </Typography>
                    <Box sx={{ minWidth: '340px', width: '600px' }}>
                        <Typography
                            variant='caption'
                            sx={{
                                margin: '20px 0',
                                display: 'flex',
                                textAlign: 'center',
                            }}
                        >
                            Por favor, inclua / anexe o ativo do vendedor. Após
                            isso você receberá um id para incluir no card deste
                            ativo no Pipefy.
                        </Typography>

                        {!id ? (
                            <DropzoneComponent
                                fileType='.xlsx'
                                description='Arraste um arquivo XLSX de até 200MB do ativo do vendedor'
                                uploadedFile={uploadedFile}
                                setUploadedFile={setUploadedFile}
                                setHasFile={setHasFile}
                                hasFile={hasFile}
                                uploadPath='ativos'
                                setId={setId}
                                id={id}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        margin: '20px auto',
                                        color: theme.palette.themeTertiary
                                            .tertiaryBlue,
                                        fontSize: '25px',
                                    }}
                                >
                                    ID: {id.slice(1, id.length - 1)}
                                </Typography>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setHasFile(false);
                                        setId('');
                                        setUploadedFile(false);
                                    }}
                                >
                                    Enviar novo arquivo
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </FormBox>
        )
    );
}
