/* eslint-disable no-unused-vars */
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalUser from 'components/ModalUser';
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TopBar = styled(AppBar)((props) => ({
    background: props.theme.palette.themePrimary.primaryWhiteLight,
    minHeight: '70',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
}));

const BoxLogo = styled(Box)({
    display: 'flex',
    cursor: 'pointer',
    // flex: 1,
});

const ToolbarContainer = styled(Toolbar)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export default function Header() {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const handleLogin = () => {
        window.location.href = '/signin';
    };

    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <TopBar position='relative'>
            <ToolbarContainer>
                <BoxLogo
                    onClick={() =>
                        'group' in user &&
                        (user.group === 'vendedor'
                            ? navigate('/seller/active')
                            : navigate('/buyer/opportunities'))
                    }
                >
                    <img
                        src='/static/image/LogoKPMG.svg'
                        alt='KPMG Plataforma de Ativos Alternativos'
                    />
                </BoxLogo>
                <Box
                    sx={{
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    {location.pathname === '/' ? (
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{ fontWeight: 700 }}
                            onClick={handleLogin}
                        >
                            Entrar
                        </Button>
                    ) : (
                        location.pathname !== '/buyer' &&
                        location.pathname !== '/buyer/' && (
                            <IconButton onClick={() => setOpen(!open)}>
                                <img
                                    src='/static/image/Perfil.svg'
                                    alt='Perfil'
                                    style={{ width: '20px', height: 'auto' }}
                                />
                            </IconButton>
                        )
                    )}
                </Box>
            </ToolbarContainer>
            {open && <ModalUser />}
        </TopBar>
    );
}
