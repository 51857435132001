/* eslint-disable default-case */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Map from 'components/Map';
import ModalForm from 'components/ModalForm';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import api from 'services/api';
import { theme } from 'themes';

const ContentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '50px 70px',
});

const TypographyTitle = styled(Typography)({
    color: ' #333333',
    fontWeight: 400,
    fontSize: '40px',
    padding: '0 80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
});

const BoxInfoFooter = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    gap: '60px',
});

const GridInfo = styled(Box)({
    color: ' #333333',
    fontWeight: 400,
    fontSize: '20px',
    padding: '0 80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '24px 0',
    '> ul': {
        textAlign: 'left',
        '> li': {
            listStyle: 'none',
            margin: '10px 0',
            '> a': {
                textDecoration: 'none',
                listStyle: 'none',
                color: ' #333333',
                '&:hover': {
                    color: ' #333333c3',
                },
            },
        },
        '> li:nth-child(1)': {
            fontWeight: 600,
        },
    },
});

const BoxCard = styled(Box)({
    width: '480px',
    height: '380px',
    display: 'flex',
    margin: '30px 0',
    border: '1px #D4D4D4 solid',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    backgroundColor: theme.palette.themePrimary.primaryWhiteLight,
});

const GridCard = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '4px',
    width: '240px',
    height: '112px',
    border: '1px #D4D4D4 solid',
});

const GridCardsLeft = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    justifyItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '4px',
    width: '260px',
    height: '112px',
    paddingLeft: '15px',
    border: '1px #D4D4D4 solid',
});

export default function Home() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [info, setInfo] = useState({});
    const [dataInfo, setDataInfo] = useState({});
    const [sellerOrBuyer, setSellerOrBuyer] = useState('');

    const builderValueGraph = (value) => {
        let newValue = 60;
        switch (true) {
            case value >= 5 && value < 10:
                newValue = 70;
                break;
            case value >= 10 && value < 15:
                newValue = 30;
                break;
            case value >= 15 && value < 20:
                newValue = 20;
                break;
            case value >= 20:
                newValue = 10;
                break;
        }
        console.log('newValue', newValue);

        return newValue;
    };

    const options = {
        indexAxis: 'y',
        // clip: { left: 100, top: false, right: -2, bottom: 0 },
        barThickness: 40,
        scales: {
            x: {
                // stacked: true,
                display: false,
                grid: {
                    display: false,
                },
                title: {
                    display: false,
                    // text: 'R$ Milhões',
                },
            },
            y: {
                // stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        elements: {
            bar: {
                borderWidth: 10,
                // backgroundColor: '#111',
                // borderSkipped: true,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    title: (context) =>
                        context[0].dataset.label[context[0].parsed.y],

                    label: (tooltipItem, data) => {
                        console.log('tooltipItem ', tooltipItem);
                        return `R$ ${tooltipItem.formattedValue}`;
                    },
                },
            },
            legend: {
                // position: 'bottom',
                display: false,
                labels: {
                    // Configuração da fonte e tamanho das labels dos datasets
                },
            },
        },
    };

    const handleOpenForm = (value) => {
        setIsOpenModal(true);
        setSellerOrBuyer(value);
    };

    const getInfo = async () => {
        try {
            const res = await api.get('api/info');
            const keysCreditType = Object.keys(res.data['Tipo de Crédito']);
            const labels = keysCreditType.map((item) =>
                item?.length > 38 ? `${item.slice(0, 38)}...` : item
            );
            setInfo(res.data);
            const objectTypeCredit = Object.values(
                res.data['Tipo de Crédito']
            ).map((item) => +item.toFixed(0));
            setDataInfo({
                labels,
                datasets: [
                    {
                        label: keysCreditType,
                        clip: { left: 50, top: false, right: -2, bottom: 0 },
                        barPercentage: 30,
                        barThickness: builderValueGraph(
                            objectTypeCredit.length
                        ),
                        data: objectTypeCredit,
                        backgroundColor: [
                            'rgba(101, 216, 174, 0.8)',
                            'rgba(197, 144, 229, 0.8)',
                            'rgba(220, 145, 121, 0.8)',
                            'rgba(100, 129, 235, 0.8)',
                            'rgba(236, 223, 112, 0.8)',
                            'rgba(115, 214, 220, 0.8)',
                            'rgba(143, 215, 255, 0.8)',
                        ],
                        borderColor: [
                            'rgba(101, 216, 174, 1)',
                            'rgba(197, 144, 229, 1)',
                            'rgba(220, 145, 121, 1)',
                            'rgba(100, 129, 235, 1)',
                            'rgba(236, 223, 112, 1)',
                            'rgba(115, 214, 220, 1)',
                            'rgba(143, 215, 255, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        localStorage.clear();
        getInfo();
    }, []);

    return (
        <>
            <ModalForm
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                sellerOrBuyer={sellerOrBuyer}
            />
            <Box sx={{ height: '100%' }}>
                <ContentBox>
                    <TypographyTitle
                        sx={{
                            display: 'inline',
                            fontSize: '25px',
                            textAlign: 'center',
                        }}
                    >
                        {/* <span style={{ color: '#00338D', fontFamily:'KPMG Bold' }}>
                            KPMG Plataforma de Ativos Alternativos,
                        </span>{' '} */}
                        <img src='/static/image/KPMG Plataforma de Ativos Alternativos.svg' alt='KPMG' style={{marginBottom:'-2px'}}/>
                        ,{' '}a mais nova solução para conectar vendedores e
                        compradores de Ativos Alternativos.
                    </TypographyTitle>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 4,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                    >
                        <BoxCard>
                            <Typography
                                sx={{
                                    width: '400px',
                                    textAlign: 'start',
                                    color: '#5E5E5E',
                                    margin: '26px 0',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '22px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                    }}
                                >
                                    Benefícios do Vendedor
                                </Typography>
                                <ul
                                    style={{
                                        paddingLeft: '16px',
                                        marginTop: '24px',
                                    }}
                                >
                                    <li>
                                        Acesso a um maior número de compradores.
                                    </li>
                                    <li>
                                        Maior agilidade no processo de venda.
                                    </li>
                                    <li>
                                        Processo organizado e transparente de
                                        venda.
                                    </li>
                                    <li>Maximização do valor do ativo.</li>
                                    <li>
                                        Geração de caixa e melhoria dos índices
                                        financeiros.
                                    </li>
                                    <li>Benefícios fiscais.</li>
                                </ul>
                            </Typography>
                            <Button
                                variant='contained'
                                backgroundColor='#0047FF'
                                sx={{ width: '400px' }}
                                onClick={() => handleOpenForm('seller')}
                            >
                                Quero vender ativos
                            </Button>
                        </BoxCard>
                        <BoxCard>
                            {/* <img
                                src='static/image/ComprarAtivos.svg'
                                alt='comprar ativos'
                                width='100'
                                height='100'
                            /> */}
                            <Typography
                                sx={{
                                    width: '400px',
                                    textAlign: 'start',
                                    color: '#5E5E5E',
                                    margin: '26px 0',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '22px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                    }}
                                >
                                    Benefícios do Comprador
                                </Typography>
                                <ul
                                    style={{
                                        paddingLeft: '16px',
                                        marginTop: '24px',
                                    }}
                                >
                                    <li>Acesso a um maior número de ativos.</li>
                                    <li>
                                        Maior agilidade no processo de compra.
                                    </li>
                                    <li> Relatório de análise dos ativos.</li>
                                    <li>Possibilidade de retornos maiores.</li>
                                    <li>
                                        Serviços adicionais disponíveis, como
                                        Due Diligence e Valuation.
                                    </li>
                                    <li style={{ visibility: 'hidden' }}>a</li>
                                </ul>
                            </Typography>
                            <Button
                                variant='contained'
                                backgroundColor='#0047FF'
                                sx={{ width: '400px' }}
                                onClick={() => handleOpenForm('buyer')}
                            >
                                Quero comprar ativos
                            </Button>
                        </BoxCard>
                    </Box>
                </ContentBox>

                <ContentBox
                    sx={{
                        backgroundColor:
                            theme.palette.themePrimary.primaryWhiteLight,
                        margin: '0',
                    }}
                >
                    <TypographyTitle
                        sx={{ textAlign: 'center', margin: '24px 0' }}
                    >
                        Nossas Oportunidades
                    </TypographyTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            // alignItems: 'center'
                            marginBottom: '40px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '480px',
                                gap: '10px',
                            }}
                        >
                            <GridCardsLeft>
                                <Box sx={{ alignItems: 'start' }}>
                                    <Typography
                                        variant='body1'
                                        sx={{ color: '#333333' }}
                                    >
                                        Valor Ofertado na Plataforma
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src='static/image/VenderAtivos.svg'
                                            alt='venderativos'
                                            width='28'
                                            height='23'
                                        />
                                        <TypographyTitle
                                            sx={{
                                                fontSize:
                                                    info?.[
                                                        'Valor Ofertado na Plataforma'
                                                    ] !== 0 &&
                                                    info?.[
                                                        'Valor Ofertado na Plataforma'
                                                    ]?.includes('milhões')
                                                        ? '24px'
                                                        : '28px',
                                                padding: '0 0 0 5px',
                                            }}
                                        >
                                            R${' '}
                                            {
                                                info?.[
                                                    'Valor Ofertado na Plataforma'
                                                ]
                                            }
                                        </TypographyTitle>
                                    </Box>
                                </Box>
                            </GridCardsLeft>
                            <GridCardsLeft>
                                <Box sx={{ alignItems: 'start' }}>
                                    <Typography
                                        variant='body1'
                                        sx={{ color: '#333333' }}
                                    >
                                        Quantidade de Ativos
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            width: '100px',
                                            marginRight: '70px',
                                        }}
                                    >
                                        <img
                                            src='static/image/Carteiras.svg'
                                            alt='carteiras'
                                            width='28'
                                            height='23'
                                            // style={{ marginLeft: '6px' }}
                                        />
                                        <TypographyTitle
                                            sx={{
                                                fontSize: '32px',
                                                padding: '0 10px',
                                            }}
                                        >
                                            {info?.['Quantidade de Ativos']}
                                        </TypographyTitle>
                                    </Box>
                                </Box>
                            </GridCardsLeft>

                            <GridCardsLeft>
                                <Box sx={{ alignItems: 'start' }}>
                                    <Typography
                                        variant='body1'
                                        sx={{ color: '#333333' }}
                                    >
                                        Quantidade de Documentos
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            // width: '100px',
                                            marginRight: '70px',
                                        }}
                                    >
                                        <img
                                            src='static/image/Titulos.svg'
                                            alt='titulos'
                                            width='32'
                                            height='27'
                                            // style={{ paddingRight: '6px' }}
                                        />
                                        <TypographyTitle
                                            sx={{
                                                fontSize: '32px',
                                                padding: '5px 10px',
                                            }}
                                        >
                                            {info?.['Quantidade de Documentos']}
                                        </TypographyTitle>
                                    </Box>
                                </Box>
                            </GridCardsLeft>
                            <GridCardsLeft>
                                <Box sx={{ alignItems: 'start' }}>
                                    <Typography
                                        variant='body1'
                                        sx={{ color: '#333333' }}
                                    >
                                        Ticket Médio
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src='static/image/TicketMedio.svg'
                                            alt='ticketmedio'
                                            width='28'
                                            height='23'
                                        />
                                        <TypographyTitle
                                            sx={{
                                                fontSize: '28px',
                                                padding: '0 0 0 5px',
                                            }}
                                        >
                                            R$ {info?.['Ticket Médio']}
                                        </TypographyTitle>
                                    </Box>
                                </Box>
                            </GridCardsLeft>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '480px',
                            }}
                        >
                            <GridCard
                                sx={{
                                    margin: '0 10px',
                                    width: '480px',
                                    height: '100%',
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{ color: '#333333', marginTop: '8px' }}
                                >
                                    Tipo de Crédito
                                </Typography>
                                <Box
                                    sx={{
                                        height: '100vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ width: '100%', height: '100%' }}>
                                        {dataInfo?.labels &&
                                            dataInfo?.datasets[0]?.data && (
                                                <Bar
                                                    data={dataInfo}
                                                    options={options}
                                                />
                                            )}
                                    </Box>
                                </Box>
                            </GridCard>
                        </Box>

                        <GridCard
                            sx={{
                                width: '400px',
                                height: '480px',
                            }}
                        >
                            {info?.Mapa && <Map data={info.Mapa} />}
                        </GridCard>
                    </Box>
                </ContentBox>

                <ContentBox>
                    <BoxInfoFooter>
                        <GridInfo>
                            <ul>
                                <li>Institucional</li>
                                <li>
                                    <Link
                                        to='/about'
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        Sobre a plataforma
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to='/about/questions'
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        Perguntas Frequentes
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to='/about/market'
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        O Mercado de Ativos Alternativos
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        onClick={() => window.scrollTo(0, 0)}
                                        to='https://kpmg.com/br/pt/home/sobre-a-kpmg.html'
                                        target='blank'
                                    >
                                        Sobre a KPMG
                                    </Link>
                                </li>
                            </ul>
                        </GridInfo>
                        <GridInfo>
                            <ul>
                                <li>Minha conta</li>
                                <li>
                                    <a href='/signin'>Vender</a>
                                </li>
                                <li>
                                    <a href='/signin'>Comprar</a>
                                </li>
                            </ul>
                        </GridInfo>
                        <GridInfo>
                            <ul>
                                <li>Contato</li>
                                <li>br-fmcontatopaa@kpmg.com.br</li>
                                <li>+55 (11) 99355-8177</li>
                            </ul>
                        </GridInfo>
                    </BoxInfoFooter>
                </ContentBox>
            </Box>
        </>
    );
}
