import { Box, Typography } from '@mui/material';
import BarGraphics from 'components/BarGraphics';
// import BarGraphics from 'components/BarGraphics';
import DetailsActiveTable from 'components/DetailsActiveTable';
import PieGraphics from 'components/PieGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';
import Template3and7and8and10 from './Template3and7and8and10';

const DistributionRegionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    maxWidth: '1380px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

const GrapichPieBox = styled(Box)({
    width: '600px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});

export default function Template6({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsConsignante, setValuesGraphicsConsignante] = useState(
        {}
    );
    const [valuesGraphicsAge, setValuesGraphicsAge] = useState({});

    useEffect(() => {
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ente Consignante'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ente Consignante'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsConsignante(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Idade'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Idade'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsAge(valuesGraphics);
    }, [walletDetails]);

    return (
        <>
            <Template3and7and8and10 walletDetails={walletDetails} />
            <DistributionRegionBox>
                <Title>
                    Distribuição de Saldo em Aberto e Quantidade de Documentos
                    por Ente Consignante
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <GrapichPieBox>
                        <PieGraphics
                            labels={
                                valuesGraphicsConsignante?.['Ente Consignante']
                            }
                            data={
                                valuesGraphicsConsignante?.['Saldo em Aberto']
                            }
                            content='propertyType'
                            size='lg'
                        />
                    </GrapichPieBox>
                    <DetailsActiveTable
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ente Consignante'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ente Consignante'
                            ]
                        )}
                    // sx={{ height: '200px' }}
                    />
                </Box>
            </DistributionRegionBox>

            <DistributionRegionBox>
                <Title>
                    Distribuição de Saldo em Aberto e Quantidade de Documentos
                    por Faixa de Idade
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsAge?.['Faixa de Idade']}
                        data={valuesGraphicsAge?.['Saldo em Aberto']}
                        type='ageRange'
                    />
                    <DetailsActiveTable
                        rows={formatRows(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Idade'
                            ], walletDetails?.calculationsWallet?.[0]?.Moeda
                        )}
                        columns={formatColumns(
                            walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Faixa de Idade'
                            ]
                        )}
                        sx={{ marginLeft: '60px' }}
                    />
                </Box>
            </DistributionRegionBox>
        </>
    );
}
