/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
// import useUser from 'hooks/User';
import { useContext, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';
import DropzoneComponent from '../../../components/Dropzone';

const TypographyOptions = styled(Typography)((props) => ({
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '880px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));

const ContainerBox = styled(Box)((props) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '600px',
}));

export default function ConfidentialityAgreement() {
    const navigate = useNavigate();
    const responseAccessRequest = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [hasFile, setHasFile] = useState(false);
    const [checked, setChecked] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [filesNda, setFilesNda] = useState([]);
    const [companyAlreadyRegistered, setCompanyAlreadyRegistered] =
        useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const {
        user,
        getUsers,
        isUserLinkedToCompany,
        setIsUserLinkedToCompany,
        nda,
        setNda,
    } = useContext(UserContext);

    async function onSubmit(submittedInfo) {
        let data = submittedInfo;
        if (checked) {
            data = {
                name: user.name,
                email: user.email,
                cnpj: user?.company?.idCompany?.cnpj,
                documentName: filesNda[0]?.name,
            };
        } else {
            data = {
                name: data.Name,
                email: data.Email,
                cnpj: user?.company?.idCompany?.cnpj,
                documentName: filesNda[0]?.name,
            };
        }
        try {
            const resRequestDownload = await api.post('/api/nda', {
                idActive: responseAccessRequest.state.idActive,
                documentName: filesNda[0].name,
                name: data.name,
                email: data.email,
            });
            toast.success(resRequestDownload.data.message);
            // getUsers();
            setSubmit(true);
        } catch (error) {
            toast.error('Erro ao enviar detalhes');
        }
        // setSubmit(true);
        return data;
    }

    function handleUserSubscribes() {
        setChecked(!checked);
        setName(user.name);
        setEmail(user.email);
    }

    const validateLettersOnly = (value) => {
        if (!value) return true;
        return value.match(/^[a-zA-Z\s]*$/) !== null;
    };

    function handleClose() {
        navigate(-1);
        getUsers();
    }

    function handleBackActive() {
        navigate('/buyer/opportunities');
        getUsers();
    }

    useLayoutEffect(() => {
        // getUsers()
        if (responseAccessRequest.state.data.link) {
            setCompanyAlreadyRegistered(true);
            // if (user?.company?.status === 'ACCEPTED') {
            //     setIsUserLinkedToCompany(true);
            // }
        }
    }, [
        // isUserLinkedToCompany,
        responseAccessRequest.state.data.link,
        responseAccessRequest.state.data,
        user,
    ]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormBox>
                <ContainerBox>
                    <Button
                        sx={{ alignSelf: 'end' }}
                        variant='text'
                        color='primary'
                        endIcon={<CloseRoundedIcon />}
                        onClick={handleClose}
                    >
                        Fechar
                    </Button>

                    {responseAccessRequest.state.waitingNda ? (
                        <>
                            <Typography
                                variant='h1'
                                align='center'
                                marginBottom='8px'
                                paddingTop='12px'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGray,
                                }}
                            >
                                Solicitação de acesso a detalhes do ativo
                            </Typography>
                            <Typography
                                variant='body1'
                                sx={{ textAlign: 'center', margin: '35px 0' }}
                            >
                                Aguardando assinatura do Acordo de
                                confidencialidade. Enquanto isso, continue
                                navegando em nossa plataforma.
                            </Typography>
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{
                                    width: '300px',
                                    height: '48px',
                                    marginTop: '8px',
                                }}
                                onClick={handleBackActive}
                            >
                                <Typography variant='h5'>
                                    Voltar para listagem de ativos
                                </Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant='h1'
                                align='center'
                                marginBottom='8px'
                                paddingTop='12px'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGray,
                                }}
                            >
                                {submit
                                    ? 'Solicitação de acesso a detalhes do ativo'
                                    : 'Acordo de Confidencialidade'}
                            </Typography>
                            <Typography
                                variant='body1'
                                sx={{ textAlign: 'center', margin: '35px 0' }}
                            >
                                {submit
                                    ? '   Solicitação de acesso enviada com sucesso. Enviaremos o status de sua solicitação por e-mail. Enquanto isso, continue navegando em nossa plataforma.'
                                    : '  Para garantirmos a segurança dos dados de ambas as partes, é necessário que uma pessoa legalmente responsável por responder pela empresa assine o Acordo de Confidencialidade. Para isso, preencha as informações abaixo.'}
                            </Typography>

                            {submit ? (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    sx={{
                                        width: '300px',
                                        height: '48px',
                                        marginTop: '8px',
                                    }}
                                    onClick={handleBackActive}
                                >
                                    <Typography variant='h5'>
                                        Voltar para listagem de ativos
                                    </Typography>
                                </Button>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '8px',
                                            marginBottom: '25px',
                                            maxHeight: '100%',
                                        }}
                                    >
                                        <FormControlLabel
                                            sx={{
                                                margin: '12px auto',
                                                display: 'flex',
                                                alignItems: 'start',
                                                flexDirection: 'column-reverse',
                                            }}
                                            control={
                                                <TextField
                                                    /* value={checked ? name : ''}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            } */
                                                    autoComplete='email'
                                                    id='nomequemassina'
                                                    placeholder='Preencha com o nome de quem assina'
                                                    variant='outlined'
                                                    sx={{
                                                        marginTop: '8px',
                                                        width: '312px',
                                                    }}
                                                    disabled={checked}
                                                    error={
                                                        !checked && errors?.Name
                                                    }
                                                    helperText={
                                                        (!checked &&
                                                            errors?.Name
                                                                ?.type ===
                                                                'validate' &&
                                                            'Digite apenas letras e espaços') ||
                                                        (!checked &&
                                                            errors?.Name &&
                                                            'O nome é requerido')
                                                    }
                                                    {...register('Name', {
                                                        required: !checked,
                                                        minLength: 1,
                                                        validate:
                                                            validateLettersOnly,
                                                    })}
                                                />
                                            }
                                            label={
                                                <TypographyOptions variant='h5'>
                                                    Nome de quem assina
                                                </TypographyOptions>
                                            }
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: '12px auto',
                                                display: 'flex',
                                                alignItems: 'start',
                                                flexDirection: 'column-reverse',
                                            }}
                                            control={
                                                <TextField
                                                    id='emailquemassina'
                                                    /* value={checked ? email : ''} */
                                                    placeholder='Preencha com o e-mail de quem assina'
                                                    variant='outlined'
                                                    sx={{
                                                        marginTop: '8px',
                                                        width: '312px',
                                                    }}
                                                    disabled={checked}
                                                    error={
                                                        !checked &&
                                                        errors?.Email
                                                    }
                                                    helperText={
                                                        ((!checked &&
                                                            email?.length >
                                                                2) ||
                                                            (!checked &&
                                                                errors?.Email)) &&
                                                        'Insira um formato de email válido'
                                                    }
                                                    {...register('Email', {
                                                        required: !checked,
                                                        pattern: /^\S+@\S+$/i,
                                                    })}
                                                />
                                            }
                                            label={
                                                <TypographyOptions variant='h5'>
                                                    E-mail de quem assina
                                                </TypographyOptions>
                                            }
                                        />
                                    </Box>

                                    <FormControlLabel
                                        sx={{
                                            marginTop: '4px',
                                            alignSelf: 'start',
                                        }}
                                        control={
                                            <Checkbox
                                                size='small'
                                                onClick={handleUserSubscribes}
                                                checked={checked}
                                                // onChange={handleUserSubscribes}
                                                name='pulverizado'
                                            />
                                        }
                                        label={
                                            <TypographyOptions variant='body2'>
                                                Sou eu quem assina
                                            </TypographyOptions>
                                        }
                                    />
                                    <DropzoneComponent
                                        fileType='.pdf'
                                        description='Arraste um PDF de até 40MB com a prova de poder de assinatura ou'
                                        setHasFile={setHasFile}
                                        hasFile={hasFile}
                                        uploadedFile={uploadedFile}
                                        setUploadedFile={setUploadedFile}
                                        setFilesNda={setFilesNda}
                                        uploadPath='confidencialidades'
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '12px',
                                            marginTop: '12px',
                                        }}
                                    >
                                        {/* <Button
                                    variant='outlined'
                                    color='primary'
                                    sx={{
                                        width: '180px',
                                        height: '48px',
                                        marginTop: '8px',
                                    }}
                                    startIcon={<ArrowBackIosIcon />}
                                    onClick={() =>
                                        setIsUserLinkedToCompany(false)
                                    }
                                >
                                    <Typography variant='h5'>
                                        Passo anterior
                                    </Typography>
                                </Button> */}
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                                width: '300px',
                                                height: '48px',
                                                marginTop: '8px',
                                            }}
                                            type='submit'
                                            disabled={!uploadedFile}
                                        >
                                            <Typography variant='h5'>
                                                Enviar detalhes de quem assina
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </ContainerBox>
            </FormBox>
        </form>
    );
    // : (
    //     <CompanyLinkage
    //         companyAlreadyRegistered={companyAlreadyRegistered}
    //         setCompanyAlreadyRegistered={setCompanyAlreadyRegistered}
    //     />
    // );
}
