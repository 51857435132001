import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    margin: '20px 0px'
});

const NumberBox = styled(Box)({
    display: 'flex',
    padding: '2px',
    width: '5%',
    height: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2px 14px 10px 14px',
    background: '#6481EB',
    borderRadius: '2px',
});

export default function RelevantPoints({ number, title }) {
    let conteudo;

    switch (number) {
        case '01':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>Estruturar o ativo e a transação.</li>
                    <li>
                        Desenhar um processo eficiente, estruturado e
                        competitivo, que permita aos investidores realizar uma
                        análise apropriada dos ativos à venda e apresentar as
                        melhores ofertas.
                    </li>
                </ul>
            );
            break;

        case '02':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Analisar e segmentar a carteira segundo tipologia e
                        características dos ativos.
                    </li>
                    <li>
                        Realizar testes antecipados para valorização dos
                        créditos de acordo com metodologia de mercado.
                    </li>
                </ul>
            );
            break;

        case '03':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Prover informação de qualidade para que os compradores
                        possam avaliar adequadamente o ativo.
                    </li>
                    <li>
                        Evitar, desta maneira, a introdução de cláusulas
                        indenizatórias adicionais ao contrato de cessão e/ou
                        impactos negativos nas ofertas.
                    </li>
                </ul>
            );
            break;

        case '04':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Entendimento das principais características do ativo ou
                        da carteira e eventual clusterização / fatiamento para
                        aumentar a atratividade e as ofertas.
                    </li>
                    <li>
                        Definição clara do tipo de comprador e fit com o ativo.
                    </li>
                </ul>
            );
            break;
        case '05':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Realizar uma verificação prévia das informações e
                        identificar possíveis problemas.
                    </li>
                    <li>
                        Analisar o ambiente jurídico para identificar problemas
                        que poderiam dificultar ou mesmo inviabilizar a
                        transação.
                    </li>
                    <li>Comunicar possíveis problemas aos compradores.</li>
                </ul>
            );
            break;
        case '06':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Entender o impacto contábil/fiscal da venda, a partir da
                        quantificação do impacto no resultado e no balanço.
                        Avaliar se existe necessidade de comunicação antecipada
                        com os reguladores e de apresentar esclarecimentos sobre
                        possíveis obstáculos.
                    </li>
                </ul>
            );
            break;
        case '07':
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Realizar procedimentos mínimos para qualificação dos
                        investidores que poderão participar do processo de venda
                        (recursos disponíveis; track record; veículo para
                        aquisição dos créditos; procedimentos de prevenção à
                        lavagem de dinheiro; entre outros).
                    </li>
                </ul>
            );
            break;

        default:
            conteudo = (
                <ul style={{ color: '#666666', fontSize: '12px' }}>
                    <li>
                        Entender qual seria a estrutura mais apropriada (venda
                        direta, profit sharing etc.) para cada transação.
                    </li>
                    <li>
                        Muitas transações fracassam devido à utilização de
                        estrutura inadequada.
                    </li>
                    <li>
                        Necessidade de time dedicado e processos claros para a
                        entrega de documentos relacionados ao ativo.
                    </li>
                </ul>
            );

        // border = colors.facePerson.borderColor;
    }

    return (
        <BoxContainer>
            <NumberBox>
                <Typography
                    sx={{ color: '#FFF' }}
                    variant='h1'
                >
                    {number}
                </Typography>
            </NumberBox>
            <Box sx={{width: '95%'}}>
                <Typography
                    sx={{
                        color: '#1E49E2',
                        fontWeight: 'bold',
                    }}
                    variant='body2'
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: '#666666',
                        fontWeight: 'bold',
                    }}
                    variant='subtitle1'
                >
                    Recomendações
                </Typography>
                <Box sx={{ margin: '0px 15px' }}>{conteudo}</Box>
            </Box>
        </BoxContainer>
    );
}
