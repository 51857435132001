import {
    Box,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    minHeight: '420px',
}));
const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '0 auto',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
}));
export default function Step2({ setResultAssessment, resultAssessment }) {
    const [text, setText] = useState(resultAssessment.step2 || '');
    const [textError, setTextError] = useState(false);

    const handleSetText = (e) => {
        setText(e);
        if (e?.length < 3) {
            setTextError(true);
            return;
        }
        setTextError(false);
    };

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: text,
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: resultAssessment?.step5,
            step6: resultAssessment?.step6,
            step7: resultAssessment?.step7,
        });
    }, [text]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/DoisBranco.svg' alt='Dois' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '20px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Descreva as principais características de seu ativo.{' '}
                    <spam style={{ color: '#D10000', fontSize: '16px' }}>
                        *
                    </spam>
                </Typography>
                <Typography
                    variant='subtitle1'
                    sx={{
                        textAlign: 'center',
                        margin: '0px 80px 0 80px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    <span style={{ fontWeight: 'bold' }}>Importante: </span>
                    não prover informações que possam vir a identificar o ativo,
                    credores ou mesmo devedores. Esta informação será publicada
                    a compradores.
                </Typography>
                <Typography
                    variant='subtitle1'
                    sx={{
                        textAlign: 'center',
                        margin: '10px 80px 0 80px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    <span style={{ fontWeight: 'bold' }}>
                        O que incluir aqui:{' '}
                    </span>
                    Como o crédito/ativo foi originado? Qual o tamanho do
                    ativo/carteira/crédito?
                </Typography>
            </Box>
            <FormControlLabelStyle
                control={
                    <TextField
                        placeholder='Escreva aqui de três a mil caracteres.'
                        variant='outlined'
                        minRows={5}
                        maxRows={5}
                        inputProps={{ maxLength: 1000 }}
                        multiline
                        sx={{
                            marginTop: '4vh',
                            width: '70vw',
                            height: '15vh',
                        }}
                        error={textError}
                        defaultValue={text}
                        onChange={(e) => {
                            handleSetText(e.target.value);
                        }}
                    />
                }
            />
        </FormBox>
    );
}
