/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-fallthrough */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingScreen from 'components/LoadingScreen';
import { UserContext } from 'context/UserContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import ComplementaryData from './ComplementaryData';
import TermsOfUse from './TermsOfUse';

const StepperBuyer = styled(Stepper)(() => ({
    border: 0,
    color: '#666666',
    background: '#FFFFFF',
    height: '55px',
    margin: '32px 80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 30px',
    borderRadius: '24px',
}));

const StepCustom = styled(Step)(() => ({
    '&. MuiStepConnector-line': {
        display: 'none',
    },
    '&. MuiStepConnector-lineVertical': {
        display: 'none',
    },
    '&. MuiStepConnector-icon': {
        display: 'block',
    },
}));

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
    color: '#fff',
    width: 16,
    height: 16,
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: '#1E49E2',
    }),
    ...(ownerState.completed && {
        background: '#1E49E2',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed } = props;

    const icons = {
        1: (
            <img
                src={
                    completed || active
                        ? '/static/image/UmBranco.svg'
                        : '/static/image/Um.svg'
                }
                alt='Um'
            />
        ),
        2: (
            <img
                src={
                    completed || active
                        ? '/static/image/DoisBranco.svg'
                        : '/static/image/Dois.svg'
                }
                alt='Dois'
            />
        ),
        3: (
            <img
                src={
                    completed || active
                        ? '/static/image/TresBranco.svg'
                        : '/static/image/Tres.svg'
                }
                alt='Tres'
            />
        ),
        4: (
            <img
                src={
                    completed || active
                        ? '/static/image/QuatroBranco.svg'
                        : '/static/image/Quatro.svg'
                }
                alt='Quatro'
            />
        ),
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const BoxTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGrayDark,
}));

const steps = ['Dados complementares', 'Termo de Ciência'];

export default function StepperLoginSeller() {
    const navigate = useNavigate();
    const { getUsers } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorCPF, setErrorCPF] = useState(false);
    const [CPF, setCPF] = useState('');
    const [position, setPosition] = useState('');
    const [cellNumber, setCellNumber] = useState('');
    const [commercialPhone, setCommercialPhone] = useState('');
    const [isUserAcceptedTermsOfUse, setIsUserAcceptedTermsOfUse] =
        useState(false);
    const [isUserAcceptedNotifications, setIsUserAcceptedNotifications] =
        useState(true);

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCompleteRegistration = async () => {
        try {

            if(cellNumber?.length !== 19) {
                toast.error("Telefone celular não pode ser vazio / número inválido");
                return
            }

            setIsLoading(true);
            await api.post('/api/user/create/seller', {
                cpf: CPF,
                position,
                phone: {
                    cell: cellNumber,
                    commercial: commercialPhone,
                },
                isUserAcceptedTermsOfUse,
            });
            navigate('/seller/active');
            getUsers();
            toast.success('Cadastro concluido.');
        } catch (error) {
            toast.error(error.response.data.message || error.message || error);
            setIsUserAcceptedTermsOfUse(false);
            setIsUserAcceptedNotifications(true);
        } finally {
            setIsLoading(false);
        }
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <ComplementaryData
                        CPF={CPF}
                        setCPF={setCPF}
                        setErrorCPF={setErrorCPF}
                        position={position}
                        setPosition={setPosition}
                        cellNumber={cellNumber}
                        setCellNumber={setCellNumber}
                        setCommercialPhone={setCommercialPhone}
                        commercialPhone={commercialPhone}
                    />
                );

            case 1:
                return (
                    <TermsOfUse
                        isUserAcceptedTermsOfUse={isUserAcceptedTermsOfUse}
                        setIsUserAcceptedTermsOfUse={
                            setIsUserAcceptedTermsOfUse
                        }
                        isUserAcceptedNotifications={
                            isUserAcceptedNotifications
                        }
                        setIsUserAcceptedNotifications={
                            setIsUserAcceptedNotifications
                        }
                    />
                );
            default:
                return '';
        }
    }

    const conditions = errorCPF || cellNumber?.length !== 19;

    return isLoading ? (
        <LoadingScreen />
    ) : (
        <Box sx={{ width: '100%', maxWidth: '1380px', margin: '0 auto' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '32px 80px',
                }}
            >
                <BoxTitle>
                    <img
                        src='/static/image/CriarConta.svg'
                        alt='Cadeado - Criar conta'
                    />
                    <TypographyTitle variant='h1'>Criar conta</TypographyTitle>
                </BoxTitle>
                <Button
                    variant='text'
                    color='primary'
                    endIcon={<CloseRoundedIcon />}
                    onClick={() => (window.location.href = '/signout')}
                >
                    Fechar
                </Button>
            </Box>
            <StepperBuyer
                activeStep={activeStep}
                connector={
                    <ArrowForwardIosRoundedIcon sx={{ width: '12px' }} />
                }
            >
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <StepCustom key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={ColorlibStepIcon}
                            >
                                {label}
                            </StepLabel>
                        </StepCustom>
                    );
                })}
            </StepperBuyer>
            {getStepContent(activeStep)}
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px 80px',
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    sx={{ background: '#FFFFFF' }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<ArrowBackIosRoundedIcon />}
                >
                    Passo anterior
                </Button>
                {activeStep === steps.length - 1 ? (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleCompleteRegistration}
                        endIcon={<CheckRoundedIcon />}
                        disabled={
                            !isUserAcceptedTermsOfUse ||
                            !isUserAcceptedNotifications
                        }
                    >
                        Concluir cadastro
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                        disabled={conditions}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                        Próximo passo
                    </Button>
                )}
            </Box>
        </Box>
    );
}
