import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const IndicatorBox = styled(Box)({
    display: 'flex',
    padding: '2px',
    width: '140px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 14px',
    height: '100px',
    border: '1px #D4D4D4 solid',
    borderRadius: '8px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2);',
});

export default function ActiveTypeCards({ text, color }) {
   
    return (
        <IndicatorBox>
            {text === 'Leasing' || text === 'Autoloan' ? (
                <Typography
                    color={color}
                    sx={{ textAlign: 'center', marginBottom: '8px' }}
                    variant='subtitle2'
                >
                    <i>{text}</i>
                </Typography>
            ) : (
                <Typography
                    color={color}
                    sx={{ textAlign: 'center', marginBottom: '8px' }}
                    variant='subtitle2'
                >
                    {text}
                </Typography>
            )}
        </IndicatorBox>
    );
}
