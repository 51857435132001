/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import fileDownload from 'js-file-download';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';

const Container = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px auto',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    height: '600px',
    '& .MuiFormHelperText-root': { height: '0px' },
}));
const ContainerBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '600px',
    gap: '48px',
}));

export default function RegisterWallet() {
    const responseAccessRequest = useLocation();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [downloading, setDownloading] = useState(false);

    async function handleDownloadTemplate() {
        const id = responseAccessRequest.state.data.message.idActiveList;
        try {
            setDownloading(true);
            const res = await api.get(`/api/assessment/download/${id}`, {
                params: {
                    cacheBustTimestamp: Date.now(),
                },
                responseType: 'blob',
                headers: {
                    Accept: 'application/octet-stream',
                },
            });
            if (!(res.data instanceof Blob)) return;

            fileDownload(
                res.data,
                `2023 - KPMG Ativos Alternativos - ${res.headers['file-name']}.xlsx`
            );
            navigate('/seller/send/email');
        } catch (error) {
            toast.error(
                'Erro ao fazer o download do template. Tente novamente mais tarde.'
            );
        } finally {
            setDownloading(false);
        }
    }

    return (
        <Container>
            <Button
                sx={{ alignSelf: 'end', margin: '0 80px 80px 0' }}
                variant='text'
                color='primary'
                endIcon={<CloseRoundedIcon />}
                onClick={() => navigate('/seller/active')}
            >
                Fechar
            </Button>
            <ContainerBox>
                <Typography
                    variant='h1'
                    align='center'
                    paddingTop='12px'
                    sx={{
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    Solicitação de cadastro de ativo
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ textAlign: 'center', marginBottom: '5px' }}
                >
                    A segunda etapa do processo de anúncio é o<i> download</i>{' '}
                    do <i>template</i> para o seu tipo de ativo. Em seguida,
                    será necessário preencher as informações solicitadas.
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ textAlign: 'center', marginBottom: '30px' }}
                >
                    Realize o<i> download</i> pelo botão abaixo:
                </Typography>

                <Button
                    variant='contained'
                    color='primary'
                    sx={{
                        width: '240px',
                        height: '48px',
                    }}
                    endIcon={
                        <img
                            src='/static/image/DownloadBranco.svg'
                            width={18}
                            height={18}
                            alt='Download'
                            style={{ marginLeft: '4px' }}
                        />
                    }
                    onClick={handleDownloadTemplate}
                    disabled={downloading}
                >
                    <Typography variant='h5'>Download do template</Typography>
                </Button>
            </ContainerBox>
        </Container>
    );
}
