import LoadingScreen from 'components/LoadingScreen';
import { UserContext } from 'context/UserContext';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function AuthRoute() {
    const { user, loading } = useContext(UserContext);
    const location = useLocation();

    if (!loading) return <LoadingScreen />;

    if (user?.group) {
        if (user?.group.toLowerCase() === 'vendedor') {
            if (location.pathname.includes('seller')) {
                return <Outlet />;
            }
        }
        if (user?.group.toLowerCase() === 'comprador') {
            if (location.pathname.includes('buyer')) {
                return <Outlet />;
            }
        }
        return window.history.go(-1);
    }

    return <Navigate to='/' state={{ from: location }} replace />;
}
