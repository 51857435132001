/* eslint-disable no-unused-vars */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

export default function ModalForm({
    isOpenModal,
    setIsOpenModal,
    sellerOrBuyer,
}) {
    return (
        <Dialog
            onClose={() => setIsOpenModal(false)}
            open={isOpenModal}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                }}
            >
                <Button
                    variant='text'
                    color='primary'
                    endIcon={<CloseRoundedIcon />}
                    onClick={() => setIsOpenModal(false)}
                >
                    Fechar
                </Button>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '1000px',
                }}
            >
                <iframe
                    style={{
                        width: '100%',
                        minWidth: '1000px',
                        height: '100%',
                        border: '0',
                    }}
                    // PRODUÇÃO
                    src={sellerOrBuyer === 'seller' ? 'https://app.pipefy.com/public/form/A02WQCJp' : 'https://app.pipefy.com/public/form/P1tTB2W2'}
                    // DEVELOP
                    // src={sellerOrBuyer === 'seller' ? 'https://app.pipefy.com/public/form/Ym2hY3iz' : 'https://app.pipefy.com/public/form/HK-T9hrN'}
                    title='description'
                />
            </DialogContent>
        </Dialog>
    );
}
