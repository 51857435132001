import { Box } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(...registerables);

export default function VerticalBarGraphics({
    labels,
    data,
    values,
    widthGraphics = '500px',
}) {
    const dataInfo = {
        labels,
        datasets: [
            {
                label: '',
                data,
                minBarThickness: 8,
                minBarLength: 2,
                backgroundColor: [
                    'rgba(96, 13, 180, 1)',
                    'rgba(18, 143, 121, 1)',
                ],
                borderColor: [
                    'rgba(101, 216, 174, 1)',
                    'rgba(197, 144, 229, 1)',
                    'rgba(220, 145, 121, 1)',
                    'rgba(100, 129, 235, 1)',
                    'rgba(236, 223, 112, 1)',
                    'rgba(115, 214, 220, 1)',
                    'rgba(143, 215, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: 'x',
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'R$',
                },
            },
            x: {
                title: {
                    display: widthGraphics !== "500px",
                    text: 'ANO',
                },
            },
        },
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                values,
            },
            legend: {
                position: 'bottom',
                display: false,
            },
        },
    };

    return (
        <Box sx={{ width: widthGraphics, height: '260px', padding: '10px' }}>
            <Bar data={dataInfo} options={options} />
        </Box>
    );
}
