/* eslint-disable no-unused-vars */

/* eslint-disable no-underscore-dangle */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import ModalAcceptUser from 'components/ModalAcceptUser';
import { RowsContext } from 'context/RowsContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'themes';
import DataTable from '../../components/Table';

export default function UserData() {
    const rowsPerPage = 5;
    const {
        rowsUsers,
        setRowsUsers,
        oldRowsUser,
        setOldRowsUser,
        getRowsUsers,
    } = useContext(RowsContext);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [type, setType] = useState('');
    const [userClicked, setUserClicked] = useState({});
    const [filterInput, setFilterInput] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const location = useLocation();

    const handleRefused = (user) => {
        setUserClicked(user._id);
        setIsOpenModal(true);
        setType('refused');
    };

    const handleDelete = (user) => {
        setUserClicked(user._id);
        setIsOpenModal(true);
        setType('delete');
    };

    const handleSubmit = () => {
        const filter = Object.values(oldRowsUser).filter(
            (item) =>
                item?.name
                    ?.toLowerCase()
                    ?.includes(filterInput.toLowerCase()) ||
                item?.email?.toLowerCase()?.includes(filterInput.toLowerCase())
        );
        setRowsUsers(filter);
        setSubmitted(false);
    };

    useEffect(() => {
        if (submitted) {
            handleSubmit();
        }
    }, [submitted]);

    useEffect(() => {
        if (!filterInput) {
            getRowsUsers();
            // setRowsUsers(oldRowsUser);
        }
    }, [filterInput]);

    const columns = [
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: 260,
        },
        {
            field: 'email',
            headerName: 'E-mail',
            minWidth: 300,
        },
        {
            field: 'createdAt',
            headerName: 'Data de entrada',
            minWidth: 240,
        },
        {
            field: 'company',
            headerName: 'Ações',
            sortable: false,
            minWidth: 120,
            renderCell: () => (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Tooltip
                            title='Para remover um usuário, entre em contato pelo e-mail br-fmcontatopaa@kpmg.com.br.'
                            placement='top'
                        >
                            <IconButton
                                size='small'
                                sx={{
                                    marginLeft: '4px',
                                    color: theme.palette.primary.main,
                                }}
                            >
                                <InfoOutlinedIcon fontSize='10px' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
        },
    ];

    return (
        <>
            <ModalAcceptUser
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                type={type}
                userClicked={userClicked}
            />

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        margin: '10px 30px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '15px',
                    }}
                >
                    <TextField
                        label='Pesquisar usuário por nome ou e-mail'
                        size='small'
                        onChange={(event) => setFilterInput(event.target.value)}
                        placeholder='Digite o nome ou e-mail do usuário'
                        sx={{ width: '500px', background: '#FFFFFF' }}
                    />
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setSubmitted(true)}
                        endIcon={<SearchRoundedIcon />}
                        sx={{ background: '#FFFFFF' }}
                    >
                        Buscar
                    </Button>
                </Box>

                <Box sx={{ width: '96%', marginBottom: '50px' }}>
                    <DataTable
                        rows={rowsUsers}
                        columns={columns}
                        rowsPerPage={rowsPerPage}
                    />
                </Box>
            </Box>
        </>
    );
}
