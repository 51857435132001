import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';

const IndicatorBox = styled(Box)({
    display: 'flex',
    padding: '4px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px',
    height: '120px',
    border: '1px #D4D4D4 solid',
    borderRadius: '8px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2);',
});

export default function IndicatorCard({ title, value, position }) {
    const [widthBox, setWidthBox] = useState('first');

    useEffect(() => {
        switch (position) {
            case 'first':
                setWidthBox('90%');
                break;

            case 'second':
                setWidthBox('80%');
                break;

            case 'third':
                setWidthBox('70%');
                break;

            case 'fourth':
                setWidthBox('60%');
                break;

            case 'fifth':
                setWidthBox('55%');
                break;
            // case 'sixth':
            //     setWidthBox('45%');
            //     break;

            default:
                setWidthBox('100&');
        }
    }, []);

    return (
        <IndicatorBox sx={{ width: widthBox }}>
            <Typography
                sx={{ textAlign: 'center', marginBottom: '8px' }}
                variant='body2'
            >
                {title}
            </Typography>
            <Typography color={theme.palette.primary.main} variant='h2'>
                {value}
            </Typography>
        </IndicatorBox>
    );
}
