/* eslint-disable no-unused-vars */
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RowsContext } from 'context/RowsContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { theme } from 'themes';

const BoxContainer = styled(Box)((props) => ({
    width: '800px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '24px',
    background: props.theme.palette.themePrimary.primaryWhiteLight,
}));

const BoxTitle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    gap: '25px',
});

export default function ModalAcceptUser({
    isOpenModal,
    setIsOpenModal,
    type,
    userClicked,
}) {
    const { getRowsUsers } = useContext(RowsContext);

    const handleRefused = async (typeSubject) => {
        try {
            await api.delete(
                `/api/user/delete/company/${userClicked}/${typeSubject}`
            );
            getRowsUsers();
            if (type === 'delete') {
                toast.success('Usuário removido com sucesso!');
            } else {
                toast.success('Usuário recusado com sucesso!');
            }
        } catch (error) {
            toast.error('Ocorreu um problema ao recusar o usuário');
        }
        setIsOpenModal(false);
    };

    return (
        <Dialog
            onClose={() => setIsOpenModal(false)}
            open={isOpenModal}
            fullWidth
            maxWidth='sm'
        >
            <DialogContent
                sx={{
                    height: '250px',
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <BoxContainer>
                    <BoxTitle>
                        {type === 'delete' ? (
                            <Typography
                                variant='h1'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Excluir usuário
                            </Typography>
                        ) : (
                            <Typography
                                variant='h1'
                                sx={{
                                    color: theme.palette.themePrimary
                                        .primaryGrayDark,
                                }}
                            >
                                Aceite de usuário
                            </Typography>
                        )}
                    </BoxTitle>

                    <Box
                        sx={{
                            textAlign: 'center',
                            margin: '30px auto',
                        }}
                    >
                        {type === 'refused' && (
                            <Typography variant='body1'>
                                Você tem certeza que deseja recusar esse
                                usuário?
                            </Typography>
                        )}
                        {type === 'delete' && (
                            <Typography variant='body1'>
                                Você tem certeza que deseja excluir esse
                                usuário?
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setIsOpenModal(false)}
                            sx={{ margin: '0 5px' }}
                        >
                            Cancelar
                        </Button>
                        {type === 'refused' && (
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{ margin: '0 5px' }}
                                onClick={() =>
                                    handleRefused('DECLINED_USER_COMPANY')
                                }
                            >
                                Sim, recusar
                            </Button>
                        )}
                        {type === 'delete' && (
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{ margin: '0 5px' }}
                                onClick={() =>
                                    handleRefused('REMOVED_USER_COMPANY')
                                }
                            >
                                Sim, excluir
                            </Button>
                        )}
                    </Box>
                </BoxContainer>
            </DialogContent>
        </Dialog>
    );
}
