/* eslint-disable import/prefer-default-export */
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1E49E2',
        },
        themePrimary: {
            primaryBlack: '#112238',
            primaryGrayDark: '#595959',
            primaryGray: '#666666',
            primaryGrayLight: '#C4C4C4',
            primaryWhite: '#F9F9F9',
            primaryWhiteLight: '#FFFFFF',
        },
        themeSecondary: {
            secondaryGreen: '#04B600',
            secondaryRedLight: '#D10000',
            secondaryRed: '#DA3A31',
            secondaryYelow: '#FFC700',
            secondaryPurple: '#8000FF',
            secondaryBlue: '#00D1FF',
            secondaryGreenLight: '#00FFC2',
        },
        themeTertiary: {
            tertiaryBlue: '#113488',
            tertiaryPurple: '#6727E1',
            tertiaryGreen: '#56BDAD',
            tertiaryBlueLight: '#8CD0FA',
        },
    },

    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'none',
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-footerContainer': {
                        borderRadius: 0,
                    },
                },
            },
        },
        /* MuiCheckbox: {
            styleOverrides: {
                root: {
                    svg: {
                        path: {
                            fill: '#1477F8',
                            borderRadius: '100% !important',
                        },
                    },
                },
            },
        }, */
        MuiCssBaseline: {
            '@global': {
                /* '@font-face': {
                    fontFamily: 'Open Sans',
                    src: `url(''static/font/OpenSans-Regular.woff2'') format('woff2');`,
                }, */
                body: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
    },

    typography: {
        fontFamily: 'Open Sans, sans-serif',
        h1: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '700',
            fontSize: '32px',
        },
        h2: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '700',
            fontSize: '24px',
        },
        h3: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '500',
            fontSize: '20px',
        },
        h4: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '500',
            fontSize: '18px',
        },
        h5: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '700',
            fontSize: '14px',
        },
        body1: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '400',
            fontSize: '16px',
            fontStyle: 'normal',
        },
        body2: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '400',
            fontSize: '14px',
        },
        subtitle1: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '400',
            fontSize: '12px',
        },
        subtitle2: {
            fontFamily: 'Open Sans, sans-serif',

            fontWeight: '400',
            fontSize: '11px',
        },
    },
});
