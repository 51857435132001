/* eslint-disable camelcase */
import { Box, Typography } from '@mui/material';
import DetailsActiveTable from 'components/DetailsActiveTable';
import PieGraphics from 'components/PieGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';

const DistributionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

const GrapichPieBox = styled(Box)({
    width: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});

export default function Template3and7and8and10({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsSale, setValuesGraphicsSale] = useState({});

    useEffect(() => {
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = { ...valuesGraphics, [key]: valueKey };
        });

        setValuesGraphicsSale(valuesGraphics);
    }, [walletDetails]);

    return (
        <DistributionBox>
            <Title>Distribuição de Saldo em Aberto</Title>
            <Box
                sx={{
                    width: '100%',
                    height: '240px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                }}
            >
                <GrapichPieBox>
                    <PieGraphics
                        labels={valuesGraphicsSale?.['Composição de Saldo']}
                        data={valuesGraphicsSale?.Valores}
                        content='outstandingBalance'
                    />
                </GrapichPieBox>
                <DetailsActiveTable
                    rows={formatRows(
                        walletDetails?.calculationsWallet?.[0]?.[
                        'Distribuição de Saldo em Aberto'
                        ], walletDetails?.calculationsWallet?.[0]?.Moeda
                    )}
                    columns={formatColumns(
                        walletDetails?.calculationsWallet?.[0]?.[
                        'Distribuição de Saldo em Aberto'
                        ]
                    )}
                    sx={{ height: '200px' }}
                />
            </Box>
        </DistributionBox>
    );
}
