import { Box } from '@mui/material';
import AsideCard from 'components/AsideCard';

export default function Template13({ walletDetails }) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard title='Ente Público' content={walletDetails?.calculationsWallet[0]?.['Ente Público']} lg />
                <AsideCard
                    title='Data do Último Andamento'
                    content={walletDetails?.calculationsWallet[0]?.['Data do Último Andamento']}
                    lg
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Fase Processual'
                    content={walletDetails?.calculationsWallet[0]?.['Fase Processual']}
                    lg
                />
                <AsideCard
                    title='Data de Último Vencimentos'
                    content={walletDetails?.calculationsWallet[0]?.['Data de Último Vencimentos']}
                    lg
                />
            </Box>
        </>
    );
}
