import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

const StyledDataGrid = styled(DataGrid)(() => ({
    border: 0,
    color: '#666666',
    cursor: 'default',

    '&.MuiDataGrid-root': {
        border: 'none',
        maxWidth: '1300px',
        overflow: 'hidden',
    },
    '& .MuiDataGrid-main': {
        width: '100%',
    },

    '& .MuiDataGrid-columnHeaderTitleContainer': {
        cursor: 'default',
    },

    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },

    '& .MuiDataGrid-iconButtonContainer': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeaders': {
        background: '#F0F0F0',
    },
    '& .MuiDataGrid-columnHeader': {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 16px',
        width: '100%',
        fontWeight: '600',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        color: '#666666',
    },
    '& .MuiDataGrid-row': {
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        border: 0,
        textAlign: 'center',
        width: '100%',

        ' &:last-child': {
            background: '#F0F0F0',
            fontWeight: '600',
            border: '1px solid #F9F9F9',
            borderRadius: '4px',
        },
    },

    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-columnHeaders, .MuiDataGrid-cell':
        {
            border: 0,
        },
    '& .MuiDataGrid-cell': {
        color: '#666666',
    },
    '& .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
        {
            outline: 0,
        },
}));

export default function DetailsActiveTable({ rows, columns }) {
    return (
        <div
            style={{
                width: '100%',
                margin: 'auto 0',
                marginLeft: '20px',
                background: '#FFF',
                borderRadius: '4px',
            }}
        >
            <StyledDataGrid
                autoHeight
                disableSelectionOnClick
                disableColumnMenu
                hideFooter
                rows={rows}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[7]}
                onColumnHeaderClick={(e) => e.preventDefault()}
            />
        </div>
    );
}
