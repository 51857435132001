/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const BoxContainer = styled(Box)((props) => ({
    width: '800px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '24px',
    background: props.theme.palette.themePrimary.primaryWhiteLight,
}));

const BoxTitle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    gap: '22px',
    marginTop: '45px',
});

const TypographyError = styled(Typography)((props) => ({
    color: props.theme.palette.themeSecondary.secondaryRed,
}));

const ButtonClosed = styled(Button)((props) => ({
    background: props.theme.palette.themeSecondary.secondaryRed,
    '&:hover': {
        background: '#c20606',
    },
}));

export default function NotFound({ error }) {
    const navigate = useNavigate();

    let errorCapture = error;

    const goBack = () => {
        if (error) {
            errorCapture = '';
        }
        navigate(-1);
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <BoxContainer>
                <BoxTitle error={errorCapture}>
                    <img src='/static/image/Alerta.svg' alt='Alerta' />
                    <TypographyError variant='h1'>
                        {errorCapture
                            ? 'Ops... aconteceu algo!'
                            : '404 - Página não encontrada!'}
                    </TypographyError>
                </BoxTitle>
                <Typography variant='body1' sx={{ margin: '48px 0' }}>
                    {errorCapture
                        ? 'Desculpe pelo ocorrido. Volte para a página anterior!'
                        : 'Página não existente, por favor retorne para o inicio!'}
                </Typography>
                <ButtonClosed variant='contained' onClick={() => goBack()}>
                    Voltar
                </ButtonClosed>
            </BoxContainer>
        </Box>
    );
}
