/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'themes';

const Container = styled(Box)({
    height: '95px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#FFF',
});

const Title = styled(Typography)({
    fontSize: '16px',
    alignSelf: 'center',
    color: theme.palette.primary.main,
});

const ContentBox = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    gap: '8px',
});

export default function AsideCard({
    title,
    content,
    icon,
    lg,
    templateNumber,
}) {
    const conditional = ['1', '2', '11', '12', '13'].includes(templateNumber)
        ? 'valor original'
        : 'saldo em aberto';

    const labels = [
        {
            'Total Valor Original': 'Somatória do Valor Original.',
        },
        {
            'Quantidade de Documentos':
                'Quantidade de documentos contidos nesse ativo.',
        },
        {
            'Ticket Médio por Documento': `Somatória do ${conditional} dividido pela quantidade de documentos referente ao ${conditional}`,
        },
        {
            'Quantidade de Devedores':
                'Quantidade de CPFs e/ou CNPJs envolvidos neste ativo.',
        },
        {
            'Ticket Médio por Devedor': `Somatória do ${conditional} dividido pela quantidade devedores (CPFs e CNPJs envolvidos no ${conditional})`,
        },
        {
            'Total Saldo em Aberto': 'Somatória do Saldo em Aberto.',
        },
        {
            'Total Valor Atualizado': 'Somatória do Valor Atualizado.',
        },
        {
            Comarca: 'Cidade onde o processo está tramitanto',
        },
        {
            'Valor Total Original da Causa':
                'Somatória do Valor Original da Causa',
        },
        {
            'Valor Total Atualizado da Causa': 'Somatória do Valor Atualizado',
        },
        {
            'Ente Público': 'Órgão',
        },
        {
            'Garantia(as)': 'Tipo de garantia',
        },
        {
            'Data da Distribuição do Processo':
                'Data em que a petição inicial foi protocolada',
        },
        {
            'Fase Processual':
                'Fase de conhecimento, fase recursal, em cumprimento de sentença, etc.',
        },
        {
            'Data do Último Andamento': 'Data do último andamento do processo',
        },
        {
            'Valor da(as) Garantia(as)': '',
        },
        {
            'Data de Último Vencimentos':
                'Data do vencimento final do precatório',
        },
    ];

    const tooltipLabel = labels.find((label) => {
        const key = Object.keys(label);
        const isLabelTrue = key[0] === title;
        if (title && isLabelTrue) {
            return label[title];
        }
        return null;
    });

    return (
        <Container sx={{ width: lg ? '49%' : '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title>{title}</Title>
                {templateNumber !== '12' && (
                    <Tooltip
                        title={
                            title !== '' && tooltipLabel
                                ? tooltipLabel[title]
                                : '-'
                        }
                        placement='top'
                    >
                        <IconButton
                            size='small'
                            sx={{
                                marginLeft: '4px',
                                color: theme.palette.primary.main,
                            }}
                        >
                            <InfoOutlinedIcon fontSize='10px' />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>

            <ContentBox>
                <Typography
                    variant='h3'
                    sx={{ fontSize: '32px', color: '#595959' }}
                >
                    {[
                        'Valor Original',
                        'Saldo em Aberto',
                        'Total Valor Original',
                        'Total Saldo em Aberto',
                        'Total Valor Atualizado',
                        'Valor da(as) Garantia(as)',
                        'Valor Total Atualizado da Causa',
                        'Valor Total Original da Causa',
                        'Total Valor do Crédito Tributário',
                    ].includes(title)
                        ? `${content}`
                        : content}
                </Typography>
                {icon && <img src={icon} alt='icon' width='28' height='23' />}
            </ContentBox>
        </Container>
    );
}
