import { Box } from '@mui/material';
import AsideCard from 'components/AsideCard';

export default function Template12({ walletDetails }) {
    return walletDetails?.calculationsWallet.length > 0 && (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                {/* <AsideCard
                title='Índice KPMG Watch'
                content={
                    <Box
                        sx={{
                            width: '1000px',
                            borderRadius: '2px',
                            background:
                                'linear-gradient(90deg, #F17B0E 0%, #1E49E2 51.56%, #11E34C 100%)',
                        }}
                    >
                        <Box
                            sx={{
                                width: '58px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#180848',
                                borderRadius: '18px',
                                marginLeft: '250px',
                            }}
                        >
                            <Typography variant='body2' color='#FFFFFF'>
                                6.7
                            </Typography>
                        </Box>
                    </Box>
                }
            /> */}
                <Box
                // sx={{
                //     marginTop: '20px',
                // }}
                >
                    <AsideCard title='Comarca' content={walletDetails?.calculationsWallet[0].Comarca} templateNumber={`${walletDetails?.template?.number}`} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Valor Total Original da Causa'
                    content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Valor Total Original da Causa']}`}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
                <AsideCard
                    title='Data da Distribuição do Processo'
                    content={walletDetails?.calculationsWallet[0]?.['Data da Distribuição do Processo']}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Valor Total Atualizado da Causa'
                    content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Valor Total Atualizado da Causa']}`}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
                <AsideCard title='Fase Processual' content={walletDetails?.calculationsWallet[0]?.['Fase Processual']} lg templateNumber={`${walletDetails?.template?.number}`} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard title='Ente Público' content={walletDetails?.calculationsWallet[0]?.['Ente Público']} lg templateNumber={`${walletDetails?.template?.number}`} />
                <AsideCard
                    title='Data do Último Andamento'
                    content={walletDetails?.calculationsWallet[0]?.['Data do Último Andamento']}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}
            >
                <AsideCard
                    title='Garantia(s)'
                    content={walletDetails?.calculationsWallet[0]?.['Garantia(as)']}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
                <AsideCard
                    title='Valor da(s) Garantia(s)'
                    content={`${walletDetails?.calculationsWallet[0]?.Moeda} ${walletDetails?.calculationsWallet[0]?.['Valor da(as) Garantia(as)']}`}
                    lg
                    templateNumber={`${walletDetails?.template?.number}`}
                />
            </Box>
        </>
    )


}
