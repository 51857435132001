import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const ActiveBox = styled(Box)({
    display: 'flex',
    padding: '2px',
    flexDirection: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px',
    height: '60px',
    borderRadius: '8px',
});

const TextBox = styled(Box)({
    justifyContent: 'center',
    alignItems: 'center',
    margin: '12px'
});

export default function ActiveType({ title, text, backgroundColor, color }) {
    return (
        <>
            <ActiveBox backgroundColor={backgroundColor}>
                <Typography
                    sx={{ textAlign: 'center', color: 'white' }}
                    variant='h4'
                >
                    {title}
                </Typography>
            </ActiveBox>
            <TextBox>
                <Typography
                    color={color}
                    sx={{ textAlign: 'center' }}
                    variant='subtitle1'
                >
                    {text}
                </Typography>
            </TextBox>
        </>
    );
}
