/* eslint-disable consistent-return */

import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    // height: '400px',
}));
const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
}));

const FormControlLabelStyle = styled(FormControlLabel)(() => ({
    margin: '2px 0',
    display: 'flex',
    alignItems: 'center',
    width: '25%',
}));
const FormGroupStyle = styled(FormGroup)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '95%',
    paddingLeft: 'auto 8px',
    margin: 'auto 10px',
}));

export default function Step1({ setResultAssessment, resultAssessment }) {
    const [values, setValues] = useState(
        resultAssessment?.step1 || {
            pulverizado: [],
            acoesJudiciais: [],
            precatorio: [],
        }
    );

    const [checkedPulverizado, setCheckedPulverizado] = useState(
        resultAssessment?.step1.pulverizado || []
    );
    const [checkedAcoesJudiciais, setCheckedAcoesJudiciais] = useState(
        resultAssessment?.step1.acoesJudiciais || []
    );
    const [checkedPrecatorio, setCheckedPrecatorio] = useState(
        resultAssessment?.step1.precatorio || []
    );

    const ativoPulverizado = [
        { name: 'agua', label: 'Conta de água' },
        { name: 'luz', label: 'Conta de luz' },
        { name: 'gas', label: 'Conta de gás' },
        { name: 'telefone', label: 'Conta de telefone' },
        { name: 'internet', label: 'Conta de internet' },
        { name: 'boletos', label: 'Boletos / Duplicatas' },
        { name: 'leasin', label: 'Leasing' },
        { name: 'autoloan', label: 'Autoloan' },
        { name: 'imobiliario', label: 'Imobiliário / Habitacional' },
        { name: 'consignadoPublico', label: 'Consignado público' },
        { name: 'consignadoPrivado', label: 'Consignado privado' },
        { name: 'cartaoCredito', label: 'Cartão de crédito' },
        { name: 'chequeEspecial', label: 'Cheque especial' },
        { name: 'creditoPessoal', label: 'Crédito pessoal' },
        { name: 'capitalGiro', label: 'Capital de giro' },
        { name: 'creditoEstFi', label: 'Crédito estudantil (financiamento)' },
        {
            name: 'creditoEstBol',
            label: 'Crédito estudantil (boletos e duplicatas)',
        },

        { name: 'cpr', label: 'Cédula do produtor rural / recebíveis rurais' },
        {
            name: 'outrosCreditosPulverizados',
            label: 'Outros créditos pulverizados',
        },
    ];

    const ativoAcoesJudiciais = [
        {
            name: 'acoesJudiciais',
            label: 'Ações judiciais e discussões em câmaras de arbitragem',
        },
        {
            name: 'creditosFalencia',
            label: 'Créditos em recuperação judicial, recuperação extra-judicial ou falência',
        },
        { name: 'imoveis', label: 'Imóveis' },
        {
            name: 'outrosDiscussoesJudiciais',
            label: 'Outras discussões judiciais',
        },
        {
            name: 'creditosCorporativos',
            label: 'Créditos Corporativos',
        },
    ];

    const ativoPrecatorio = [
        {
            name: 'precatorios',
            label: 'Precatórios federais, estaduais e municipais',
        },
        { name: 'outrasAcoes', label: 'Outras ações com entidades públicas' },
        { name: 'creditosTributarios', label: 'Créditos tributários' },
        {
            name: 'outrosCreditosComEntidadesPublicas',
            label: 'Outros créditos com entidades públicas',
        },
    ];

    // https://codesandbox.io/embed/determined-yalow-j78e3?fontsize=14&hidenavigation=1&theme=dark
    const handleChangePulverizado = (isChecked) => {
        if (isChecked) {
            return setCheckedPulverizado(
                ativoPulverizado.map((item) => item.label)
            );
        }
        setCheckedPulverizado([]);
    };

    const handleChangeCreditoPulverizado = (isChecked, label) => {
        const index = checkedPulverizado.indexOf(label);

        if (isChecked)
            return setCheckedPulverizado((state) => [...state, label]);

        if (!isChecked && index > -1)
            return setCheckedPulverizado((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
    };

    const handleChangeAcoesJudiciais = (isChecked) => {
        if (isChecked)
            return setCheckedAcoesJudiciais(
                ativoAcoesJudiciais.map((item) => item.label)
            );
        setCheckedAcoesJudiciais([]);
    };

    const handleChangeCreditoAcoesJudiciais = (isChecked, label) => {
        const index = checkedAcoesJudiciais.indexOf(label);

        if (isChecked)
            return setCheckedAcoesJudiciais((state) => [...state, label]);

        if (!isChecked && index > -1)
            return setCheckedAcoesJudiciais((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
    };

    const handleChangePrecatorio = (isChecked) => {
        if (isChecked)
            return setCheckedPrecatorio(
                ativoPrecatorio.map((item) => item.label)
            );
        setCheckedPrecatorio([]);
    };

    const handleChangeCreditoPrecatorio = (isChecked, label) => {
        const index = checkedPrecatorio.indexOf(label);

        if (isChecked)
            return setCheckedPrecatorio((state) => [...state, label]);

        if (!isChecked && index > -1)
            return setCheckedPrecatorio((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
    };

    useEffect(() => {
        setResultAssessment({
            step1: values,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: resultAssessment?.step4,
            step5: resultAssessment?.step5,
        });
    }, [values]);

    useEffect(() => {
        setValues({
            pulverizado: checkedPulverizado,
            acoesJudiciais: checkedAcoesJudiciais,
            precatorio: checkedPrecatorio,
        });
    }, [checkedPulverizado, checkedAcoesJudiciais, checkedPrecatorio]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/UmBranco.svg' alt='Um' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '30px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Selecione um ou mais ativos de sua preferência.
                    <spam
                        style={{
                            color: '#D10000',
                            fontSize: '16px',
                            margin: '0 5px',
                        }}
                    >
                        *
                    </spam>
                </Typography>
            </Box>

            <FormGroupStyle>
                <FormControlLabelStyle
                    sx={{ width: '100%' }}
                    control={
                        <Checkbox
                            defaultChecked={
                                checkedPulverizado && checkedPulverizado
                            }
                            name='pulverizado'
                            checked={
                                checkedPulverizado.length ===
                                ativoPulverizado.length
                            }
                            indeterminate={
                                checkedPulverizado.length !==
                                    ativoPulverizado.length &&
                                checkedPulverizado.length > 0
                            }
                            onChange={(event) =>
                                handleChangePulverizado(event.target.checked)
                            }
                        />
                    }
                    label={
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            Créditos Pulverizados
                        </Typography>
                    }
                />
                <Grid
                    container
                    spacing={1}
                    sx={{ display: 'flex', margin: '0 20px 20px 10px' }}
                >
                    <Grid container item spacing={1}>
                        {checkedPulverizado &&
                            ativoPulverizado.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Checkbox
                                            size='small'
                                            key={item.name}
                                            checked={checkedPulverizado.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleChangeCreditoPulverizado(
                                                    event.target.checked,
                                                    item.label
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontStyle:
                                                    (item.label === 'Leasing' ||
                                                        item.label ===
                                                            'Autoloan') &&
                                                    'italic',
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                    </Grid>
                </Grid>

                <FormControlLabelStyle
                    sx={{ width: '100%' }}
                    control={
                        <Checkbox
                            defaultChecked={values && values?.acoesJudiciais}
                            name='acoesJudiciais'
                            checked={
                                checkedAcoesJudiciais.length ===
                                ativoAcoesJudiciais.length
                            }
                            indeterminate={
                                checkedAcoesJudiciais.length !==
                                    ativoAcoesJudiciais.length &&
                                checkedAcoesJudiciais.length > 0
                            }
                            onChange={(event) =>
                                handleChangeAcoesJudiciais(event.target.checked)
                            }
                        />
                    }
                    label={
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            Créditos Corporativos e Discussões Judiciais
                        </Typography>
                    }
                />
                <Grid
                    container
                    spacing={1}
                    sx={{ display: 'flex', margin: '0 20px 20px 10px' }}
                >
                    <Grid container item spacing={1}>
                        {checkedAcoesJudiciais &&
                            ativoAcoesJudiciais.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Checkbox
                                            size='small'
                                            key={item.name}
                                            checked={checkedAcoesJudiciais.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleChangeCreditoAcoesJudiciais(
                                                    event.target.checked,
                                                    item.label
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant='body2'>
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                    </Grid>
                </Grid>

                <FormControlLabelStyle
                    sx={{ width: '100%' }}
                    control={
                        <Checkbox
                            defaultChecked={values && values?.precatorio}
                            name='precatorio'
                            checked={
                                checkedPrecatorio.length ===
                                ativoPrecatorio.length
                            }
                            indeterminate={
                                checkedPrecatorio.length !==
                                    ativoPrecatorio.length &&
                                checkedPrecatorio.length > 0
                            }
                            onChange={(event) =>
                                handleChangePrecatorio(event.target.checked)
                            }
                        />
                    }
                    label={
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            Créditos com Entidades Públicas
                        </Typography>
                    }
                />
                <Grid
                    container
                    spacing={1}
                    sx={{ display: 'flex', margin: '10px 20px 20px 10px' }}
                >
                    <Grid container item spacing={1}>
                        {checkedPrecatorio &&
                            ativoPrecatorio.map((item) => (
                                <FormControlLabelStyle
                                    control={
                                        <Checkbox
                                            size='small'
                                            key={item.name}
                                            checked={checkedPrecatorio.includes(
                                                item.label
                                            )}
                                            onChange={(event) =>
                                                handleChangeCreditoPrecatorio(
                                                    event.target.checked,
                                                    item.label
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant='body2'>
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            ))}
                    </Grid>
                </Grid>
            </FormGroupStyle>
        </FormBox>
    );
}
