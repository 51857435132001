import { Box, Typography } from '@mui/material';
import BarGraphics from 'components/BarGraphics';
import DetailsActiveTable from 'components/DetailsActiveTable';
import PieGraphics from 'components/PieGraphics';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'themes';
import { formatColumns, formatRows } from 'utils/formats';
import Template5 from './Template5';

const DistributionBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '20px',
    padding: '24px',
    // maxWidth: '1280px',
    border: '1px #D4D4D4 solid',
    borderRadius: '4px',
    background: '#fff',
});
const GrapichPieBox = styled(Box)({
    width: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
});

const Title = styled(Typography)({
    fontSize: '18px',
    marginBottom: '15px',
    color: theme.palette.primary.main,
});

export default function Template4({ walletDetails }) {
    let valuesGraphics = {};
    const [valuesGraphicsVehicle, setValuesGraphicsVehicle] = useState({});
    const [valuesGraphicsFabrication, setValuesGraphicsFabrication] = useState(
        {}
    );

    useEffect(() => {
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Créditos PF Versus PJ'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Créditos PF Versus PJ'
                ]
            )[index];

            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });

        setValuesGraphicsVehicle(valuesGraphics);

        valuesGraphics = {};
        Object.values(
            walletDetails?.calculationsWallet?.[0]?.[
            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ano de Fabricação'
            ]
        ).forEach((item, index) => {
            const key = Object.keys(
                walletDetails?.calculationsWallet?.[0]?.[
                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ano de Fabricação'
                ]
            )[index];
            const items = item.map((i) =>
                i
                // ?.replaceAll('.', '')?.replace('R$ ', '')?.replace(',', '')
            );
            const valueKey = items.slice(0, -1);
            valuesGraphics = {
                ...valuesGraphics,
                [key]: valueKey,
            };
        });
        setValuesGraphicsFabrication(valuesGraphics);
    }, [walletDetails]);

    return (
        <>
            <Template5 walletDetails={walletDetails} />
            <DistributionBox>
                <Title>
                    Distribuição de Saldo em Aberto e Quantidade de Documentos
                    por Tipo de Veículo
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '280px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    {

                        walletDetails?.calculationsWallet?.[0]?.[
                            'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo'
                        ]['Tipo de Veículos'][0] === 'Total' ? (
                            <Typography sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>Infelizmente, não dispomos de dados suficientes para gerar este gráfico</Typography>
                        ) : (
                            <>
                                <GrapichPieBox>
                                    <PieGraphics
                                        labels={valuesGraphicsVehicle?.['Tipo de Veículos']}
                                        data={valuesGraphicsVehicle?.['Saldo em Aberto']}
                                        content='facePerson'
                                    />
                                </GrapichPieBox>
                                <DetailsActiveTable
                                    rows={formatRows(
                                        walletDetails?.calculationsWallet?.[0]?.[
                                        'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo'
                                        ], walletDetails?.calculationsWallet?.[0]?.Moeda
                                    )}
                                    columns={formatColumns(
                                        walletDetails?.calculationsWallet?.[0]?.[
                                        'Distribuição de Saldo em Aberto e Quantidade de Documentos por Tipo de Veículo'
                                        ]
                                    )}
                                />
                            </>
                        )
                    }

                </Box>
            </DistributionBox>

            <DistributionBox>
                <Title>
                    Distribuição de Saldo em Aberto e Quantidade de Documentos
                    por Ano de Fabricação
                </Title>
                <Box
                    sx={{
                        width: '100%',
                        height: '480px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <BarGraphics
                        labels={valuesGraphicsFabrication?.['Ano de Fabricação']}
                        data={valuesGraphicsFabrication?.['Saldo em Aberto']}
                        type='ageRange'
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '5px',
                        }}
                    >
                        <DetailsActiveTable
                            rows={formatRows(
                                walletDetails?.calculationsWallet?.[0]?.[
                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ano de Fabricação'
                                ], walletDetails?.calculationsWallet?.[0]?.Moeda
                            )}
                            columns={formatColumns(
                                walletDetails?.calculationsWallet?.[0]?.[
                                'Distribuição de Saldo em Aberto e Quantidade de Documentos por Ano de Fabricação'
                                ]
                            )}
                            sx={{ height: '200px' }}
                        />
                    </Box>
                </Box>
            </DistributionBox>
        </>
    );
}
