/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import Add from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Filter from 'components/Filter';
import { RowsContext } from 'context/RowsContext';
import { useContext, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import chipStatus from 'utils/chipStatus';
import DataTable from '../../../components/Table';
// import Opportunities from 'views/Buyer/Opportunities';
import EmptyState from './EmptyState';

const columns = [
    {
        field: 'idGenerate',
        headerName: 'Identificador',
        minWidth: 120,
    },
    {
        field: 'publication',
        headerName: 'Publicação',
        minWidth: 120,
    },
    {
        field: 'activeType',
        headerName: 'Tipo de Ativo',
        minWidth: 230,
    },
    {
        field: 'walletType',
        headerName: 'Tipo de Carteira',
        minWidth: 220,
    },
    {
        field: 'coin',
        headerName: '',
        width: 50,
        sortable: false,
        renderCell: (params) => (
            <Typography
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                }}
            >
                {params.value}
            </Typography>
        ),
    },
    {
        field: 'faceValue',
        headerName: 'Valor Original',
        minWidth: 150,
    },
    {
        field: 'coin',
        headerName: '',
        width: 50,
        sortable: false,
        renderCell: (params) => (
            <Typography
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                }}
            >
                {params.value}
            </Typography>
        ),
    },
    {
        field: 'updatedValue',
        headerName: 'Valor Atualizado',
        minWidth: 150,
    },
    {
        field: 'amountDoc',
        headerName: 'Qtd. Documentos',
        minWidth: 150,
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 200,
        renderCell: (params) => chipStatus(params.value),
    },
];

const BoxHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '48px 32px',
});

const BoxHeaderContent = styled(Box)({
    display: 'flex',
    alignContent: 'center',
    width: '90%',
});

const TypographyTitle = styled(Typography)((props) => ({
    marginLeft: '14px',
    color: props.theme.palette.themePrimary.primaryGray,
}));

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    // height: '100%',
    justifyContent: 'center',
});

export default function Active() {
    const { rows, setRows, oldRows, setOldRows } = useContext(RowsContext);
    const rowsPerPage = 100;
    const [doesUserHasActive, setDoesUserHasActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function handleCreateNewActive() {
        navigate('/seller/assessment');
    }

    useLayoutEffect(() => {
        if (rows?.length > 0) {
            setDoesUserHasActive(true);
        }
    }, [rows]);

    return (
        <Box sx={{ marginBottom: '48px' }}>
            <BoxHeader>
                <BoxHeaderContent>
                    <img
                        src='/static/image/MeusAtivos.svg'
                        alt='Carteiras - Meus ativos'
                    />
                    <TypographyTitle variant='h1'>Meus ativos</TypographyTitle>
                </BoxHeaderContent>

                {doesUserHasActive && (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleCreateNewActive}
                        sx={{ width: '130px', height: '38px' }}
                        endIcon={<Add />}
                    >
                        Novo Ativo
                    </Button>
                )}
            </BoxHeader>

            {doesUserHasActive && (
                <BoxContainer>
                    <Filter rows={rows} setRows={setRows} oldRows={oldRows} />
                    <DataTable
                        rows={rows}
                        columns={columns}
                        setRows={setRows}
                        setOldRows={setOldRows}
                        rowsPerPage={rowsPerPage}
                        userType='seller'
                    />
                </BoxContainer>
            )}

            {!doesUserHasActive && (
                <EmptyState handleCreateNewActive={handleCreateNewActive} />
            )}
        </Box>
    );
}
