import {
    Autocomplete,
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { theme } from 'themes';

const FormBox = styled(FormGroup)((props) => ({
    backgroundColor: props.theme.palette.themePrimary.primaryWhiteLight,
    margin: '30px 80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '24px',
    alignItems: 'center',
    minHeight: '400px',
}));

const BoxFormControl = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '25px',
}));

const BoxIconStyle = styled(Box)(() => ({
    width: '24px',
    height: '24px',
    background: '#1E49E2',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
}));

export default function Step4({ setResultAssessment, resultAssessment }) {
    const [statesPrint, setStatesPrint] = useState(
        resultAssessment?.step4.states.length !== 0
            ? resultAssessment.step4.states
            : []
    );
    const [valueOption, setValueOption] = useState(
        resultAssessment?.step4.option !== ''
            ? resultAssessment?.step4.option
            : ''
    );

    const states = [
        { label: 'Acre' },
        { label: 'Alagoas' },
        { label: 'Amapá' },
        { label: 'Amazonas' },
        { label: 'Bahia' },
        { label: 'Ceará' },
        { label: 'Distrito Federal' },
        { label: 'Espírito Santo' },
        { label: 'Goiás' },
        { label: 'Maranhão' },
        { label: 'Mato Grosso' },
        { label: 'Mato Grosso do Sul' },
        { label: 'Minas Gerais' },
        { label: 'Pará' },
        { label: 'Paraíba' },
        { label: 'Paraná' },
        { label: 'Pernambuco' },
        { label: 'Piauí' },
        { label: 'Rio de Janeiro' },
        { label: 'Rio Grande do Norte' },
        { label: 'Rio Grande do Sul' },
        { label: 'Rondônia' },
        { label: 'Roraima' },
        { label: 'Santa Catarina' },
        { label: 'São Paulo' },
        { label: 'Sergipe' },
        { label: 'Tocantins' },
    ];

    const handleChangeStatesPrint = (_, values) => {
        if (!statesPrint.includes(values)) {
            setStatesPrint(values);
        }
    };

    const getValueRadius = (e) => {
        if (e.target.value === 'nao') {
            setStatesPrint([]);
        }
        setValueOption(e.target.value);
    };

    useEffect(() => {
        setResultAssessment({
            step1: resultAssessment?.step1,
            step2: resultAssessment?.step2,
            step3: resultAssessment?.step3,
            step4: { option: valueOption, states: statesPrint },
            step5: resultAssessment?.step5,
        });
    }, [statesPrint, valueOption]);

    return (
        <FormBox>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxIconStyle>
                    <img src='/static/image/QuatroBranco.svg' alt='Quatro' />
                </BoxIconStyle>
                <Typography
                    variant='h5'
                    sx={{
                        marginTop: '4vh',
                        marginBottom: '30px',
                        color: theme.palette.themePrimary.primaryGrayDark,
                    }}
                >
                    Preferência por localidade?{' '}
                    <spam style={{ color: '#D10000', fontSize: '16px' }}>
                        *
                    </spam>
                </Typography>
            </Box>
            <BoxFormControl>
                <FormControl>
                    <RadioGroup defaultValue={resultAssessment?.step4.option}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                value='sim'
                                control={
                                    <Radio
                                        size='small'
                                        onChange={getValueRadius}
                                    />
                                }
                                label={
                                    <Typography variant='body2'>Sim</Typography>
                                }
                            />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                value='nao'
                                control={
                                    <Radio
                                        size='small'
                                        onChange={getValueRadius}
                                    />
                                }
                                label={
                                    <Typography variant='body2'>Não</Typography>
                                }
                            />
                        </Box>
                    </RadioGroup>
                </FormControl>
            </BoxFormControl>
            {resultAssessment?.step4.option === 'sim' && (
                <Box
                    sx={{
                        width: '100%',
                        padding: '0 50px',
                    }}
                >
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={states?.map((option) => option.label)}
                        onChange={handleChangeStatesPrint}
                        value={statesPrint?.map((option) => option) || ''}
                        defaultValue={statesPrint !== [] && statesPrint}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label='Estados'
                            />
                        )}
                    />
                </Box>
            )}
        </FormBox>
    );
}
