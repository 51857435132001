import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, Button, Typography } from '@mui/material';
import ModalInviteUser from 'components/ModalInviteUser';
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'themes';
import CompanyData from './CompanyData';
import UserData from './UserData';

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const BoxTitle = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '40px auto',
});

const BoxOrgUser = styled(Box)({
    width: '200px',
    minWidth: '200px',
    // height: '1045px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '16px',
    background: theme.palette.themePrimary.primaryWhiteLight,
    border: '1px solid #EDEDED',
    // background: theme.palette.themeSecondary.secondaryRedLight,
});

const TypographyTitle = styled(Typography)({
    color: theme.palette.themePrimary.primaryGray,
});

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    // height: '100%',
    justifyContent: 'space-between',
});

export default function CompanyAdministration() {
    const navigate = useNavigate();
    const { getUsers } = useContext(UserContext);
    const [isOrg, setIsOrg] = useState(true);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const isFirstRender = useRef(true);

    const handleInvite = () => {
        setIsOpenModal(true);
    };
    
    const handleGoBack = async () => {
        navigate(-1);
        getUsers();
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getUsers();
    }, []);


    return (
        <>
            <ModalInviteUser
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
            />
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    width: '90%',
                    maxWidth: '1380px',
                    margin: '0 auto',
                }}
            >
                <HeaderContainer>
                    <Button
                        variant='text'
                        sx={{
                            marginTop: '32px',
                            width: '32px',
                        }}
                        color='primary'
                        size='4px'
                        startIcon={<ArrowBackIosRoundedIcon />}
                        onClick={handleGoBack}
                    >
                        Voltar
                    </Button>
                    {isOrg ? (
                        <BoxTitle>
                            <img
                                src='/static/image/DadosEmpresa.svg'
                                alt='Dados da empresa'
                                width={24}
                            />
                            <TypographyTitle
                                sx={{ marginLeft: '14px' }}
                                variant='h1'
                            >
                                Dados da Entidade
                            </TypographyTitle>
                        </BoxTitle>
                    ) : (
                        <BoxTitle sx={{ justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex' }}>
                                <img
                                    src='/static/image/Perfil.svg'
                                    alt='Perfil'
                                    width={24}
                                />
                                <TypographyTitle
                                    sx={{ marginLeft: '14px' }}
                                    variant='h1'
                                >
                                    Usuários
                                </TypographyTitle>
                            </Box>

                            <Button
                                variant='contained'
                                color='primary'
                                size='8px'
                                endIcon={<AddRoundedIcon />}
                                onClick={handleInvite}
                            >
                                Convidar usuário
                            </Button>
                        </BoxTitle>
                    )}
                </HeaderContainer>

                <BoxContainer>
                    <BoxOrgUser>
                        <Button
                            sx={{
                                margin: '5px 0',
                                background: isOrg && '#EDEDED',
                                justifyContent: 'flex-start',
                            }}
                            startIcon={
                                <img
                                    src='/static/image/DadosEmpresa.svg'
                                    alt='Dados da empresa'
                                    width={16}
                                    style={{ margin: '0 10px' }}
                                />
                            }
                            onClick={() => setIsOrg(true)}
                        >
                            Organização
                        </Button>
                        <Button
                            sx={{
                                margin: '5px 0',
                                background: !isOrg && '#EDEDED',
                                paddingLeft: '5px',
                                justifyContent: 'flex-start',
                            }}
                            startIcon={
                                <img
                                    src='/static/image/Perfil.svg'
                                    alt='Perfil'
                                    width={16}
                                    style={{ margin: '0 12px' }}
                                />
                            }
                            onClick={() => setIsOrg(false)}
                        >
                            Usuários
                        </Button>
                    </BoxOrgUser>
                    {isOrg ? <CompanyData /> : <UserData />}
                </BoxContainer>
            </Box>
        </>
    );
}
